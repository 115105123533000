<template>
  <b-modal id="modal-resumen-recepcion" centered ref="modal" title="Resumen" size="xl">
    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <b-row class="d-flex justify-content-around">
        <div class="pl-3">Resumen</div>
      </b-row>
      <button type="button" class="close" aria-label="Close" @click="close()">
        <span aria-hidden="true" style="color:white">&times;</span>
      </button>
    </template>

    <div>

      <div >

 <b-row>    

   <b-col class="col-8">

</b-col>
<b-col class="col-8">

</b-col>

</b-row>
<div id="pagePdf" class="page">
 
        <header class="header">
        <div class="header-left">
          <img src="@/assets/ucn.png" alt="Logo de la Institución">
        </div>
        <div class="header-center">
            <h1>LABORATORIO DE SERVICIOS ANALITICOS</h1>
            <p>Contacto: 55 2 355613 - 55 2 355633 - +56 9 934461712</p>
            <p>Email: lsa@ucn.cl</p>
            <div class="social-media">
                <span>
                    <i class="fab fa-instagram"></i> UCN_LSA
                </span>
                <span>
                    <i class="fab fa-twitter"></i> UCN-LSA
                </span>
                <span>
                    <i class="fab fa-linkedin"></i> UCN LSA
                </span>
            </div>
            <h3 style="text-align:center; color:rgb(50, 50, 237); font-size: 10px;">15° Versión</h3>

        </div>
        <div class="logo-container">
          <div class="header-right">
            <img src="@/assets/isologo-LSA.png" alt="Logo del Laboratorio">
          </div>
        </div>
        <div class="header-fourth">
            <p>INFORME Nº</p>
            <div class="rum-container">
                <p>RUM</p>
                <div class="rectangle">{{ this.resumenData.numero_informe }}</div>
            </div>
        </div>
    </header>
      <div class="page__content">
      </div>
      <div class="page__details">
        <table border="1" class="page__table">
          <thead>
            <tr>
              <th colspan="2" style="text-align:center;">Identificacion del Cliente</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tablaItems[0]" :key="item.label">
              <td>{{ item.label }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
        
        <table border="1" class="page__table2">
          <thead>
            <tr>
              <th colspan="2" style="text-align:center;">Identificacion de la Muestra</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tablaItems[1]" :key="item.label">
              <td>{{ item.label }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
        <table border="1" class="page__table4">
          <thead>
            <tr>
              
              <th  style="text-align:center;">Número de la submuestra</th>
              <th  style="text-align:center;">Identificación de la submuestra</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tablaItems[3]" :key="item.label">
              <td>{{ item.label }}</td>
              <td>{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
        <table border="1" id='tabla3' class="page__table3">
          <thead>
            <tr>
              <th colspan="2" style="text-align:center;">Parametros </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tablaItems[2]" :key="item.label">
              
             
           
          
              <td colspan="2" style="text-align:center;">{{ item.value }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
      </div>

    </div>


    <template #modal-footer="{ close }">
      <b-col>


        <b-row class="d-flex justify-content-between">
<b-row >
          <xlsx-workbook>
          <xlsx-sheet :collection="sheet.data" v-for="sheet in resumen" :key="sheet.name" :sheet-name="sheet.name" />
          <xlsx-download>
            <b-button class="lsa-orange reactive-button ml-2" size="xl" style="font-weight: bold; border: none; min-height: 40px;">Descargar
              excel<b-icon icon="file-earmark-spreadsheet" class="ml-2"></b-icon></b-button>
          </xlsx-download>
          
       

        </xlsx-workbook>

          <b-button size="xl" class="reactive-button lsa-orange ml-2" style="font-weight: bold; border:none;  min-height: 40px;"
            @click="generatePDF">Descargar Vista Previa
            <b-icon size="0.9" icon="file-earmark-pdf" class="ml-2" />
          </b-button>

        </b-row>

          <b-button @click="close()" variant="primary" class="float-right reactive-button" style="font-weight:bold">
            Cerrar
          </b-button>
        </b-row>
      </b-col>

    </template>

  </b-modal>
</template>
<style>
/* Estilos para el PDF */
.page {
  font-family: Arial, sans-serif;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
}

.page__resume {
  font-style: italic;
  margin-bottom: 20px;
}
.page__table, .page__table2, .page__table3, .page__table4 {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
}
.page__table th, .page__table td, .page__table2 th, .page__table2 td, .page__table3 th, .page__table3 td, .page__table4 th, .page__table4 td {
  border: 1px solid #ccc;
  padding: 8px;
  word-wrap: break-word; /* Permite el quiebre de palabras largas */
  white-space: pre-wrap;
  
}
.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #ffffff; /* Azul con tonalidad muy clara */
    border-bottom: 2px solid #ccc;
}

.header-left,
.header-center,
.header-right,
.header-fourth {
    flex: 1;
}

.header-left{
    max-width: 150px;
}

.header img {
    max-width: 100%;
    height: auto;
}

.header h1 {
    margin: 0;
    font-size: 1.5em;
}

.header p {
    margin: 5px 0;
}
.header-right {
    width: auto; /* Permite que el contenedor se ajuste al tamaño de la imagen */
    display: flex; /* Utiliza flexbox para centrar la imagen verticalmente */
    justify-content: center; /* Centra horizontalmente la imagen */
    align-items: center; /* Centra verticalmente la imagen */
}

.header-right img {
    height: 100px; /* Ajusta la altura según sea necesario */
    width: 200px; /* Establece la anchura deseada para la imagen */
    max-width: none; /* Permite que la imagen mantenga su tamaño original */
    max-height: none; /* Permite que la imagen mantenga su tamaño original */
}
.social-media {
    display: flex;
    gap: 10px;
}

.social-media span {
    display: flex;
    align-items: center;
}

.social-media i {
    margin-right: 5px;
    color: #0077b5; /* Color de los íconos */
}

.header-fourth {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    margin-left: auto; /* Alinear a la derecha */
}

.rum-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.rum-container p {
    margin: 5px 0;
}

.rectangle {
    width: 120px;
    height: 30px;
    background-color: #fff;
    border: 1px solid #000;
    text-align: center;
    line-height: 30px;
    margin-top: 5px;
}
</style>

<script>
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { nextTick } from 'vue';
import {XlsxWorkbook, XlsxSheet, XlsxDownload } from "vue-xlsx";

export default {
  components: {
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload
  },
  watch: {
    resumenData: {
      handler() {
        var parametros_string = "";
        this.resumenData.parametros.forEach(p => {
          parametros_string += p + ","
        });

        this.resumen = [{ name: "Resumen", data: [{
            "N° Informe" : "-",
            "Cliente" : this.resumenData.cliente,
            "Parámetros" : parametros_string,
            "Fecha de Ingreso" : this.resumenData.fecha_ingreso,
            "Fecha de Entrega" : this.resumenData.fecha_entrega,
            "N° Muestras Total" : this.resumenData.numero_muestras,
            "Fecha de Emisión Informe" : "-",
            "Prioridad" : this.resumenData.prioridad,
            "Cotización N°" : this.resumenData.numero_cotizacion,
            "Matriz" : this.resumenData.matriz,
            "Acreditación" : "-",
            "OC/HES" : "-",
            "Factura N°" : "-",
            "EDP" : "-",
            "Código Recuperación" : "-",
            "Valor NETO UF" : this.resumenData.valor,
            
          }] }] 
      }
    }
  },
  computed: {
      tablaItems() {
        var tablaItems = [];

        var items = [
          { label: 'Solicitante', value:  this.resumenData.nombre_solicitante},
          { label: 'Empresa', value: this.resumenData.cliente },
          { label: 'Direccion', value: this.resumenData.direccion },
          { label: 'Fono', value: this.resumenData.telefono_solicitante },
          { label: 'Correo', value: this.resumenData.correo_solicitante },
          { label: 'Cotización N°', value: this.resumenData.numero_cotizacion },
        ];
        tablaItems.push(items);

        items = [
          { label: 'Muestreado por:', value: this.resumenData.muestreado_por },
          { label: 'N° Muestras', value: this.resumenData.numero_muestras },
          { label: 'Tipo Matriz', value: this.resumenData.matriz },
          { label: 'Prioridad', value: this.resumenData.prioridad  },
          { label: 'Fecha de Muestreo', value: this.resumenData.fecha_muestreo },
          { label: 'Hora de Muestreo', value: this.resumenData.hora_muestreo },
          { label: 'Responsable Entrega', value: ' ' },
          { label: 'Chofer', value: this.resumenData.nombre_chofer},
          { label: 'Rut', value: this.resumenData.rut_chofer},
          { label: 'Patente Vehiculo', value: this.resumenData.patente},
          { label: 'Temperatura de Transporte', value: this.resumenData.temperatura_transporte},
          { label: 'Fecha de Ingreso', value: this.resumenData.fecha_recepcion},
          { label: 'Hora de Ingreso',value: this.resumenData.hora_recepcion},
          { label: 'Recibido por',value: this.resumenData.recibido_por},
          { label: 'Fecha Entrega de resultados',value: this.resumenData.fecha_entrega},
          { label: 'Observaciones',value: this.resumenData.observaciones},

        ];
        tablaItems.push(items);
        items = [];
        try {
          this.resumenData.parametros.forEach(p => {
          items.push({ label: 'Parametro', value: p.parametro });
          
        });
        } catch (error) {
            ///
        }
        
        tablaItems.push(items);
        items = [];
        var index = 0;
        try {
          this.resumenData.identificaciones_submuestra.forEach(p => {
          if(p == ''){
            items.push({ label: index+1, value: 'Sin identificador asignado' });
          }else{
            items.push({ label: index+1, value: p });
          }
          
          index++;
          
        });
        } catch (error) {
            ///
        }
        tablaItems.push(items);
        return tablaItems;
      },
      tablaFields() {
        const fields = [
        { key: 'label', label: 'Campo' },
        { key: 'value', label: 'Valor' }
        ];
        
        return fields;          
      },      
    },
  props: {
    resumenData: Object
  },
  methods: {
    generatePDF() {
      nextTick(() => {

        const doc = new jsPDF({
          orientation: 'landscape' // Establece la orientación a horizontal
        });
        const logoucn = require('@/assets/ucn.png')
        const iconoIG = require('@/assets/logotipo-de-instagram.png');
        const iconoTW = require('@/assets/twitter.png');
        const iconoLinkedIn = require('@/assets/linkedin.png');
        const logoLSA = require('@/assets/isologo-LSA.png');
        // Añadimos el contenido de cada página
        
        const pages = document.querySelectorAll('#pagePdf');
        pages.forEach(() => {
        // Evitamos añadir una página extra al final
        if (!doc.internal.pageSize.getHeight()) {
          doc.addPage();
        }
        // Encabezado izquierdo
      var img = new Image()
      img.src = logoucn;
      doc.addImage(img, 'PNG', 20, 10, 20, 20 ,undefined,'FAST');            

      let numero_informe = '';
      try{
        doc.setFontSize(13); // Tamaño de la fuente
        numero_informe = this.resumenData.numero_informe.toString();
        doc.text(numero_informe, 247, 31);
      }catch{
        doc.setFontSize(12); // Tamaño de la fuente
        numero_informe =  'Vista Previa';
        doc.text(numero_informe, 241, 31);
      }

      
      doc.setFontSize(10); // Tamaño de la fuente
      doc.setFont('helvetica', 'bold');
      doc.text('LABORATORIO DE SERVICIOS ANALITICOS', 50, 15);
      doc.setFont('helvetica', 'normal');
      doc.setFontSize(8);
      doc.text('Contacto: 55 2 355613 - 55 2 355633 - +56 9 934461712', 50, 20);
      doc.text('Email: LSA@UCN.CL', 75, 25);
      doc.text('UCN_LSA       UCN-LSA       UCN LSA', 64, 30);
      img.src = iconoIG;
      doc.addImage(img, 'PNG', 60, 27, 3, 3 ,undefined,'FAST'); // Icono de Instagram
      img.src = iconoTW;
      doc.addImage(img, 'PNG', 79, 27, 3, 3 ,undefined,'FAST'); // Icono de Twitter
      img.src = iconoLinkedIn;
      doc.addImage(img, 'PNG', 97, 27, 3, 3,undefined,'FAST'); // Icono de LinkedIn
      // Encabezado derecho
      img.src = logoLSA;
      doc.addImage(img, 'PNG', 160, 10, 45, 20,undefined,'FAST'); 
      // Encabezado cuarto
      doc.setFont('helvetica', 'bold');
      doc.setFontSize(12);
      doc.text('INFORME Nº', 241, 20);
      doc.text('RUM', 247, 15);
      doc.rect(238, 24, 30, 10);
      doc.setFontSize(7);
      doc.setTextColor(50, 50, 237);
      doc.text('15ª version', 81, 36);
      doc.setTextColor(0);

        // Detalles
      var table = document.querySelector('.page__table'); // Selecciona la tabla HTML
      var rows = table.querySelectorAll('tr');
      var header = table.querySelectorAll('th');
      let x = 20;
      let y = 40;
      var cellWidth = 60; // Ancho de celda 
      doc.setFontSize(7); // Ajusta el tamaño de fuente de la tabla
      doc.cell(x, y, 120, 6, ' ', 0,'center'); // Ajusta el contenido de la celda
      doc.text(header[0].textContent, 64, 44); 
  
      rows.forEach((row, rowIndex) => {
        const columns = row.querySelectorAll('td'); 
        x = 20;
        columns.forEach((column) => {
          cellWidth = 60;
          
          if(column.textContent.length > 0){
            doc.cell(x, y, cellWidth, 6,column.textContent, rowIndex, 'center'); // Ajusta el contenido de la celda
          }
          else{
            doc.cell(x, y, cellWidth, 6,' ', rowIndex, 'center'); // Ajusta el contenido de la celda
          }
        });
      });
      table = document.querySelector('.page__table2'); // Selecciona la tabla HTML
      rows = table.querySelectorAll('tr');
      header = table.querySelectorAll('th');
      doc.cell(x, y, 120, 6, ' ', 0,'center'); // Ajusta el contenido de la celda
      
      doc.text(header[0].textContent, 64, 86); 
      var entrar = true;
      const tableStyle = {
          fontSize: 7,
          halign: 'center', // Alineación horizontal al centro
          lineWidth: 10,
        };
      rows.forEach((row, rowIndex) => {
        const columns = row.querySelectorAll('td'); 
        x = 20;
       if(rowIndex > 15){
          entrar = false;
        }
        if(entrar){
          
          columns.forEach((column) => {
            cellWidth = 60;

            if(column.textContent.length > 0){
              doc.cell(x, y, cellWidth, 6,column.textContent, rowIndex, 'center'); // Ajusta el contenido de la celda
            }
            else{
              doc.cell(x, y, cellWidth, 6,' ', rowIndex, 'center'); // Ajusta el contenido de la celda
            }
          });
        }else{
          
          doc.autoTable({
          head: [['Observaciones']],
          body: [[columns[1].textContent]],
          startY: 40, 
          startX: 300,
          margin: { right: 25,left: 170}, 
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' }
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        }
              
      });
      //// Función para convertir una tabla HTML a datos para autoTable
      function convertTableToData(tableSelector) {
          const table = document.querySelector(tableSelector);
          const rows = table.querySelectorAll('tr');
          const data = [];

          rows.forEach((row) => {
            const rowData = [];
            const columns = row.querySelectorAll('td, th'); // Incluye celdas de encabezado (th)

            columns.forEach((column) => {
              rowData.push(column.textContent);
            });

            data.push(rowData);
          });

          return data;
        }
        //crear autotabla con identificacion de las muestras 
        const tableIdentificacionesData = convertTableToData('.page__table4');
        doc.autoTable({
          head: [tableIdentificacionesData[0]],
          body: tableIdentificacionesData.slice(1,16),
          startY: 82, 
          startX: 300,
          margin: { right: 25,left: 170}, 
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' }
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        if(tableIdentificacionesData.length > 16){
          doc.autoTable({
          head: [tableIdentificacionesData[0]],
          body: tableIdentificacionesData.slice(16,45),
          startY: 200, 
          startX: 1,
          margin: { right: 157,left: 20}, 
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' }
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        }
        if(tableIdentificacionesData.length > 45){
          doc.autoTable({
          head: [tableIdentificacionesData[0]],
          body: tableIdentificacionesData.slice(45,74),
          startY: 15, 
          startX: 300,
          margin: { right: 25,left: 170}, 
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' }
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        }
        if(tableIdentificacionesData.length > 74){
          doc.autoTable({
          head: [tableIdentificacionesData[0]],
          body: tableIdentificacionesData.slice(74,101),
          startY: 400, 
          startX: 1,
          margin: { right: 157,left: 20}, 
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' }
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        }
      
        const table2Data = convertTableToData('.page__table3');
        if(table2Data.length > 59){
          doc.autoTable({
          head: [table2Data[0]],
          body: table2Data.slice(1,30),
          startY: 400, 
          startX: 1,
          margin: { right: 200}, 
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' }
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        doc.autoTable({
          head: [table2Data[0]],
          body: table2Data.slice(30,59),
          startY: 14, 
          startX: 40,
          margin: { right: 110,left:100}, // Margen izquierdo + espacio entre tablas
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' } // Ancho automático para la primera columna
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        doc.autoTable({
          head: [table2Data[0]],
          body: table2Data.slice(59),
          startY: 14, 
          startX: 100,
          margin: { right: 20,left:190}, // Margen izquierdo + espacio entre tablas
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' } // Ancho automático para la primera columna
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        
        }
        else if(table2Data.length > 30){
          doc.autoTable({
          head: [table2Data[0]],
          body: table2Data.slice(1,30),
          startY: 400, 
          startX: 1,
          margin: { right: 200}, // Margen izquierdo + espacio entre tablas
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' } // Ancho automático para la primera columna
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        doc.autoTable({
          head: [table2Data[0]],
          body: table2Data.slice(30),
          startY: 14, 
          startX: 40,
          margin: { right: 110,left:100}, // Margen izquierdo + espacio entre tablas
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' } // Ancho automático para la primera columna
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        }
        else if(table2Data.length == 1){
          doc.autoTable({
          head: [table2Data[0]],
          body: [['Sin parametros seleccionados']],
          startY: 400, 
          startX: 1,
          margin: { right: 200}, // Margen izquierdo + espacio entre tablas
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' } // Ancho automático para la primera columna
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });

        }
        else {
          doc.autoTable({
          head: [table2Data[0]],
          body: table2Data.slice(1,30),
          startY: 400, 
          startX: 1,
          margin: { right: 200}, // Margen izquierdo + espacio entre tablas
          theme: 'grid',
          styles: {tableStyle, lineColor: [0, 0, 0],fontSize: 6 },
          columnStyles: {
            0: { cellWidth: 'auto' } // Ancho automático para la primera columna
          },
          headStyles: { 
          fillColor: [255, 255, 255], // Color azul (RGB: 0, 0, 255)
          lineColor: [0, 0, 0],
          textColor: [0, 0, 0],
          halign: 'center',
          lineWidth: 0.1,

        }
        });
        }
        
      
      // Guardamos el PDF
      doc.save('documento.pdf');


      });

      });


     
    },
  },
  data() {

    return {
      

    }
  },
}
</script>

<style lang="">

</style>
