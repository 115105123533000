import { render, staticRenderFns } from "./modal_rehacerAnalisis-supervisor.vue?vue&type=template&id=067c6fef&"
import script from "./modal_rehacerAnalisis-supervisor.vue?vue&type=script&lang=js&"
export * from "./modal_rehacerAnalisis-supervisor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports