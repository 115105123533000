<template>
    <div>
        <!-- <validation-observer ref="form"> -->
        <modal_ingresarSubmuestra :n-muestras="nMuestras" :objetosSeleccionados="objetosSeleccionados" :opcionesParametrosSeleccionados="opcionesParametrosSeleccionados" :actualizarModal="actualizarModal"
         @datosIngresados="capturarDatos" :identificaciones="identificacion" :submuestra_agregar="submuestra_agregar" />
        <modal_agregarSolicitante/>
        <modal_agregarEmpresa @refrescar="obtenerEmpresas" />
        <m_agregarParametro @refrescar="this.obtenerParametro"/>
        <m_agregarMetodologia @refrescar="this.obtenerParametro"/>
        <modal_resumenMuestra :resumen-data="this.resumenData" ref="modal_resumenMuestra" />
        <div>
            <b-card no-body>
                <div style="height:20px"></div>
                <b-row class="d-flex justify-content-center">
                    <b-col class="col-8">
                        <b-tabs active-nav-item-class="lsa-orange" no-fade v-model="tabIndex" pills card vertical
                            style="height:65vh">
                            <b-row class="d-flex justify-content-center">
                                <b-col class="col-12">

                                    <b-tab title="Recepcionista">

                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon v-if="!recepcionista_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Recepcionista</strong>
                                                </b-row>
                                            </b-col>
                                        </template>

                                        <validation-observer ref="valrecepcionista">
                                            <strong> Datos de la recepcion </strong>
                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        <ValidationProvider name="Rut Recepcionista" rules="required|rut"
                                                            v-slot="validationContext">
                                                            <label for="RutRecepcionista-input">RUT del recepcionista:</label>
                                                            <b-form-input id="Rut Recepcionista-input" readonly
                                                                v-model="recepcionistaRUT"
                                                                :state="getValidationState(validationContext)"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                            }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
                                                    </b-col>
                                                    <b-col class="col-6">
                                                        <ValidationProvider name="NombreRecepcionista" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Nombre del recepcionista:</label>
                                                            <b-form-input v-model="recepcionista" readonly
                                                                :state="getValidationState(validationContext)"
                                                                placeholder="Seleccione un recepcionista"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                            }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
                                                    </b-col>
                                                </b-row>                                            
                                            </b-card>
                                        </validation-observer>

                                        <strong> Identificación del cliente </strong>
                                        <b-card>
                                            <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        
                                                        <label for="input-live">RUT Solicitante (Sin puntos ni guion)</label>
                                                        <div class="d-flex align-items-center ">
                                                            <b-input-group>
                                                                <!-- Input como filtro de opciones -->
                                                                <b-form-input
                                                                    v-model="rutFilter"
                                                                    placeholder="Buscar solicitante..."
                                                                    :disabled="solicitanteIngresado"
                                                                ></b-form-input>
                                                                <b-button class=" lsa-orange reactive-button"
                                                                    @click="obtenerDatosSolicitante()">
                                                                    <b-icon icon="search"></b-icon>
                                                                </b-button>
                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        v-b-modal.modal-solicitante>
                                                                        <b-icon style="color:white" icon="person-plus">
                                                                        </b-icon>
                                                                    </b-button>
                                                                    <b-button class=" lsa-orange reactive-button"
                                                                    @click="limpiarDatosIdentificacion()">
                                                                    <b-icon icon="arrow-clockwise"></b-icon>
                                                                    </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>    
                                                        
                                                        <b-alert :show="dismissCountDown" dismissible fade variant="danger"
                                                            @dismiss-count-down="countDownChanged">
                                                            El RUT del solicitante no está registrado en la base de datos.
                                                        </b-alert>

                                                        <label for="NombreSolicitante-input">Nombre solicitante:</label>
                                                        <b-form-input id="Nombre Solicitante-input" readonly v-model="nombreSolicitante" ></b-form-input>

                                                        <label for="ObservacionCliente-input">Observación Cliente:</label>
                                                        <b-form-input id="Observacion Cliente-input" v-model="observacionCliente" ></b-form-input>
                                                            
                                                        
                                                    </b-col>
                                                    <b-col class="col-6">
                                                        
                                                        <label for="input-live">Nombre de la empresa:</label>
                                                        <div class="d-flex align-items-center ">
                                                            <b-input-group>
                                                                <b-form-select id="input-live" v-model="empresaSolicitante"
                                                                    :options="empresasDelSolicitante"
                                                                    
                                                                    :disabled="empresaSolicitante === 'Particular'" 
                                                                    text-field="nombre_empresa"
                                                                    value-field="rut_empresa"
                                                                    @input="actualizarSelectedEmpresa" filter>
                                                                    <template #first>
                                                                        <b-form-select-option v-if="tieneEmpresa" disabled :value="null">Selecciona una empresa</b-form-select-option>
                                                                        <b-form-select-option v-if="!tieneEmpresa" disabled :value="null">Solicitante sin empresas asociadas</b-form-select-option>
                                                                    </template>   

                                                                </b-form-select>
                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        v-b-modal.modal-empresa>
                                                                        <b-icon style="color:white" icon="person-plus">
                                                                        </b-icon>
                                                                    </b-button>
                                                                </b-input-group-append>
                                                                
                                                            </b-input-group>
                                                        </div>
                                                            
                                                       

                                                        
                                                        <label for="input-live">Dirección de la empresa:</label>
                                                        <b-form-select id="input-live" v-model="direccion"
                                                            :options="opcionesDireccion"
                                                            :disabled="empresaSolicitante === '' ||empresaSolicitante === 'Particular'||!empresaSeleccionada"
                                                            text-field="direccionConCiudad" value-field="id_ciudad">
                                                        </b-form-select>
                                                            
                                                        <label for="input-live">Cotización:</label>
                                                        <b-form-select id="input-live" v-model="cotizacion"
                                                            :options="opcionesCotizacion" text-field="idconNombre" value-field="id_cotizacion"
                                                            aria-describedby="input-live-help cotizacion-live-feedback">
                                                            <template #first>
                                                                <b-form-select-option v-if="solicitanteIngresado && tieneCotizaciones" disabled :value="null">Seleccione una cotización</b-form-select-option>
                                                                <b-form-select-option v-if="!solicitanteIngresado" disabled :value="null">Ingrese solicitante para visualizar sus cotizaciones</b-form-select-option>
                                                                <b-form-select-option v-if="solicitanteIngresado && !tieneCotizaciones" disabled :value="null">Solicitante sin cotizaciones asociadas</b-form-select-option>

                                                            </template>   
                                                        </b-form-select>
                                                    </b-col>
                                            </b-row>      
                                        </b-card>
                                    </b-tab>

                                    <b-tab title="Muestra">
                                        <strong> Datos de la muestra</strong>
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon v-if="!muestra_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Muestra</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valmuestra">
                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">

                                                        <ValidationProvider name="nMuestras" rules="required|numeric">
                                                            <label for="input-live">N° de muestras:</label>
                                                            <div class="d-flex align-items-center">
                                                                <b-input-group size="sm">
                                                                    <b-form-spinbutton id="nMuestras-input" v-model="nMuestras" min="1" max="100" step="1"                                                                       
                                                                        aria-describedby="nMuestras-live-feedback"></b-form-spinbutton>

                                                                </b-input-group>
                                                                
                                                            </div>
                                                            
                                                        </ValidationProvider>

                                                        <ValidationProvider name="fecha de recepcion" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Fecha de recepción:</label>
                                                            <b-form-datepicker
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                id="input-live" v-model="fecha_recepcion"
                                                                aria-describedby="input-live-help fecha de recpecion-live-feedback"
                                                                :state="getValidationState(validationContext)"
                                                                placeholder="Seleccione fecha">
                                                            </b-form-datepicker>
                                                            <b-form-invalid-feedback
                                                                id="fecha de recepcion-live-feedback">{{
                                                                    validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="hora de recepcion" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-time">Hora de recepción:</label>
                                                            <b-form-input 
                                                                id="input-time" 
                                                                v-model="hora_recepcion"
                                                                aria-describedby="input-live-help hora-de-recepcion-live-feedback"
                                                                :state="getValidationState(validationContext)"
                                                                :type="'time'"
                                                                placeholder="Ingrese hora">
                                                            </b-form-input>
                                                            <b-form-invalid-feedback id="hora de recepcion-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="fecha de muestreo" rules="required"
                                                            v-slot="validationContext">
                                                        <label for="input-live">Fecha de muestreo:</label>
                                                        <b-form-datepicker
                                                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                            id="input-live" v-model="fecha" :state="getValidationState(validationContext)" placeholder="Seleccione fecha">
                                                        </b-form-datepicker>
                                                        <b-form-invalid-feedback id="fecha de muestreo-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="hora de mustreo" rules="required"
                                                            v-slot="validationContext">
                                                        <label for="input-time">Hora de muestreo:</label>
                                                        <b-form-input 
                                                            id="input-time" 
                                                            v-model="hora"
                                                            aria-describedby="input-live-help hora-de-muestreo-live-feedback"
                                                            :state="getValidationState(validationContext)"
                                                            :type="'time'"
                                                            placeholder="Ingrese hora">
                                                        </b-form-input>
                                                        <b-form-invalid-feedback id="hora de muestreo-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="fecha de entrega" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Fecha de entrega:</label>
                                                            <b-form-datepicker
                                                                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                                id="input-live" v-model="fechaEntrega"
                                                                aria-describedby="input-live-help fecha de entrega-live-feedback"
                                                                placeholder="Seleccione fecha" :min="currentDate"
                                                                :state="getValidationState(validationContext)">
                                                            </b-form-datepicker>
                                                            <b-form-invalid-feedback id="fecha de entrega-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        
                                                        

                                                    </b-col>

                                                    <b-col class="col-6">
                                                        <ValidationProvider name="muestreado" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Muestreado por:</label>
                                                            <b-form-select id="input-live" v-model="muestreado"
                                                                :options="opcionesMuestreado"
                                                                aria-describedby="input-live-help muestreado-live-feedback"
                                                                :state="getValidationState(validationContext)"></b-form-select>
                                                            <b-form-invalid-feedback id="muestreado-live-feedback">{{
                                                                validationContext.errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="prioridad" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Prioridad:</label>
                                                            <b-form-select id="input-live" v-model="prioridad"
                                                                :options="opcionesPrioridad"
                                                                aria-describedby="input-live-help prioridad-live-feedback"
                                                                :state="getValidationState(validationContext)"></b-form-select>
                                                            <b-form-invalid-feedback id="prioridad-live-feedback">{{
                                                                validationContext.errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="matriz" :rules="isCheckboxCheckedMatriz ? '' : 'required'"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Matriz:</label>
                                                            <b-input-group size="sm" class="mb-2">
                                                            <b-form-select id="input-live" v-model="TipoMatriz"
                                                                :options="opcionesMatriz"
                                                                aria-describedby="input-live-help matriz-live-feedback"
                                                                :state="getValidationState(validationContext)"
                                                                text-field="nombre_matriz"
                                                                value-field="id_matriz"
                                                                :disabled="isCheckboxCheckedMatriz">
                                                            </b-form-select>
                                                            <b-input-group-append is-text>
                                                                <b-form-checkbox v-model="isCheckboxCheckedMatriz" switch @input="TipoMatriz = isCheckboxCheckedMatriz ? '' : TipoMatriz" class="mr-n2 mb-n1"></b-form-checkbox>
                                                            </b-input-group-append>
                                                            <b-form-invalid-feedback id="matriz-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-input-group>
                                                            
                                                        </ValidationProvider>




                                                        <ValidationProvider name="Temperatura" :rules="isCheckboxCheckedTemp ? '' : 'required|decimal'"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Temperatura (°C):</label>
                                                            <b-input-group size="sm" class="mb-2">
                                                                <b-form-input id="Temperatura-input" v-model="Temperatura" aria-describedby="Temperatura-live-feedback" :disabled="isCheckboxCheckedTemp"
                                                                     :state="getValidationState(validationContext)"
                                                                      ></b-form-input>
                                                            <b-input-group-append is-text>
                                                                <b-form-checkbox v-model="isCheckboxCheckedTemp" switch @input="clearInput" class="mr-n2 mb-n1"></b-form-checkbox>
                                                            </b-input-group-append>
                                                            <b-form-invalid-feedback id="Temperatura-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                            </b-input-group>
                                                            
                                                            
                                                        </ValidationProvider>


                                                        <label for="input-live">Observaciones:</label>
                                                        <b-form-textarea  id="input-live" v-model="observaciones" max-rowsrows="5" rows="5" maxlength="500" :style="{ maxHeight: `${maxRows * rowHeight}px` }" 
                                                            aria-describedby="input-live-help observaciones-live-feedback"></b-form-textarea>
                                                    </b-col>
                                                </b-row>
                                            </b-card>

                                        </validation-observer>
                                    </b-tab>

                                    <b-tab title="Transportista">
                                        <strong>Datos del transportista</strong>

                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon v-if="!transportista_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Datos del transportista</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valtransportista">

                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        <ValidationProvider name="Rut Transportista" rules="rut|rutSinPuntoGuion|max:9"
                                                            v-slot="validationContext">
                                                        <label for="input-live">RUT:</label>
                                                        <b-form-input id="transportistaRut-input" class="mb-1"
                                                            v-model="transportistaRut"
                                                            aria-describedby="transportistaRut-live-feedback"
                                                            :state="getValidationState(validationContext)"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                            }}</b-form-invalid-feedback>
                                                        </ValidationProvider>


                                                        <label for="input-live">Teléfono móvil:</label>
                                                       
                                                        <ValidationProvider name="número móvil" rules="numeric|min:8|max:15"
                                                            v-slot="validationContext">
                                                            <b-input-group size="sm" class="mb-1">

                                                                <b-input-group-prepend is-text>
                                                                    +56 9
                                                                </b-input-group-prepend>
                                                                <b-form-input id="input-live" v-model="telefono_transportista"
                                                                    aria-describedby="input-live-help movil-live-feedback"
                                                                    :state="getValidationState(validationContext)" placeholder=""></b-form-input>
                                                                <b-form-invalid-feedback id="movil-live-feedback">{{
                                                                    validationContext.errors[0] }}
                                                                </b-form-invalid-feedback>
                                                            </b-input-group>

                                                        </ValidationProvider>
                                                        
                                                    </b-col>

                                                    <b-col class="col-6">
                                                        <label for="input-live">Nombre y apellido:</label>
                                                        <b-form-input class="mb-1" id="input-live" v-model="transportista"
                                                            aria-describedby="input-live-help transportista-live-feedback"
                                                            placeholder="" trim>
                                                        </b-form-input>

                                                        <label for="input-live">Patente:</label>
                                                        <b-form-input class="mb-1" id="input-live" v-model="patente"
                                                            aria-describedby="input-live-help patente-live-feedback"
                                                            placeholder="" trim>
                                                        </b-form-input>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </validation-observer>
                                    </b-tab>

                                    <b-tab title="Parámetros">
                                        <strong> Parámetros</strong>

                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">

                                                    <b-icon v-if="!parametros_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Parámetros</strong>
                                                </b-row>
                                            </b-col>
                                        </template>

                                        <validation-observer ref="valparametros">

                                            <b-card>
                                                <b-row>
                                                    <b-col class="col-6">
                                                        <b-form-group label="Seleccione una norma:">
                                                            <b-input-group>
                                                                <b-form-select v-model="norma" :options="opcionesNorma"
                                                                    placeholder="Seleccione una norma:" text-field="nombre"
                                                                    value-field="id"
                                                                    @change="obtenerTablasNormas"
                                                                    :disabled="normaDisabled">
                                                                </b-form-select>

                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        @click="PushParametrosMetodologias()">
                                                                        <b-icon style="color:white"
                                                                            icon="box-arrow-in-down-left">
                                                                        </b-icon>
                                                                    </b-button>
                                                                </b-input-group-append>

                                                            </b-input-group>
                                                            <b-form-checkbox v-model="normaDisabled" switch @input="norma = normaDisabled ? null : norma;tabla = normaDisabled ? null : tabla" @change="resetOpcionesParametrosInciales">
                                                                    Desactivar norma
                                                            </b-form-checkbox>
                                                        </b-form-group>
                                                    </b-col>

                                                    <b-col class="col-6">
                                                        <b-form-group label="Seleccione una tabla:">
                                                            <b-input-group>
                                                                <b-form-select :disabled="!norma" v-model="tabla"
                                                                :options="opcionesTabla" value-field="id"
                                                                @change="actualizarParametrosTabla">
                                                                </b-form-select>

                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        @click="PushParametrosMetodologiasTabla()">
                                                                    <b-icon style="color:white"
                                                                        icon="box-arrow-in-down-left">
                                                                    </b-icon>
                                                                </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>

                                                <b-row>
                                                    <b-col>
                                                        <label for="input-live">Seleccione un parámetro:</label>
                                                        <b-input-group>

                                                            <b-form-select v-model="parametroSeleccionado"
                                                                :options="opcionesParametro"
                                                                placeholder="Seleccione un Parámetro"
                                                                @change="agregarObjetosSeleccionados">
                                                            </b-form-select>

                                                            <b-input-group-append>
                                                                <b-button size="sm" class="lsa-orange reactive-button"
                                                                    style="aspect-ratio:1; border: none"
                                                                    v-b-modal.modal-Agregar-Parametro>
                                                                    <b-icon style="color:white" icon="plus-circle-fill">
                                                                    </b-icon>
                                                                </b-button>
                                                            </b-input-group-append>

                                                        </b-input-group>
                                                    </b-col>

                                                    <b-col>
                                                        <label for="input-live">Seleccione una metodología:</label>
                                                        <b-input-group>

                                                            <b-form-select v-model="metodologiaSeleccionada"
                                                                :options="opcionesMetodologia"
                                                                placeholder="Seleccione una metodología"
                                                                :disabled="metodologiaDeshabilitada"
                                                                @change="agregarObjetosSeleccionados">
                                                            </b-form-select>

                                                            <b-input-group-append>
                                                                <b-button size="sm" class="lsa-orange reactive-button"
                                                                    style="aspect-ratio:1; border: none"
                                                                    v-b-modal.modal-Agregar-Metodologia>
                                                                    <b-icon style="color:white" icon="plus-circle-fill">
                                                                    </b-icon>
                                                                </b-button>
                                                            </b-input-group-append>

                                                        </b-input-group>
                                                    </b-col>
                                                </b-row>

                                                <b-row v-if="objetosSeleccionados.length > 0" class="mt-3">
                                                    <b-col>

                                                        <!--
                                                            <b-form-group label-size="sm"
                                                                style="overflow-y: scroll; height: 300px;"
                                                                label="Parámetros seleccionados:">
                                                                <div v-for="(objetos, index) in objetosSeleccionados"
                                                                    :key="index" class="d-flex align-items-center">
                                                                    <b-input readonly
                                                                        style="padding-top:1px; padding-bottom: 1px;"
                                                                        :value="objetos.parametro"
                                                                        :state="getValidationState(validationContext)"
                                                                        class="mr-2">
                                                                    </b-input>
                                                                    <b-input style="padding-top:1px; padding-bottom: 1px;"
                                                                        readonly :value="objetos.metodologia"
                                                                        :state="getValidationState(validationContext)"
                                                                        class="mr-2">
                                                                    </b-input>
                                                                    <b-button variant="danger" style="aspect-ratio: 1;"
                                                                        @click="eliminarObjetosSeleccionados(index)"
                                                                        class="ml-2">
                                                                        <b-icon-trash-fill></b-icon-trash-fill>
                                                                    </b-button>
                                                                </div>
                                                            </b-form-group>
-->
                                                        <label style="font-weight: bold;">Parámetros seleccionados:</label>
                                                        <b-form-group label-size="sm"
                                                            style="overflow-y: scroll; height: 250px; border:1px solid lightgray;">
                                                            <b-list-group horizontal
                                                                v-for="(objetos, index) in objetosSeleccionados"
                                                                :key="index">
                                                                <b-list-group-item
                                                                    class="d-flex align-items-center justify-content-center"
                                                                    style="width:45%; padding:2px; height:40px">
                                                                    {{ objetos.parametro }}
                                                                </b-list-group-item>
                                                                <b-list-group-item
                                                                    class="d-flex align-items-center justify-content-center"
                                                                    style="width:45%; padding:2px; height:40px">
                                                                    {{ objetos.metodologia }}
                                                                </b-list-group-item>
                                                                <b-list-group-item
                                                                    class="d-flex align-items-center justify-content-center"
                                                                    style="width:10%; padding:2px; height:40px">
                                                                    <b-button
                                                                        class="reactive-button d-flex align-items-center justify-content-center"
                                                                        variant="danger" style="height:30px; width:30px;"
                                                                        @click="eliminarObjetosSeleccionados(index)">
                                                                        <b-icon scale="0.8" icon="trash-fill"></b-icon>
                                                                    </b-button>
                                                                </b-list-group-item>

                                                            </b-list-group>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>

                                                <b-alert variant="danger" :show="alertaDuplicado" dismissible
                                                    @dismissed="alertaDuplicado = false">
                                                    El parámetro y su metodologia ya se encuentra agregado.
                                                </b-alert>
                                            </b-card>
                                        </validation-observer>
                                    </b-tab>

                                    <b-tab title="Asignar parámetros a muestras">
                                        <strong> Asignar identificadores a la muestra</strong>

                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-end">
                                                    <b-icon icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Asignar identificadores a la
                                                        muestra</strong>
                                                </b-row>
                                            </b-col>
                                        </template>

                                        <b-card>
                                            <div class="mt-5">
                                                <b-button class="lsa-orange reactive-button" @click="agregar()"
                                                    style="border: none" pill size="md">
                                                    Asignar identificadores a la muestra
                                                    <b-icon icon="plus-circle-fill"></b-icon>
                                                </b-button>
                                            </div>
                                        </b-card>
                                    </b-tab>

                                </b-col>

                            </b-row>
                        </b-tabs>





                        <b-row class="d-flex justify-content-center mt-2 mb-2">
                            <b-col class="col-11">
                                <b-row class="d-flex justify-content-between" style="width:100%">

                                    <div style="width:30%">
                                        <b-row class="d-flex justify-content-around">
                                            <b-col class="col-6">
                                                <b-button v-if="tabIndex > 0" style="font-weight: bold;"
                                                    class="lsa-blue reactive-button  d-flex justify-content-between" block
                                                    pill @click="goToPreviousTab">
                                                    <b-icon icon="arrow-left-circle-fill"></b-icon>
                                                    Atrás
                                                </b-button>
                                            </b-col>
                                            <b-col class="col-6">
                                                <b-button v-if="shouldShowNextButton()" style="font-weight: bold;" block
                                                    class="lsa-blue reactive-button d-flex justify-content-between" pill
                                                    @click="goToNextTab">

                                                    Siguiente
                                                    <b-icon icon="arrow-right-circle-fill"></b-icon>
                                                </b-button>
                                            </b-col>
                                        </b-row>
                                    </div>
                                    <div style="width:70%">
                                        <b-row class="d-flex justify-content-end">
                                            <b-button @click="abrirResumenMuestra()" pill
                                                class="lsa-blue reactive-button mr-2"
                                                style="border:none; font-weight: bold;">
                                                Resumen
                                                <b-icon icon="file-earmark-text" aria-hidden="true"></b-icon>
                                            </b-button>
                                            <b-button @click="enviarFormulario()" variant="primary" size="xl"
                                                class="reactive-button lsa-light-blue ml-2" pill
                                                style="font-weight:bold; min-width: 50%;">
                                                Recepcionar muestra
                                                <b-icon icon=" clipboard-check" aria-hidden="true"></b-icon>
                                            </b-button>
                                        </b-row>
                                    </div>

                                </b-row>
                            </b-col>
                        </b-row>


                    </b-col>

                </b-row>



            </b-card>



            <!--
                <b-row class="d-flex justify-content-center mt-2">
                    <b-col class="col-10">
                        <b-button @click="enviarFormulario()" variant="primary" size="xl"
                                                    class="reactive-button" style="font-weight:bold;">
                                                    Recepcionar Muestra
                                                </b-button>
                    </b-col>
                </b-row>
                -->
            <!-- Control buttons-->

            <b-modal centered id="modal-Agregar-Opciones" ref="modal" :title="`Agregar parámetro a opciones`" size="lg"
                @shown="onModalShown">
                <template #modal-header="{ close }">
                    <b-row class="d-flex justify-content-around">
                        <div class="pl-3">Agregar parámetros</div>
                    </b-row>
                    <button type="button" class="close" aria-label="Close" @click="close()">
                        <span aria-hidden="true" style="color:white">&times;</span>
                    </button>
                </template>
                <b-row>
                    <b-col>
                        <label>Seleccione un parámetro:</label>
                        <b-input-group>
                            <b-form-select v-model="parametroSeleccionado" :options="TodasopcionesParametro"
                                placeholder="Seleccione un parámetro" @change="agregarObjetosSeleccionados"></b-form-select>
                            <b-input-group-append>
                                <b-button size="sm" class="lsa-orange reactive-button" style=" border: none"
                                    v-b-modal.modal-Agregar-Parametro>
                                    Nuevo
                                    <b-icon style="color:white" icon="pencil-square"></b-icon>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>

                    <b-col>
                        <label>Seleccione una metodología:</label>
                        <b-input-group>
                            <b-form-select v-model="metodologiaSeleccionada" :options="opcionesMetodologia"
                                placeholder="Seleccione una metodología" :disabled="metodologiaDeshabilitada"
                                @change="agregarObjetosSeleccionados"></b-form-select>
                            <b-input-group-append>
                                <b-button size="sm" class="lsa-orange reactive-button" style="border: none"
                                    v-b-modal.modal-Agregar-Metodologia>
                                    Nuevo
                                    <b-icon style="color:white" icon="pencil-square"></b-icon>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>

                <b-alert variant="danger" :show="alertaDuplicado" dismissible @dismissed="alertaDuplicado = false">
                    Los parámetros y metodologías ya se encuentran agregados.
                </b-alert>

                <b-alert variant="success" :show="alertaExito" dismissible @dismissed="alertaExito = false">
                    Parámetro y metodología agregada con éxito.
                </b-alert>
                <!-- //////////////////////////////////////////MODAL-FOOTER////////////////////////////////////////////////////////////////////////////////// -->
                <template #modal-footer="{ close }">
                    <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button"
                        style="font-weight:bold">
                        Cerrar
                    </b-button>
                </template>
            </b-modal>
        </div>
        <!--  </validation-observer> -->
    </div>
</template>

<script>
import MuestraService from '@/helpers/api-services/Muestra.Service';
import modal_ingresarSubmuestra from '@/components/recepcionMuestra/modal_ingresarSubmuestra.vue';
import ElementosService from '@/helpers/api-services/Elementos.service';
import PersonalService from '@/helpers/api-services/Personal.service';
//import EmpresaService from '@/helpers/api-services/Empresa.service';
import SolicitanteService from "@/helpers/api-services/Solicitante.service";

import modal_resumenMuestra from '@/components/recepcionMuestra/modal_ResumenMuestra.vue';
import modal_agregarSolicitante from '@/components/admSolicitante/modal_agregarSolicitante.vue';
import modal_agregarEmpresa from '@/components/admEmpresa/modal_agregarEmpresa.vue';


import m_agregarParametro from '@/components/admParametro/modal_agregarParametro.vue';
import m_agregarMetodologia from '@/components/admElementosMuestra/modal_agregarMetodologia.vue';

import {
    getUserInfo
} from "@/helpers/api-services/Auth.service";
import {
    isLoggedIn
} from "@/helpers/api-services/Auth.service";

export default {

    components: {
        modal_ingresarSubmuestra,
        //modal_agregarMetodologia,
        //modal_agregarParametro,
        modal_resumenMuestra,

        m_agregarParametro,
        m_agregarMetodologia,

        modal_agregarSolicitante,
        modal_agregarEmpresa,
    },

    data() {

        return {
            solicitante: '',
            observacionCliente: '',
            nombreSolicitante: '',
            rutSolicitante: '',
            telefono_solicitante: '',
            correo_solicitante: '',
            rutFilter:'',
            empresasDelSolicitante: [],
            recepcionista: '',
            recepcionistaRUT: '',
            nombre_empresa: '',
            empresaSolicitante: '',
            rut: '',
            rut_empresa: '',
            metodologiaSeleccionada: '',
            objetosSeleccionados: [],
            direccion: '',
            direccion_empresa: '',
            muestreado: '',
            opcionesDireccion: [],
            opcionesMuestreado: [{
                value: 'UCN-LSA',
                text: 'UCN-LSA'
            },
            {
                value: 'Cliente',
                text: 'Cliente'
            }
            ],
            prioridad: null,
            opcionesPrioridad: [{
                value: 'Normal',
                text: 'Normal'
            },
            {
                value: 'Urgente',
                text: 'Urgente'
            }
            ],
            TipoMatriz: null,
            opcionesMatriz: [],
            opcionesRecepcionistas: [],
            opcionesClientes: [],
            opcionesParametro: [],
            norma: '',
            opcionesNorma: [],
            tabla: '',
            opcionesTabla: [],
            parametroSeleccionado: '',
            ParametrosSeleccionados: [],
            parametros: [],
            alertaDuplicado: false,
            alertaExito: false,
            alertaExiste: false,
            alertaNOexiste: false,
            transportista: '',
            Temperatura: '',
            transportistaRut: '',
            telefono_transportista: '',
            fono: '',
            fechaEntrega: '',
            observaciones: '',
            nMuestras: 1,
            muestras: [],
            fecha: "",
            hora: "",
            patente: "",
            estado: null,
            tabIndex: 0,
            identificacion: [''],
            telefonos_agregar: [],
            TodasopcionesParametro: [],
            opcionesParametrosSeleccionados: [],
            submuestra_agregar: [{
                identificador: '',
                orden: '',
                parametros_agregar: [],
            }],
            userData: '',
            dismissSecs: 5,
            dismissCountDown: 0,
            parametrosTablaSeleccionada: [],
            opcionesMetodologia: [],
            metodologiaDeshabilitada: true,
            parametroDeshabilitado: true,
            direccionDeshabilitado: true,
            metodologias: [],
            metodologiasData: [],
            parametros_agregar: [],
            selectedEmpresa: {
                id_ciudad: '',
                nombre_ciudad: '',
                direccion: ''
            },
            id_tabla: '',
            recepcionista_incompleto: true,
            muestra_incompleto: true,
            transportista_incompleto: true,
            parametros_incompleto: true,
            revisado: false,
            opcionesCotizacion: [],
            cotizacion: '',

            opcionesEmpresa: [],
            totalTabs: 5,
            fecha_recepcion: null,
            hora_recepcion: null,
            resumenData: {},
            isCheckboxCheckedTemp: false,
            isCheckboxCheckedMatriz: false,
            normaDisabled: false,
            opcionesIniciales: [ /* Aquí guardaremos las opciones iniciales */ ],

            inputText: '',
            actualizarModal: 0,
            maxRows: 5, 
            rowHeight: 24,
            numero_informe: undefined,
            tieneEmpresa: true,
            solicitanteIngresado: false,
            tieneCotizaciones: false,
            empresaSeleccionada: false,
            currentDate: new Date(),
            formattedDate: null,
            formattedTime: null,
            

        };
    },

    async created() {
        if (isLoggedIn()) {
            this.userData = getUserInfo();
            this.recepcionista = `${this.userData.nombre} ${this.userData.apellido}`;
            this.recepcionistaRUT = `${this.userData.rut}`;
        }
    },

    mounted() {
        this.$root.$on('refrescar', () => {
            this.obtenerParametro();
        });

        this.obtenerParametro();

        this.obtenerMatriz(),

        this.obtenerEmpresas(),

        this.obtenerNormas(),

        PersonalService.obtenerTodosPersonal().then((response) => {
            if (response != null && response.status === 200) {
                this.recepcionistas = response.data
            }
        });
        const formattedDate = this.currentDate.toISOString().split('T')[0];
        const formattedTime = this.currentDate.toTimeString().split(' ')[0].substring(0, 5);
        this.fecha_recepcion = formattedDate;
        this.hora_recepcion = formattedTime;
        
    },

    watch: {
        

        parametroSeleccionado: function (newParametro) {
            if (newParametro) {
                this.actualizarMetodologias();
                this.metodologiaDeshabilitada = false; // Habilita el input 
            } else {
                this.metodologiaDeshabilitada = true;
            }
        },

        tabla: function (nuevoValor) {
            const tablaSeleccionada = this.tablasProcesadas.find(tabla => tabla.nombre_tabla === nuevoValor);
            if (tablaSeleccionada) {
                const idTabla = tablaSeleccionada.id_tablas[0]; // Suponiendo que solo haya una id_tabla por cada nombre_tabla
                this.id_tabla = idTabla
                this.parametroDeshabilitado = false;
            } else {
                this.parametroDeshabilitado = true;
            }
        },

        direccion(newValue) {
            const direccionSeleccionada = this.opcionesDireccion.find(opcion => opcion.id_ciudad === newValue);
            if (direccionSeleccionada) {
                const direccion = direccionSeleccionada.direccion;
                // Realiza cualquier otra acción necesaria con la dirección seleccionada
                this.direccion_empresa = direccion;
            } else {
                console.log("No se encontró la dirección seleccionada.");
            }
        },
        nMuestras:{
            handler(nuevaNmuestras,antiguaNmuestras){
                if(nuevaNmuestras>antiguaNmuestras){
                    for (let i = 1; i <= nuevaNmuestras; i++) {
                        if(i > antiguaNmuestras){
                            this.submuestra_agregar.push({
                            identificador: '',
                            orden: '',
                            parametros_agregar: [],
                            });
                            this.identificacion.push('');
                        }
                    }

                }else if(nuevaNmuestras<antiguaNmuestras){
                    this.submuestra_agregar.splice(nuevaNmuestras,antiguaNmuestras-nuevaNmuestras);
                    this.identificacion.splice(nuevaNmuestras,antiguaNmuestras-nuevaNmuestras);
                }
            }
        }
    },

    methods: {
        
        limpiarDatosIdentificacion() {
            this.nombreSolicitante = '';
            this.rutSolicitante = '';
            this.direccion = '';
            this.empresaSolicitante = '';
            this.empresaSeleccionada = false;
            this.nombre_empresa = '';
            this.tieneEmpresa = true;
            this.opcionesDireccion  = [];
            this.empresasDelSolicitante = this.opcionesEmpresa;
            this.solicitanteIngresado = false;
            this.rutFilter = '';

            this.telefono_solicitante = '';
            this.correo_solicitante = '';
            this.opcionesCotizacion = [];
            this.cotizacion = '';
            this.tieneCotizaciones = false;
        },
        obtenerDatosSolicitante() {
            

            SolicitanteService.obtenerDetallesSolicitante(this.rutFilter).then((response) => {
                
                try{
                    if (response.request.status == 200 && this.rutFilter !== "") {
                    this.solicitanteIngresado = true;
                    this.empresaSeleccionada = false;
                    this.opcionesDireccion  = [];
                    this.direccion = '';
                    this.nombre_empresa = '';
                    this.nombreSolicitante = `${response.data.nombre} ${response.data.primer_apellido} ${response.data.segundo_apellido}`;
                    this.rutSolicitante = response.data.rut_solicitante; 
                    this.telefono_solicitante = response.data.telefono;
                    this.correo_solicitante = response.data.correo;

                    if(response.data.detalles_empresas.length > 0){
                        this.tieneEmpresa = true;
                    }else{
                        this.tieneEmpresa = false;
                    
                    }
                    const empresas = response.data.detalles_empresas;
                    this.empresasDelSolicitante = empresas;
                    if(response.data.tipo_cliente === 'Particular'){
                        this.empresaSolicitante = 'Particular';
                        this.nombre_empresa = 'Particular';
                        this.rut_empresa =null;
                        this.empresasDelSolicitante = [{nombre_empresa: 'Particular',rut_empresa:'Particular'}];
                    }
                    const data = { rut_solicitante: this.rutSolicitante };
                    SolicitanteService.CotizacionSolicitante(data).then((response) => {
                        if (response.data != null && response.status === 200) {
                            this.opcionesCotizacion = response.data.map(cotizacion => ({
                                id_cotizacion: cotizacion.id_cotizacion,
                                nombre_original_documento: cotizacion.nombre_original_documento,
                                idconNombre: `${cotizacion.id_cotizacion} - ${cotizacion.nombre_original_documento}`
                            }))
                            if(this.opcionesCotizacion.length>0){
                                this.tieneCotizaciones = true;
                            }else{
                                this.tieneCotizaciones = false;
                            }
                        }
                    })

                }

                }catch{
                    this.showAlert();
                }
                
                
            
            })
        },
        handleInput(value) {
            // Método para manejar la selección del campo de selección
            this.empresaSolicitante = value;
        },
        getCurrentDate() {
            const now = new Date();
            const formattedDate = ('0' + now.getDate()).slice(-2) + '-' + ('0' + (now.getMonth() + 1)).slice(-2) + '-' + now.getFullYear();
            return formattedDate;
        },
        getCurrentTime() {
            const now = new Date();
            return now.toTimeString().slice(0, 5); // Formato HH:MM
        },
        matrizRules() {
            return this.isMatrizRequired ? 'required' : '';
        },

        clearInput() {
            if (this.isCheckboxCheckedTemp) {
                this.Temperatura = ''; // Borramos el contenido cuando el interruptor se activa
            }
        },

        abrirResumenMuestra() {

            var numero_cotizacion = "Sin cotizacion";
            var matriz = "Sin matriz";

            try {
                numero_cotizacion = this.opcionesCotizacion.find(c => c.id_cotizacion == this.cotizacion).id_cotizacion;
            } catch (error) {
                ///console.log(error);
            }
            try {
                matriz = this.opcionesMatriz.find(m => m.id_matriz == this.TipoMatriz).nombre_matriz;
            } catch (error) {
                ///console.log(error);
            }
            var data = {
                numero_muestras: this.nMuestras,
                fecha_entrega: this.fechaEntrega,
                fecha_ingreso: this.fecha_recepcion,
                nombre_solicitante: this.nombreSolicitante,
                cliente: this.nombre_empresa,
                direccion: this.direccion_empresa,
                telefono_solicitante: this.telefono_solicitante,
                correo_solicitante: this.correo_solicitante,
                prioridad: this.prioridad,
                fecha_muestreo: this.fecha,
                hora_muestreo: this.hora,
                nombre_chofer: this.transportista,
                rut_chofer: this.transportistaRut,
                telefono_transportista: this.telefono_transportista,
                patente: this.patente,
                temperatura_transporte: this.Temperatura,
                fecha_recepcion: this.fecha_recepcion,
                hora_recepcion: this.hora_recepcion,
                recibido_por: this.recepcionista,
                valor: "-",
                observaciones: this.observaciones,
                identificaciones_submuestra: this.identificacion,
                muestreado_por: this.muestreado,
                numero_cotizacion: numero_cotizacion,
                matriz: matriz,
                parametros: this.opcionesParametrosSeleccionados,
                numero_informe: this.numero_informe,

            }
            this.resumenData = data;
            this.$bvModal.show('modal-resumen-recepcion');
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        goToPreviousTab() {
            if (this.tabIndex > 0) {
                this.tabIndex--;
            }
        },
        goToNextTab() {
            if (this.tabIndex < this.totalTabs - 1) {
                this.tabIndex++;
            }
        },

        shouldShowNextButton() {
            return this.tabIndex < this.totalTabs - 1;
        },
        onModalShown() {
            this.alertaExito = false;
            this.alertaDuplicado = false;
        },

        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = 5; // Ajusta el valor según el tiempo que quieras que dure la alerta
        },
        detallesSolicitante() {
            const data = {
                rut_solicitante: this.rutSolicitante
            };
            MuestraService.obtenerDetallesSolicitante(data).then((response) => {
                
                const empresasAgrupadas = response.data.detalles_empresas.reduce((agrupadas, empresa) => {
                    // Verificar si la empresa ya existe en el array agrupadas
                    const empresaExistente = agrupadas.find(e => e.rut_empresa === empresa.rut_empresa || e.nombre_empresa === empresa.nombre_empresa);

                    if (empresaExistente) {
                        // La empresa ya existe, mezclar los datos
                        if (!empresaExistente.id_ciudad.includes(empresa.id_ciudad)) {
                            // Agregar el id_ciudad si no está presente en el array
                            empresaExistente.id_ciudad.push(empresa.id_ciudad);
                        }

                        if (!empresaExistente.nombre_ciudad.includes(empresa.nombre_ciudad)) {
                            // Agregar el nombre_ciudad si no está presente en el array
                            empresaExistente.nombre_ciudad.push(empresa.nombre_ciudad);
                        }

                        if (!empresaExistente.direccion.includes(empresa.direccion)) {
                            // Agregar la direccion si no está presente en el array
                            empresaExistente.direccion.push(empresa.direccion);
                        }
                    } else {
                        // La empresa no existe, agregarla al array agrupadas
                        empresa.id_ciudad = [empresa.id_ciudad]; // Convertir id_ciudad en un array
                        empresa.nombre_ciudad = [empresa.nombre_ciudad];
                        empresa.direccion = [empresa.direccion];
                        agrupadas.push(empresa);
                    }
                    return agrupadas;
                }, []);
                this.opcionesClientes = empresasAgrupadas;
                // Seleccionar la primera empresa de la lista por defecto
                if (this.opcionesClientes.length > 0) {
                    const primeraEmpresa = this.opcionesClientes[0];
                    this.selectedEmpresa.id_ciudad = primeraEmpresa.id_ciudad[0];
                    this.selectedEmpresa.nombre_ciudad = primeraEmpresa.nombre_ciudad[0];
                    this.selectedEmpresa.direccion = primeraEmpresa.direccion[0];
                }
            })
        },
        obtenerEmpresas() {
            MuestraService.obtenerEmpresas().then((response) => {
                const empresas = response.data;
                this.opcionesEmpresa = empresas;
                this.empresasDelSolicitante = empresas;
            })
        },
        actualizarSelectedEmpresa() {
            this.direccionDeshabilitado = false;
            const nombreEmpresa = this.opcionesEmpresa.find(objeto => objeto.rut_empresa === this.empresaSolicitante);
            let nombreEmpresaEncontrada = "";
            if (nombreEmpresa) {
                this.empresaSeleccionada = true;
                nombreEmpresaEncontrada = nombreEmpresa.nombre_empresa;
                this.nombre_empresa = nombreEmpresaEncontrada;
                MuestraService.obtenerEmpresas().then((response) => {
                const empresas = response.data;
                const empresaSelec = empresas.find(empresa => empresa.nombre_empresa === nombreEmpresaEncontrada);
                const direccion = empresaSelec.ciudades_direcciones;
                this.opcionesDireccion = direccion.map(opc => ({
                    id_ciudad: opc.id_ciudad,
                    nombre_ciudad: opc.nombre_ciudad,
                    direccion: opc.direccion,
                    direccionConCiudad: `${opc.nombre_ciudad} / ${opc.direccion}`
                }));
                });
            }
        },
        verificarValidacionTab(value) {
            console.log(value)
        },
        agregar() {
            this.alertaExito = false;
            this.alertaDuplicado = false;
            this.actualizarModal++;
            this.$bvModal.show('modal-cantidad');
        },
        detallesCotizaciones() {
            const data = {
                rut_empresa: this.solicitante
            };
            SolicitanteService.CotizacionSolicitante(data).then((response) => {
                if (response.data != null && response.status === 200) {
                    this.opcionesCotizacion = response.data.map(cotizacion => ({
                        id_cotizacion: cotizacion.id_cotizacion,
                        nombre_original_documento: cotizacion.nombre_original_documento,
                        idconNombre: `${cotizacion.id_cotizacion} - ${cotizacion.nombre_original_documento}`
                    }))
                }
            })
        },
        agregarObjetosSeleccionados() {
            if (this.parametroSeleccionado && this.metodologiaSeleccionada) {
                const ParamExistente = this.objetosSeleccionados.find(objeto => objeto.parametro === this.parametroSeleccionado);
                const MetExistente = this.objetosSeleccionados.find(objeto => objeto.metodologia === this.metodologiaSeleccionada);
                if (ParamExistente && MetExistente) {
                    this.alertaDuplicado = true;
                    this.parametroSeleccionado = '';
                    this.metodologiaSeleccionada = '';
                    this.alertaExito = false;
                } else {
                    const parametroData = this.metodologiasData.find(item => item.nombre_parametro === this.parametroSeleccionado);
                    const metodologiaCompleta = parametroData.metodologias.find(metodologia => metodologia.nombre_metodologia === this.metodologiaSeleccionada);
                    this.objetosSeleccionados.push({
                        id_parametro: parametroData.id_parametro,
                        parametro: this.parametroSeleccionado,
                        metodologia: metodologiaCompleta.nombre_metodologia,
                        id_metodologia: metodologiaCompleta.id_metodologia
                    });
                    this.parametros_agregar.push({
                        id_parametro: parametroData.id_parametro,
                        id_metodologia: metodologiaCompleta.id_metodologia,
                    });
                    if(this.opcionesParametrosSeleccionados.find(objeto => objeto.parametro === this.parametroSeleccionado)){
                        const index = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === this.parametroSeleccionado)
                        this.opcionesParametrosSeleccionados[index].metodologias.push({
                            metodologia: metodologiaCompleta.nombre_metodologia,
                            id_metodologia: metodologiaCompleta.id_metodologia,
                        })
                    }else{
                        this.opcionesParametrosSeleccionados.push({
                            id_parametro: parametroData.id_parametro,
                            parametro: this.parametroSeleccionado,
                            metodologias: [{metodologia: metodologiaCompleta.nombre_metodologia,id_metodologia: metodologiaCompleta.id_metodologia,}],
                        })
                    }
                    for (let i = 1; i <= parseInt(this.nMuestras); i++) {
                        if(this.submuestra_agregar[i-1].parametros_agregar.length > 0){
                            if(this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.parametro === this.parametroSeleccionado &&
                            this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.metodologia === this.metodologiaSeleccionada))){
                                continue;
                            }else{
                                this.submuestra_agregar[i-1].parametros_agregar.push({
                                id_parametro: parametroData.id_parametro,
                                parametro: this.parametroSeleccionado,
                                metodologia: metodologiaCompleta.nombre_metodologia,
                                id_metodologia: metodologiaCompleta.id_metodologia
                                });
                            }

                        }else{
                            this.submuestra_agregar[i-1].parametros_agregar.push({
                             id_parametro: parametroData.id_parametro,
                             parametro: this.parametroSeleccionado,
                             metodologia: metodologiaCompleta.nombre_metodologia,
                             id_metodologia: metodologiaCompleta.id_metodologia
                            });
                        }
                    }
                    this.parametroSeleccionado = '';
                    this.metodologiaSeleccionada = '';
                    this.alertaDuplicado = false;
                    this.alertaExito = true;
                }
            }
        },

        obtenerParametro() {
            ElementosService.obtenerParametros().then((response) => {
                if (response.data != null && response.status === 200) {
                    // Almacenar los datos en metodologiasData
                    this.metodologiasData = response.data.map(item => ({
                        id_parametro: item.id_parametro,
                        nombre_parametro: item.nombre_parametro,
                        metodologias: item.metodologias.map(metodologia => ({
                            id_metodologia: metodologia.id_metodologia,
                            nombre_metodologia: metodologia.nombre_metodologia
                        }))
                    }));
                    this.opcionesParametro = response.data.map(item => item.nombre_parametro);
                    this.TodasopcionesParametro = response.data.map(item => item.nombre_parametro);
                }
            });
        },
        eliminarObjetosSeleccionados(index) {
            for (let i = 1; i <= this.nMuestras; i++) {
                if(this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.parametro === this.objetosSeleccionados[index].parametro &&
                    this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.metodologia === this.objetosSeleccionados[index].metodologia))){
                        const indexParametroEliminar = this.submuestra_agregar[i-1].parametros_agregar.findIndex(objeto => objeto.metodologia === this.objetosSeleccionados[index].metodologia && objeto.parametro === this.objetosSeleccionados[index].parametro)
                        this.submuestra_agregar[i-1].parametros_agregar.splice(indexParametroEliminar,1);
                        
                    }
            }
            const indexParametro = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === this.objetosSeleccionados[index].parametro);
            const indexMetodologia = this.opcionesParametrosSeleccionados[indexParametro].metodologias.findIndex(objeto => objeto.metodologia === this.objetosSeleccionados[index].metodologia);
            this.opcionesParametrosSeleccionados[indexParametro].metodologias.splice(indexMetodologia,1);
            if(this.opcionesParametrosSeleccionados[indexParametro].metodologias.length === 0) {
                this.opcionesParametrosSeleccionados.splice(indexParametro,1);
            }
            this.objetosSeleccionados.splice(index, 1);
            this.parametros_agregar.splice(index, 1);
        },

        actualizarMetodologias() {
            const parametro = this.parametroSeleccionado;
            // Buscar el objeto correspondiente al parámetro seleccionado en metodologiasData
            const parametroData = this.metodologiasData.find(item => item.nombre_parametro === parametro);
            this.metodologias = parametroData.metodologias
            this.opcionesMetodologia = this.metodologias.map(item => item.nombre_metodologia);
        },

        capturarDatos(datos) {
            // Array para cada columna
            const identificacionArray = [];
            const ordenArray = [];
            const parametrosArray = [];
            const idparametros = [];
            const idmetodologias = [];
            const metodologiasArray = [];

            // Recorrer los datos y almacenarlos en las columnas correspondientes
            datos.forEach((objeto) => {
                identificacionArray.push(objeto.identificacion);
                ordenArray.push(objeto.orden);
                parametrosArray.push(objeto.parametros);
                idparametros.push(objeto.id_parametro);
                idmetodologias.push(objeto.id_metodologia);
                metodologiasArray.push(objeto.metodologias);
            });
            this.submuestra_agregar = [];
            datos.forEach((objeto) => {
                this.submuestra_agregar.push({
                    identificador: objeto.identificacion,
                    orden: objeto.orden,
                    parametros_agregar: objeto.parametros_agregar
                });
            });
            this.identificacion = identificacionArray;
            this.orden = ordenArray;
            this.parametros = parametrosArray;
            this.id_parametro = idparametros;
            this.id_metodologia = idmetodologias;

        },

        obtenerMatriz() {
            ElementosService.obtenerMatriz().then((response) => {
                if (response.data != null && response.status === 200) {
                    // Mapear los datos de las matrices a las opciones del select
                    this.opcionesMatriz = response.data.map(matriz => ({
                        id_matriz: matriz.id_matriz,
                        nombre_matriz: matriz.nombre_matriz
                    }));
                }
            });
        },

        obtenerNormas() {
            ElementosService.obtenerNormas().then((response) => {
                if (response.data != null && response.status === 200) {
                    const normas = response.data.map(norma => ({
                        id: norma.id_norma,
                        nombre: norma.nombre_norma
                    }));
                    this.opcionesNorma = normas;
                }
            });
        },

        obtenerTablasNormas() {
            const idNormaSeleccionada = this.norma;
            ElementosService.obtenerTablasNorma(idNormaSeleccionada).then((response) => {
                if (response.data != null && response.status === 200) {
                    // Almacena las tablas agrupadas por nombre
                    const tablasAgrupadas = {};
                    // agrupar las tablas por nombre
                    response.data.forEach((tabla) => {
                        const nombreTabla = tabla.nombre_tabla;
                        const nombreParametro = tabla.nombre_parametro;
                        const idParametro = tabla.id_parametro;
                        const idMetodologia = tabla.id_metodologia;
                        const nombreMetodologia = tabla.nombre_metodologia;
                        // Verificar si la tabla ya está en el objeto tablasAgrupadas
                        if (!tablasAgrupadas[nombreTabla]) {
                            // Si la tabla no existe, crear un nuevo objeto con el nombre de la tabla
                            tablasAgrupadas[nombreTabla] = {
                                nombre_tabla: nombreTabla,
                                id_tablas: [],
                                parametros: [],
                                id_parametro: [],
                                id_metodologia: [],
                                metodologias: []
                            };
                        }
                        // Agregar el id_tabla y el nombre_parametro a la tabla correspondiente en tablasAgrupadas
                        tablasAgrupadas[nombreTabla].id_tablas.push(tabla.id_tabla);
                        tablasAgrupadas[nombreTabla].parametros.push(nombreParametro);
                        tablasAgrupadas[nombreTabla].id_parametro.push(idParametro);
                        tablasAgrupadas[nombreTabla].id_metodologia.push(idMetodologia);
                        tablasAgrupadas[nombreTabla].metodologias.push(nombreMetodologia)
                    });
                    // convertir  en un array
                    const tablasProcesadas = Object.values(tablasAgrupadas);
                    // Asignar las tablas procesadas a opcionesTabla
                    this.opcionesTabla = tablasProcesadas.map((tabla) => tabla.nombre_tabla);
                    // Asignar tablasProcesadas a this.tablasProcesadas
                    this.tablasProcesadas = tablasProcesadas;
                }
            });
        },

        PushParametrosMetodologias() { 
            function esDuplicado(parametrosYMetodologias, parametro, metodologia) {
                return parametrosYMetodologias.some((item) => item.parametro === parametro && item.metodologia === metodologia);
            }
            let parametrosYMetodologias = [];
            // Recorrer cada tabla procesada
            for (const tabla of this.tablasProcesadas) {
                // Recuperar los arrays de id_parametro y metodologias de la tabla actual
                const idParametro = tabla.id_parametro;
                const parametros = tabla.parametros;
                const metodologias = tabla.metodologias;
                const idMetodologias = tabla.id_metodologia;
                // Agregar los parámetros y metodologías de la tabla actual a la variable principal
                for (let i = 0; i < parametros.length; i++) {
                    const parametro = parametros[i];
                    const metodologia = metodologias[i];
                    const id_parametro = idParametro[i];
                    const id_metodologia = idMetodologias[i];
                    // Verificar si el parámetro y la metodología ya existen en la variable "parametrosYMetodologias"
                    if (!esDuplicado(parametrosYMetodologias, parametro, metodologia)) {
                        parametrosYMetodologias.push({
                            id_parametro: id_parametro,
                            parametro: parametro,
                            metodologia: metodologia,
                            id_metodologia: id_metodologia
                        });
                        if(this.opcionesParametrosSeleccionados.find(objeto => objeto.parametro === parametro)){
                            const index = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === parametro);
                            if(!this.opcionesParametrosSeleccionados[index].metodologias.find(objeto => objeto.metodologia === metodologia)){
                                this.opcionesParametrosSeleccionados[index].metodologias.push({
                                metodologia: metodologia,
                                id_metodologia: id_metodologia,
                                })
                            }
                        }else{
                            this.opcionesParametrosSeleccionados.push({
                                id_parametro: id_parametro,
                                parametro: parametro,
                                metodologias: [{metodologia: metodologia,id_metodologia: id_metodologia}],
                            })
                        }
                        for (let i = 1; i <= parseInt(this.nMuestras); i++) {
                            if(this.submuestra_agregar[i-1].parametros_agregar.length > 0){
                                if(this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.parametro === parametro &&
                                this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.metodologia === metodologia))){
                                    continue;
                                }else{
                                    this.submuestra_agregar[i-1].parametros_agregar.push({
                                    id_parametro: id_parametro,
                                    parametro: parametro,
                                    metodologia: metodologia,
                                    id_metodologia: id_metodologia
                                    });
                                }

                            }else{
                                this.submuestra_agregar[i-1].parametros_agregar.push({
                                id_parametro: id_parametro,
                                parametro: parametro,
                                metodologia: metodologia,
                                id_metodologia: id_metodologia
                                });  
                            }   
                        }
                    }
                }
            }
            // Asegurarnos de no agregar duplicados a "this.objetosSeleccionados"
            const objetosNoDuplicados = parametrosYMetodologias.filter((item) => !esDuplicado(this.objetosSeleccionados, item.parametro, item.metodologia));
            this.objetosSeleccionados.push(...objetosNoDuplicados);
            const IDNoDuplicados = parametrosYMetodologias.filter((item) => !esDuplicado(this.objetosSeleccionados, item.id_parametro, item.id_metodologia));
            const parametros_agregar_solo_id = IDNoDuplicados.map(obj => {
                    const nuevoObjeto = { ...obj };
                    delete nuevoObjeto.parametro;
                    delete nuevoObjeto.metodologia;
                    return nuevoObjeto;
            });
            this.parametros_agregar.push(...parametros_agregar_solo_id);
        },


        //PushParametrosMetodologias de la tabla seleccionada para agregar objetosSeleccionados
        PushParametrosMetodologiasTabla() {
            function esDuplicado(parametrosYMetodologias, parametro, metodologia) {
                return parametrosYMetodologias.some((item) => item.parametro === parametro && item.metodologia === metodologia);
            }   

            let parametrosYMetodologias = [];

            // Recuperar la tabla seleccionada
            const tablaSeleccionada = this.tablasProcesadas.find(tabla => tabla.nombre_tabla === this.tabla);
            if (tablaSeleccionada) {
                // Recuperar los arrays de id_parametro y metodologias de la tabla actual
                const idParametro = tablaSeleccionada.id_parametro;
                const parametros = tablaSeleccionada.parametros;
                const metodologias = tablaSeleccionada.metodologias;
                const idMetodologias = tablaSeleccionada.id_metodologia;

                // Agregar los parámetros y metodologías de la tabla actual a la variable principal
                for (let i = 0; i < parametros.length; i++) {
                    const parametro = parametros[i];
                    const metodologia = metodologias[i];
                    const id_parametro = idParametro[i];
                    const id_metodologia = idMetodologias[i];

                        // Verificar si el parámetro y la metodología ya existen en la variable "parametrosYMetodologias"
                        if (!esDuplicado(parametrosYMetodologias, parametro, metodologia)) {
                            parametrosYMetodologias.push({
                                id_parametro: id_parametro,
                                parametro: parametro,
                                metodologia: metodologia,
                                id_metodologia: id_metodologia
                            });
                            if(this.opcionesParametrosSeleccionados.find(objeto => objeto.parametro === parametro)){
                                const index = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === parametro);
                                if(!this.opcionesParametrosSeleccionados[index].metodologias.find(objeto => objeto.metodologia === metodologia)){
                                    this.opcionesParametrosSeleccionados[index].metodologias.push({
                                    metodologia: metodologia,
                                    id_metodologia: id_metodologia,
                                    })
                                }
                            }else{
                                this.opcionesParametrosSeleccionados.push({
                                    id_parametro: id_parametro,
                                    parametro: parametro,
                                    metodologias: [{metodologia: metodologia,id_metodologia: id_metodologia}],
                                })
                            }
                            for (let i = 1; i <= parseInt(this.nMuestras); i++) {
                            if(this.submuestra_agregar[i-1].parametros_agregar.length > 0){
                                if(this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.parametro === parametro &&
                                this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.metodologia === metodologia))){
                                    continue;
                                }else{
                                    this.submuestra_agregar[i-1].parametros_agregar.push({
                                    id_parametro: id_parametro,
                                    parametro: parametro,
                                    metodologia: metodologia,
                                    id_metodologia: id_metodologia
                                    });
                                }

                            }else{
                                this.submuestra_agregar[i-1].parametros_agregar.push({
                                id_parametro: id_parametro,
                                parametro: parametro,
                                metodologia: metodologia,
                                id_metodologia: id_metodologia
                                });
                            }
                        }
                    }
                }
                // Asegurarnos de no agregar duplicados a "this.objetosSeleccionados"
                const objetosNoDuplicados = parametrosYMetodologias.filter((item) => !esDuplicado(this.objetosSeleccionados, item.parametro, item.metodologia));
                this.objetosSeleccionados.push(...objetosNoDuplicados);
                const IDNoDuplicados = parametrosYMetodologias.filter((item) => !esDuplicado(this.objetosSeleccionados, item.id_parametro, item.id_metodologia));
                const parametros_agregar_solo_id = IDNoDuplicados.map(obj => {
                    const nuevoObjeto = { ...obj };
                    delete nuevoObjeto.parametro;
                    delete nuevoObjeto.metodologia;
                    return nuevoObjeto;
                });
                this.parametros_agregar.push(...parametros_agregar_solo_id);
            }
        },

        resetOpcionesParametrosInciales() {
            this.obtenerParametro();
            this.opcionesParametro = this.TodasopcionesParametro;
        },
        verificarParametros() {
            for (let objetoSeleccionado of this.objetosSeleccionados) {
                let idMetodologia = objetoSeleccionado.id_metodologia;
                let encontrado = false;

                // Recorremos cada submuestra
                for (let submuestra of this.submuestra_agregar) {
                let parametrosAgregar = submuestra.parametros_agregar;

                // Verificamos si el id_metodologia está en los parámetros de la submuestra
                if (parametrosAgregar.some(parametro => parametro.id_metodologia === idMetodologia)) {
                    encontrado = true;
                    break; // Si encontramos el id_metodologia, no necesitamos seguir buscando en otras submuestras
                }
                }

                // Si no encontramos el id_metodologia en ninguna submuestra, retornamos false
                if (!encontrado) {
                return false;
                }
            }
            // Si todos los id_metodologia están presentes en al menos una submuestra, retornamos true ,grandechatgpt
            return true;
        },
        actualizarParametrosTabla() {
            const tablaSeleccionada = this.tabla;
            // Buscar la tabla seleccionada en tablasProcesadas
            const tablaProcesada = this.tablasProcesadas.find(tabla => tabla.nombre_tabla === tablaSeleccionada);
            if (tablaProcesada) {
                const parametrosUnicos = Array.from(new Set(tablaProcesada.parametros)); // Eliminar duplicados
                this.parametrosTablaSeleccionada = parametrosUnicos.map(parametro => ({
                    nombre_parametro: parametro
                }));
                this.opcionesParametro = parametrosUnicos;
            } else {
                // Si la tabla seleccionada no se encuentra en tablasProcesadas, limpiar los parámetros
                this.parametrosTablaSeleccionada = [];
                this.opcionesParametro = [];
            }
        },

        async validarFormularios() {
            const recepValido = await this.$refs.valrecepcionista.validate();
            const muestValido = await this.$refs.valmuestra.validate()
            const transValido = await this.$refs.valtransportista.validate();
            const paramValido = await this.$refs.valparametros.validate();
            if (recepValido && muestValido && transValido && paramValido) {
                this.recepcionista_incompleto = false;
                this.muestra_incompleto = false;
                this.transportista_incompleto = false;
                this.parametros_incompleto = false;
                this.revisado = true;
                return true;
            } else {

                this.recepcionista_incompleto = !recepValido;
                this.muestra_incompleto = !muestValido;
                this.transportista_incompleto = !transValido;
                this.parametros_incompleto = !paramValido;
                this.revisado = true;
                return false;
            }
        },

        async enviarFormulario() {
            const datosValidos = await this.validarFormularios();
            if (!datosValidos) {
                return;
            } 
            else if (this.submuestra_agregar.some(submuestra => submuestra.identificador.trim() === '')) {
                this.$bvToast.toast("La muestra contiene identificadores en blanco. Por favor, complete todos los identificadores.", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (this.submuestra_agregar.some(submuestra => submuestra.parametros_agregar.length === 0)) {
                this.$bvToast.toast("Alguna submuestra no posee parametros, porfavor ingrese parámetros para dicha muestra.", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (!this.verificarParametros()) {
                this.$bvToast.toast("Algun parámetro no ha sido asignado a ninguna submuestra, intentelo denuevo", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else {
                if(this.empresaSolicitante =='Particular'){
                    this.empresaSolicitante = null;
                }
                var data = {
                    observacion_cliente : this.observacionCliente,
                    rut_empresa: this.empresaSolicitante,
                    rut_empleado: this.recepcionistaRUT,
                    nombre_empresa: this.nombre_empresa,
                    id_ciudad: this.direccion,
                    id_cotizacion: this.cotizacion,
                    direccion_empresa: this.direccion_empresa,
                    rut_solicitante: this.rutSolicitante,
                    muestreado_por: this.muestreado,
                    cantidad_muestras: this.nMuestras,
                    prioridad: this.prioridad,
                    temperatura_transporte: this.Temperatura,
                    fecha_ingreso: this.fecha_recepcion,
                    hora_ingreso: this.hora_recepcion,
                    fecha_entrega: this.fechaEntrega,
                    fecha_muestreo: this.fecha,
                    hora_muestreo: this.hora,
                    rut_transportista: this.transportistaRut,
                    nombre_transportista: this.transportista,
                    patente_vehiculo: this.patente,
                    telefonos_agregar: {telefono_transportista: this.telefono_transportista},
                    estado: 'Recepcionado',
                    observaciones: this.observaciones,
                    parametros_agregar: this.parametros_agregar,
                    id_matriz: this.TipoMatriz,
                    id_norma: this.norma,
                    id_tabla: this.id_tabla,
                    submuestras_agregar: this.submuestra_agregar,
                    
                    nombre_solicitante: this.nombreSolicitante,
                }
                MuestraService.ingresarMuestra(data).then((response) => {
                    if (response != null) {
                        if (response.status == 200) {
                            this.$bvToast.toast(`Recepción de la muestra exitosa.`, {
                                title: 'Éxito',
                                toaster: 'b-toaster-top-center',
                                solid: true,
                                variant: "success",
                                appendToast: true,
                            })
                            this.numero_informe = response.data[0].numero_informe;
                            this.abrirResumenMuestra();
                            this.$refs.modal_resumenMuestra.generatePDF();
                            setTimeout(() => {
                                if(this.userData.role == 2|| this.userData.role == 6){ // jefe de laboratorio y supervisor
                                this.$router.push({name: "AdminMuestraSupervisor"});
                                }
                                if(this.userData.role == 3 || this.userData.role == 4){ // quimico y analista quimico
                                    this.$router.push({name: "AdminMuestraLab"});
                                }
                                
                            }, 1000);
                           
                            return;
                        }
                        this.observacionCliente = '';
                        this.recepcionista = '';
                        this.recepcionistaRUT = '';
                        this.empresaSolicitante = '';
                        this.rut = '';
                        this.direccion = '';
                        this.muestreado = '';
                        this.prioridad = null;
                        this.TipoMatriz = null;
                        this.nMuestras = null;
                        this.Temperatura = '';
                        this.fechaEntrega = '';
                        this.transportista = '';
                        this.patente = '';
                        this.transportistaRut = '';
                        this.fono = '';
                        this.observaciones = '';
                        this.fecha = '';
                        this.hora = '';
                        this.rutSolicitante = '';
                        this.nombreSolicitante = '';

                        

                        

                    } else {
                        this.$bvToast.toast(`Error al recepcionar muestra.`, {
                            title: 'Error',
                            toaster: 'b-toaster-top-center',
                            solid: true,
                            variant: "warning",
                            appendToast: true
                        })
                    }
                })
            }
        },
    }
}
</script>