<template>
    <div>  
        <!-- <validation-observer ref="form"> -->
         
            <modal_agregarEmpresa @refrescar="obtenerEmpresas" />
            <modal_agregarSolicitante />
        <div> 
            <b-card no-body > 
                <div style="height:20px"></div>

                <div class="rum-container">
                    <strong class="rum-label">RUM: </strong>
                    <span class="rum-number">{{ RUM }}</span>
                </div>
                <b-row class="d-flex justify-content-center" style='width:1900px'>
                    <b-col class="col-8" >
                        <b-tabs active-nav-item-class="lsa-orange" no-fade v-model="tabIndex" pills card vertical
                            style="height:65vh">
                            <b-row class="d-flex justify-content-center">
                                <b-col class="col-12">
  
                                    <b-tab title="Recepcionista">
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon v-if="!recepcionista_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Recepcionista</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valrecepcionista">

                                        <strong> Datos de la recepcion </strong>
                                        <b-card>
                                            <b-row class="pb-2">
                                                <b-col class="col-6">
                                                    <ValidationProvider name="Rut Recepcionista" rules="required|rut"
                                                        v-slot="validationContext">
                                                        <label for="RutRecepcionista-input">RUT del recepcionista:</label>
                                                        <b-form-input id="Rut Recepcionista-input" readonly
                                                            v-model="recepcionistaRUT"
                                                            :state="getValidationState(validationContext)"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                        }}</b-form-invalid-feedback>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col class="col-6">
                                                    <ValidationProvider name="NombreRecepcionista" rules="required"
                                                        v-slot="validationContext">
                                                        <label for="input-live">Nombre del recepcionista:</label>
                                                        <b-form-input v-model="recepcionista" readonly
                                                            :state="getValidationState(validationContext)"
                                                            placeholder="Seleccione un recepcionista"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                        }}</b-form-invalid-feedback>
                                                    </ValidationProvider>
                                                </b-col>

                                            </b-row>                                            
                                        </b-card>
                                        </validation-observer>
                                        <strong> Identificacion del cliente </strong>
                                        <b-card>
                                            <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        
                                                            <label for="input-live">RUT Solicitante:</label>
                                                            <div class="d-flex align-items-center ">
                                                                <b-input-group>
                                                                <!-- Input como filtro de opciones -->
                                                                <b-form-input
                                                                    v-model="rutFilter"
                                                                    placeholder="Buscar solicitante..."
                                                                    :disabled="solicitanteIngresado"
                                                                ></b-form-input>
                                                                <b-button class=" lsa-orange reactive-button"
                                                                    @click="obtenerDatosSolicitante()">
                                                                    <b-icon icon="search"></b-icon>
                                                                </b-button>
                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        v-b-modal.modal-solicitante>
                                                                        <b-icon style="color:white" icon="person-plus">
                                                                        </b-icon>
                                                                    </b-button>
                                                                    <b-button class=" lsa-orange reactive-button"
                                                                    @click="limpiarDatosIdentificacion()">
                                                                    <b-icon icon="arrow-clockwise"></b-icon>
                                                                    </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            </div>                                                        
                                                        <b-alert :show="dismissCountDown" dismissible fade variant="danger"
                                                            @dismiss-count-down="countDownChanged">
                                                            El RUT del solicitante no está registrado en la base de datos.
                                                        </b-alert>

                                                        <label for="NombreSolicitante-input">Nombre solicitante:</label>
                                                        <b-form-input id="Nombre Solicitante-input" readonly v-model="nombreSolicitante" ></b-form-input>
                                                        
                                                        <label for="ObservacionCliente-input">Observacion Cliente:</label>
                                                        <b-form-input id="Observacion Cliente-input" v-model="observacionCliente" ></b-form-input>

                                                        
                                                    </b-col>
                                                    <b-col class="col-6">
                                        
                                                        <label for="input-live">Nombre de la empresa:</label>
                                                        <div class="d-flex align-items-center ">
                                                            <b-input-group>
                                                                <b-form-select id="input-live" v-model="empresaSolicitante"
                                                                :options="empresasDelSolicitante"
                                                                
                                                                :disabled="empresaSolicitante === 'Particular'" 
                                                                text-field="nombre_empresa"
                                                                value-field="rut_empresa"
                                                                @input="actualizarSelectedEmpresa" filter>
                                                                <template #first>
                                                                    <b-form-select-option v-if="tieneEmpresa" disabled :value="null">Selecciona una empresa</b-form-select-option>
                                                                    <b-form-select-option v-if="!tieneEmpresa" disabled :value="null">Solicitante sin empresas asociadas</b-form-select-option>
                                                                </template>   
                                                            </b-form-select>
                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        v-b-modal.modal-empresa>
                                                                        <b-icon style="color:white" icon="person-plus">
                                                                        </b-icon>
                                                                    </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>


                                                        <label for="input-live">Dirección de la empresa:</label>
                                                        <b-form-select id="input-live" v-model="direccion"
                                                            :options="opcionesDireccion"
                                                            :disabled="empresaSolicitante === '' ||empresaSolicitante === 'Particular'||!empresaSeleccionada || empresaSolicitante=== null"
                                                            text-field="direccionConCiudad" value-field="id_ciudad">
                                                        </b-form-select>

                                                        <label class="mt-1" for="input-live">Cotización:</label>
                                                        <b-form-select id="input-live" v-model="cotizacion"  :options="opcionesCotizacion" text-field="idconNombre" value-field="id_cotizacion" aria-describedby="input-live-help Cotizacion-live-feedback">
                                                            <template #first>
                                                                <b-form-select-option v-if="solicitanteIngresado && tieneCotizaciones" disabled :value="null">Seleccione una cotización</b-form-select-option>
                                                                <b-form-select-option v-if="!solicitanteIngresado" disabled :value="null">Ingrese solicitante para visualizar sus cotizaciones</b-form-select-option>
                                                                <b-form-select-option v-if="solicitanteIngresado && !tieneCotizaciones" disabled :value="null">Solicitante sin cotizaciones asociadas</b-form-select-option>
                                                            </template>
                                                        </b-form-select>

                                                    </b-col>
                                            </b-row>      
                                        </b-card>
                                    </b-tab>
                                    <b-tab title="Pago">
                                        <strong>Datos del Pago</strong>
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Pago</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valpago">
                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                            <label for="input-pago">Forma de pago:</label>
                                                            <b-form-select id="input-pago" v-model="tipo_pago"
                                                                :options="opcionesPago">
                                                            </b-form-select>    
                                                    </b-col>
                                                    <b-col class="col-6">
                                                            <label for="input-valor">Valor neto del pago:</label>
                                                            <b-input-group size="sm">
                                                                <b-form-input id="input-valor" v-model="valor_neto">
                                                                </b-form-input>
                                                            </b-input-group>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </validation-observer>
                                    </b-tab>
                                    
                                    <b-tab title="Transportista">
                                        <strong>Datos del transportista</strong>
  
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon v-if="!transportista_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Datos del transportista</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valtransportista">
  
                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        <ValidationProvider name="Rut Transportista" rules="rut|rutSinPuntoGuion|max:9"
                                                            v-slot="validationContext">
                                                        <label for="input-live">RUT:</label>
                                                        <b-form-input id="transportistaRut-input" class="mb-1"
                                                            v-model="transportistaRut"
                                                            aria-describedby="transportistaRut-live-feedback"
                                                            :state="getValidationState(validationContext)"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                            }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
                                                                                             
                                                        <label for="input-live">Teléfono móvil:</label>
                                                       
                                                       <ValidationProvider name="número móvil" rules="numeric|min:8|max:15"
                                                           v-slot="validationContext">
                                                           <b-input-group size="sm" class="mb-1">

                                                               <b-input-group-prepend is-text>
                                                                   +56 9
                                                               </b-input-group-prepend>
                                                               <b-form-input id="input-live" v-model="telefono_transportista"
                                                                   aria-describedby="input-live-help movil-live-feedback"
                                                                   :state="getValidationState(validationContext)" placeholder=""></b-form-input>
                                                               <b-form-invalid-feedback id="movil-live-feedback">{{
                                                                   validationContext.errors[0] }}
                                                               </b-form-invalid-feedback>
                                                           </b-input-group>

                                                       </ValidationProvider>
  
                                                    </b-col>
  
                                                    <b-col class="col-6">
                                                        <label for="input-live">Nombre y apellido:</label>
                                                        <b-form-input class="mb-1" id="input-live" v-model="transportista"
                                                            aria-describedby="input-live-help transportista-live-feedback"
                                                            placeholder="" trim></b-form-input>
  
                                                        <label for="input-live">Patente:</label>
                                                        <b-form-input class="mb-1" id="input-live" v-model="patente"
                                                            aria-describedby="input-live-help patente-live-feedback"
                                                            placeholder="" trim></b-form-input>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </validation-observer>
                                    </b-tab>
  
  
                                </b-col>
                                <div style="position:absolute; right:20px; bottom:15px; width:45%">
                                    <b-row class="d-flex justify-content-between">
                                      <b-col class="col-6">
                                        <b-button v-if="tabIndex > 0" block class="lsa-blue reactive-button" pill @click="goToPreviousTab">Atrás</b-button>
                                      </b-col>
                                      <b-col class="col-6">
                                        <b-button v-if="shouldShowNextButton()" block class="lsa-blue reactive-button" pill @click="goToNextTab">Siguiente</b-button>
                                      </b-col>
                                    </b-row>
  
                                    <b-button @click="enviarFormulario()" variant="primary" size="xl"
                                        class="reactive-button lsa-light-blue"
                                        style="font-weight:bold; margin-top:30px; position:absolute; width:100%; right:0px">
                                        Editar datos
                                    </b-button>
                                </div>
                            </b-row>
  
                        </b-tabs>
                    </b-col>
  
                </b-row>
  
            </b-card>
           
        </div>
    </div>
  </template>

<style>
.left-aligned-list {
  text-align: left;
}
.left-aligned-list li {
  margin-bottom: 10px; /* Ajusta el margen inferior */
}
</style>

<script>
import MuestraService from '@/helpers/api-services/Muestra.Service';

import solicitanteService from "@/helpers/api-services/Solicitante.service";
import PersonalService from '@/helpers/api-services/Personal.service';

import modal_agregarSolicitante from '@/components/admSolicitante/modal_agregarSolicitante.vue';
import modal_agregarEmpresa from '@/components/admEmpresa/modal_agregarEmpresa.vue';



import {
    getUserInfo
} from "@/helpers/api-services/Auth.service";
import {
    isLoggedIn
} from "@/helpers/api-services/Auth.service";

export default {

    components: {
       
        modal_agregarSolicitante,
        modal_agregarEmpresa,
    },

    data() {
        return {
            observacionCliente: '',
            nombreSolicitante: '',
            rutSolicitante: '',
            rutFilter:'',
            empresasDelSolicitante: [],
            RUM : '',
            direccionOG : "",       
            rutOG : "",
            recepcionistaOG : "",
            nMuestrasOG : "",
            fechaOG : "",
            horaOG : "",
            observacionesOG : "",
            transportistaRutOG : "",
            transportistaOG : "",                      
            TemperaturaOG : "",
            patenteOG : "",
            telefonos_agregarOG : "",
            recepcionista: '',
            recepcionistaRUT: '',
            nombre_empresa: '',
            empresaSolicitante: '',
            rut: '',
            tipo_pago: '',
            valor_neto: '',
            rut_empresa: '',
            direccion: '',
            direccion_empresa: '',
            cotizacion: '',            
            opcionesCotizacion: [],
            opcionesDireccion: [],
            analista: null,
            prioridad: null,
            opcionesPago: [{
                    value: 'Efectivo',
                    text: 'Efectivo'
                },
                {
                    value: 'Tarjeta de crédito',
                    text: 'Tarjeta de crédito'
                },
                {
                    value: 'Transferencia bancaria',
                    text: 'Transferencia bancaria'
                },
                {
                    value: 'Cheque',
                    text: 'Cheque'
                },
                {
                    value: 'Otro',
                    text: 'Otro'
                }
            ],
            opcionesRecepcionistas: [],
            opcionesClientes: [],
            opcionesEmpresa: [],

            transportista: '',
            transportistaRut: '',
            fono: '',
            fechaEntrega: '',
            observaciones: '',
            nMuestras: null,
            fecha: "",
            hora: "",
            patente: "",
            estado: null,
            tabIndex: 0,
            identificacion: [],
            telefonos_agregar: [],
            userData: '',
            id_ciudad: '',
            dismissSecs: 2,
            dismissCountDown: 0,
            selectedEmpresa: {
                id_ciudad: '',
                nombre_ciudad: '',
                direccion: '',
            },
            telefonos_eliminar: [],
            FechaActual: new Date().toISOString().split('T')[0],

            filaSeleccionada: null,
            nuevaobs: [],
            empresasDirecciones: [],
            datosCargados: false,
            recepcionista_incompleto: true,
            muestra_incompleto: true,
            transportista_incompleto: true,
            revisado: false,
            totalTabs: 3,

            opcionesIniciales: [ /* Aquí guardaremos las opciones iniciales */ ],
            solicitanteIngresado: false,
            tieneEmpresa: true,
            tieneCotizaciones: false,
            empresaSeleccionada: null,
            telefono_transportista: '',


        };
    },

    async created() {
        if (isLoggedIn()) {
            console.log('checkenado user info');
            this.userData = getUserInfo();
            console.log('data user', this.userData);
        }

        
        this.RUM = this.$route.query.RUM;
        await MuestraService.obtenerDatosMuestra(this.RUM).then((response)=>{
            console.log("Obteniedo detalles",response.data)
            this.datos = response.data;
            this.RellenarForm(response.data);        

           

        }).catch(error => {
            console.error(error);
        });
        await this.obtenerEmpresas()        
        await PersonalService.obtenerTodosPersonal().then((response) => {
            console.log(response.data);
            if (response.data != null) {
                this.analista = response.data;
                this.recepcionista = this.analista.find((analista) => analista.rut_empleado === this.recepcionistaRUT);
                this.recepcionista = `${this.recepcionista.nombre} ${this.recepcionista.apellido}`;
                        
            }
        })

    },
    


    watch: {


        direccion(newValue) {
            const direccionSeleccionada = this.opcionesDireccion.find(opcion => opcion.id_ciudad === newValue);
            if (direccionSeleccionada) {
                const direccion = direccionSeleccionada.direccion;
                console.log("Dirección seleccionada:", direccion);
                // Realiza cualquier otra acción necesaria con la dirección seleccionada
                this.direccion_empresa = direccion;
            } else {
                console.log("No se encontró la dirección seleccionada.");
            }
        },
        
    },

    methods: {
        //crea una funcion que cuando se el input de rut solicitante cambie, se actualice se llame a la funcion ontenerCotizaciones

        obtenerArchivos(){
            //verifica si el input rut solicitante
        solicitanteService.obtenerDetallesCompletosSolicitante(this.userData.rut_solicitante).then((response)=>{
            console.log(response)
            if(response.request.status == 200){
                console.log("detalles de solicitante",response.data)
                this.Cotizaciones = response.data.cotizaciones;

            }
        })},
       
        limpiarDatosIdentificacion() {
            this.nombreSolicitante = '';
            this.rutSolicitante = '';
            this.direccion = '';
            this.empresaSolicitante = '';
            this.opcionesDireccion  = [];
            this.empresasDelSolicitante = this.opcionesEmpresa;
            this.nombre_empresa = '';
            this.tieneEmpresa = true;
            this.solicitanteIngresado = false;
            this.rutFilter = '';
            this.opcionesCotizacion = [];
            this.cotizacion = '';
            this.tieneCotizaciones = false;
            this.empresaSeleccionada = false;

        },
       
        obtenerDatosSolicitante() {
            

            solicitanteService.obtenerDetallesSolicitante(this.rutFilter).then((response) => {
                
                console.log(response)
                try{
                    if (response.request.status == 200 && this.rutFilter !== "") {
                    console.log("detalles de solicitante", response.data)
                    this.solicitanteIngresado = true;
                    this.empresaSeleccionada = false;
                    this.opcionesDireccion  = [];
                    this.direccion = '';
                    this.empresaSolicitante = '';
                    this.nombre_empresa = '';
                    //obten nombre y apellido
                    this.nombreSolicitante = `${response.data.nombre} ${response.data.primer_apellido} ${response.data.segundo_apellido}`;
                    this.rutSolicitante = response.data.rut_solicitante; 
                    this.telefono_solicitante = response.data.telefono;
                    this.correo_solicitante = response.data.correo;

                    if(response.data.detalles_empresas.length > 0){
                        this.tieneEmpresa = true;
                    }else{

                        this.tieneEmpresa = false;
                        ///
                    }
                    const empresas = response.data.detalles_empresas;
                    this.empresasDelSolicitante = empresas;

                    
                    if(response.data.tipo_cliente === 'Particular'){
                        this.empresaSolicitante = 'Particular';
                        this.nombre_empresa = 'Particular';
                        this.rut_empresa =null;
                        this.empresasDelSolicitante = [{nombre_empresa: 'Particular',rut_empresa:'Particular'}];

                    }
                    const data = { rut_solicitante: this.rutSolicitante };
                    solicitanteService.CotizacionSolicitante(data).then((response) => {
                        if (response.data != null && response.status === 200) {
                            console.log("cotizaciones: ", response.data)
                            this.opcionesCotizacion = response.data.map(cotizacion => ({
                                id_cotizacion: cotizacion.id_cotizacion,
                                nombre_original_documento: cotizacion.nombre_original_documento,
                                idconNombre: `${cotizacion.id_cotizacion} - ${cotizacion.nombre_original_documento}`
                            }))
                            if(this.opcionesCotizacion.length>0){
                                this.tieneCotizaciones = true;
                            }else{
                                this.tieneCotizaciones = false;
                            }
                            console.log("Opc. cotizaciones: ", this.opcionesCotizacion)
                        }
                    })

                }

                }catch{
                    this.showAlert();
                }
                
                
            
            })
        },
        
        showAlert() {
            this.dismissCountDown = 5; // Ajusta el valor según el tiempo que quieras que dure la alerta
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        removeInput(index) {
            const telefonoEliminado = this.telefonos_agregar[index].telefono_transportista;
            if (telefonoEliminado !== "") {
              this.telefonos_eliminar.push({ telefono_transportista: telefonoEliminado });
            }                   
            this.telefonos_agregar.splice(index, 1);
        },

        
        
        obtenerEmpresas () {
            MuestraService.obtenerEmpresas().then((response) => {
                console.log("obteniendo empresas",response.data)
                const empresas = response.data;
                this.opcionesEmpresa = empresas;
            })
        },

        goToPreviousTab() {
            if (this.tabIndex > 0) {
                this.tabIndex--;
            }
        },

        goToNextTab() {
            if (this.tabIndex < this.totalTabs - 1) {
                this.tabIndex++;
            }
        },

        shouldShowNextButton() {
            return this.tabIndex < this.totalTabs - 1;
        },

        onModalShown() {
            this.alertaExito = false;
            this.alertaDuplicado = false;
        },

       

        // Función para verificar si dos objetos son iguales
        sonObjetosIguales(objeto1, objeto2) {
            const keys1 = Object.keys(objeto1);
            const keys2 = Object.keys(objeto2);
            
            if (keys1.length !== keys2.length) {
              return false;
            }
          
            for (const key of keys1) {
              if (objeto1[key] !== objeto2[key]) {
                return false;
              }
            }
          
            return true;
        },
        

        mapeotelefono() {
            this.telefonos_agregarOG = this.telefonos_agregarOG.map(fono => ({
                telefono_transportista: fono.telefono_transportista
            }));
            console.log("telefono_transportista nuevo:", this.telefonos_agregarOG);
        },

        obs(){
            console.log(this.observacionesOG);
            if (this.observacionesOG.length === 0) {
                this.nuevaobs = [{
                  fecha_observacion: '',
                  hora_observacion: '',
                  observaciones: this.observaciones,
                }];                
                console.log("primero: ",this.nuevaobs)              
            }   
            else{
                const fechaObs = this.observacionesOG[0].fecha_observacion;
                console.log("fecha observacion: ", fechaObs)  
                
                const partesFecha = fechaObs.split("/");
                const dia = partesFecha[0];
                const mes = partesFecha[1];
                const anio = partesFecha[2];

                // Formatear la fecha en "yyyy-mm-dd"
                const fechaFormateada = `${anio}/${mes}/${dia}`;
                console.log(fechaFormateada);

                const horaObs = this.observacionesOG[0].hora_observacion;
                console.log("fecha observacion: ", horaObs)

                const observaciones = [{
                    fecha_observacion: fechaObs,
                    hora_observacion: horaObs,  
                    observaciones: this.observaciones,
                }]

                if (observaciones === this.observacionesOG){
                    this.nuevaobs = []
                }else{
                    this.nuevaobs = observaciones;
                    console.log("obs nueva: ",observaciones)
                }              
            }
        },            


        actualizarSelectedEmpresa() {

            if(this.empresaSolicitante !== '' && this.empresaSolicitante !== undefined && this.empresaSolicitante !== 'Particular'){
                this.empresaSeleccionada = true;
                console.log("empresa:", this.empresaSolicitante)
                const nombreEmpresa = this.opcionesEmpresa.find(objeto => objeto.rut_empresa === this.empresaSolicitante);
                console.log("empresa:", nombreEmpresa)
                let nombreEmpresaEncontrada = "";
                if (nombreEmpresa) {
                nombreEmpresaEncontrada = nombreEmpresa.nombre_empresa;
                this.nombre_empresa = nombreEmpresaEncontrada;
                }

                console.log("Nombre de la empresa encontrada:", nombreEmpresaEncontrada);

                MuestraService.obtenerEmpresas().then((response) => {
                    console.log("obteniendo empresas",response.data)
                    const empresas = response.data;
                    const empresaSelec = empresas.find(empresa => empresa.nombre_empresa === this.nombre_empresa);
                    const direccion = empresaSelec.ciudades_direcciones;

                    const direccionCiudad = direccion.map(c => c.direccion);
                    this.opcionesDireccion = direccion.map(opc => ({
                        id_ciudad: opc.id_ciudad,
                        nombre_ciudad: opc.nombre_ciudad,
                        direccion: opc.direccion,
                        direccionConCiudad: `${opc.nombre_ciudad} / ${opc.direccion}`
                    }))
                    this.direccion_empresa = direccionCiudad.toString();
                });
            }


        },
        

        async validarFormularios() {
            const recepValido = await this.$refs.valrecepcionista.validate();
            const transValido = await this.$refs.valtransportista.validate();

            if (recepValido && transValido ) {
                this.recepcionista_incompleto = false;
                this.transportista_incompleto = false;
                this.revisado = true;
                return true;
            } else {

                this.recepcionista_incompleto = !recepValido;
                this.transportista_incompleto = !transValido;
                this.revisado = true;
                return false;
            }
        },

        async RellenarForm(response) {            
            //info anterior
            this.recepcionistaRUT = response.rut_empleado;
            this.RUM = response.RUM;
            this.direccionOG = response.direccion_empresa;       
            this.recepcionistaOG = response.rut_empleado;
            this.nMuestrasOG = response.cantidad_muestras.toString();
            this.observacionesOG = response.observaciones
            this.transportistaRutOG = response.rut_transportista;
            this.transportistaOG = response.nombre_transportista;                       
            this.patenteOG = response.patente_vehiculo;
            console.log(response.telefonos_transportista)
            this.telefonos_agregarOG = response.telefonos_transportista;
            this.mapeotelefono(),
            console.log(response.rut_solicitante)
                //TAB RECEPCIONISTA
            this.observacionCliente = response.observacion_cliente;
            if(response.rut_solicitante != null){
                this.rutFilter = response.rut_solicitante;
                await solicitanteService.obtenerDetallesSolicitante(this.rutFilter).then((response) => {
                try{
                    if (response.request.status == 200 && this.rutFilter !== "") {
                    console.log("detalles de solicitante", response.data)
                    this.solicitanteIngresado = true;
                    this.empresaSeleccionada = true;
                    this.opcionesDireccion  = [];
                    this.direccion = '';
                    this.nombre_empresa = '';
                    //obten nombre y apellido
                    this.nombreSolicitante = `${response.data.nombre} ${response.data.primer_apellido} ${response.data.segundo_apellido}`;
                    this.rutSolicitante = response.data.rut_solicitante; 
                    this.telefono_solicitante = response.data.telefono;
                    this.correo_solicitante = response.data.correo;

                    if(response.data.detalles_empresas.length > 0){
                        this.tieneEmpresa = true;
                    }else{

                        this.tieneEmpresa = false;
                        ///
                    }
                    const empresas = response.data.detalles_empresas;
                    this.empresasDelSolicitante = empresas;

                    
                    const data = { rut_solicitante: this.rutSolicitante };
                    solicitanteService.CotizacionSolicitante(data).then((response) => {
                        if (response.data != null && response.status === 200) {
                            console.log("cotizaciones: ", response.data)
                            this.opcionesCotizacion = response.data.map(cotizacion => ({
                                id_cotizacion: cotizacion.id_cotizacion,
                                nombre_original_documento: cotizacion.nombre_original_documento,
                                idconNombre: `${cotizacion.id_cotizacion} - ${cotizacion.nombre_original_documento}`
                            }))
                            if(this.opcionesCotizacion.length>0){
                                this.tieneCotizaciones = true;
                            }else{
                                this.tieneCotizaciones = false;
                            }
                            console.log("Opc. cotizaciones: ", this.opcionesCotizacion)
                        }
                    })

                    console.log(this.empresaSolicitante)
                    if(response.data.tipo_cliente === 'Particular'){
                        this.empresaSolicitante = 'Particular';
                        this.nombre_empresa = 'Particular';
                        this.rut_empresa =null;
                        this.empresasDelSolicitante = [{nombre_empresa: 'Particular',rut_empresa:'Particular'}];

                    }
                }

                }catch{
                    this.showAlert();
                }
            })
            }else{
                await MuestraService.obtenerEmpresas().then((response) => {
                    console.log("obteniendo empresas",response.data)
                    const empresas = response.data;
                    this.opcionesEmpresa = empresas;
                })
                this.empresasDelSolicitante =  this.opcionesEmpresa 
            }  
            this.rut = response.rut_solicitante; 
            if(response.rut_empresa != null){
                this.empresaSolicitante = response.rut_empresa;
            } 
            this.rut_empresa = response.rut_empresa;
            this.nombre_empresa = response.nombre_empresa;
            this.direccion = response.id_ciudad;
            this.cotizacion = response.id_cotizacion;
        //TAB TRANSPORTISTA
            this.transportistaRut = response.rut_transportista;
            this.transportista = response.nombre_transportista;                       
            this.Temperatura = response.temperatura_transporte;
            this.patente = response.patente_vehiculo;
            this.telefonos_eliminar = response.telefonos_transportista;
            if(response.telefonos_transportista.length > 0){
                this.telefono_transportista = response.telefonos_transportista[0].telefono_transportista;
            } 
             //TAB TPAGO
             if( response.valor_neto != null){
                this.valor_neto = response.valor_neto;

             }
             if( response.tipo_pago != null){
                this.tipo_pago = response.tipo_pago;
             }


        },   
               
        async enviarFormulario() {
            const datosValidos = await this.validarFormularios();
            
            if (!datosValidos) {
                return;
            } 
            else{
                if(this.empresaSolicitante =='Particular'){
                    this.empresaSolicitante = null;
                }
                
                this.obs();
                var data = {
                    RUM: this.RUM,
                    rut_empresa: this.empresaSolicitante,
                    rut_empleado: this.recepcionistaRUT,
                    nombre_empresa: this.nombre_empresa,
                    id_ciudad: this.direccion,
                    direccion_empresa: this.direccion_empresa,                    
                    rut_solicitante: this.rutSolicitante,
                    rut_transportista: this.transportistaRut,
                    nombre_transportista: this.transportista,
                    patente_vehiculo: this.patente,
                    telefonos_agregar: {telefono_transportista: this.telefono_transportista},
                    telefonos_eliminar: this.telefonos_eliminar,
                    id_cotizacion: this.cotizacion,
                    tipo_pago: this.tipo_pago,
                    valor_neto: this.valor_neto,
                    observacion_cliente: this.observacionCliente,

                }
                console.log("data a enviar", data)
                MuestraService.editarDatosMuestra(data).then((response) => {
                    console.log(response)
                    if (response != null) {
                        if (response.status == 200) {
                            this.$bvToast.toast(`Ingreso de la muestra exitoso.`, {
                                title: 'Éxito',
                                toaster: 'b-toaster-top-center',
                                solid: true,
                                variant: "success",
                                appendToast: true
                            })    
                            setTimeout(() => {
                                if(this.userData.role == 2|| this.userData.role == 6){ // jefe de laboratorio y supervisor
                                this.$router.push({name: "AdminMuestraSupervisor"});
                                }
                                if(this.userData.role == 3 || this.userData.role == 4){ // quimico y analista quimico
                                    this.$router.push({name: "AdminMuestraLab"});
                                }                               
                                if(this.userData.role == 7 ){ // Adm de finanzas
                                    this.$router.push({name: "AdminMuestraFinanzas"});
                                }
                                
                            }, 1000);                            
                        }else{
                            this.$bvToast.toast(`Error al editar datos de la muestra.`+response.toString(), {
                            title: 'Error',
                            toaster: 'b-toaster-top-center',
                            solid: true,
                            variant: "warning",
                            appendToast: true
                            })

                        }

                    } 
                    else {
                        this.$bvToast.toast(`Error al editar datos de la muestra.`, {
                            title: 'Error',
                            toaster: 'b-toaster-top-center',
                            solid: true,
                            variant: "warning",
                            appendToast: true
                        })
                    }
                })
            }        
        },
    }
}
</script>