<template>
    <validation-observer ref="form">
        <b-modal centered id="modal-editar-analistas-supervisor" :title="`Observaciones de la muestra ${RUM}`" size="xl"  @hidden="onModalClose">


            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <b-row class="d-flex justify-content-around">
                    <div class="pl-3">Editar analistas</div>
                </b-row>

                <button type="button" class="close" aria-label="Close" @click="close()">
                    <span aria-hidden="true" style="color:white">&times;</span>
                </button>
            </template>


            <b-row class="justify-content-between mb-4 ml-2 mr-2">
                <b-col class="col-2">
                    <b-row style=" border: 1px solid var(--lsa-light-gray); padding:4px; border-radius:5px;">
                        <b-col class="col-6" style="font-weight:bold; padding-top: 13px; padding-bottom: 15px;"> RUM: </b-col>
                        <b-col class="col-6" style="padding-top: 13px;">{{ RUM }}</b-col>
                    </b-row>
                </b-col>
                <b-col class="col-5">
                    <b-row style="border: 1px solid var(--lsa-light-gray); padding:4px; border-radius:5px">
                        <b-col class="col-6" style="font-weight:bold; text-align: center; padding-top: 13px;"> Parámetros por asignar: </b-col>
                    <div style="flex-grow: 2; position: relative;">
                        <b-list-group v-if="parametrosOpciones.length > 0">
                        <b-list-group-item v-b-toggle="'collapse-pym-0'" style="position: relative; background-color: #f8f9fa; width: 200px;">
                            <strong style="text-align: center;">{{ parametrosOpciones[0].nombre_parametro }}</strong>
                            <b-icon v-if="parametrosOpciones.length > 1" style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); color: #949494;" icon="caret-down-fill"></b-icon>
                        </b-list-group-item>

                        <b-collapse id="collapse-pym-0" style="position: absolute; z-index: 1; margin-top:50px; width: 200px;" >
                            <b-list-group>
                            <b-list-group-item v-for="(item, index) in parametrosOpciones.slice(1)" :key="index" style="background-color: #f8f9fa; padding-right:20px">
                                <strong style="text-align: center; ">{{ item.nombre_parametro }}</strong>
                            </b-list-group-item>
                            </b-list-group>
                        </b-collapse>
                        </b-list-group>
                        <b-list-group-item v-else variant="success" style="background-color: #f8f9fa;">
                        <strong style="color: green;"> Ninguno</strong>
                        </b-list-group-item>
                    </div>

                    </b-row>
                </b-col>
            </b-row>
            

            <div style="min-height: 300px;">
                <b-list-group-item v-if="loading" class="d-flex align-items-center justify-content-center lsa-orange-text"
                    style="height:100px">
                    <div>
                        <b-spinner class="align-middle"></b-spinner>
                        <strong> Cargando...</strong>
                    </div>
                </b-list-group-item>
                <div class="ml-4">


                    <b-list-group v-if="!loading" style="padding:0px">
                        <b-list-group-item style="padding:0px; height: 70px;">
                            <b-list-group horizontal>
                                <b-list-group-item class="p-3 text-center" style="width:30%;font-weight: bold; position: relative;">
                                    <p style="position: relative; top: -5px;">Analista</p>
                                </b-list-group-item>
                                <b-list-group-item class="p-3 text-center" style="width:15%;font-weight: bold; position: relative;">
                                    <p style="position: relative; top: -15px;">Orden de análisis</p>
                                </b-list-group-item>
                                <b-list-group-item class="p-3 text-center" style="width:20%;font-weight: bold; position: relative;">
                                    <p style="position: relative; top: -5px;">Parámetro</p>
                                </b-list-group-item>
                                <b-list-group-item class="p-3 text-center" style="width:20%;font-weight: bold; position: relative;">
                                    <p style="position: relative; top: -5px;">Fecha de Entrega</p>
                                </b-list-group-item>
                                <b-list-group-item class="p-3 text-center" style="width:15%;font-weight: bold; position: relative;">
                                    <p style="position: relative; top: -5px;">Estado</p>
                                </b-list-group-item>
                                <b-list-group-item class="p-3 text-center" style="width:15%;font-weight: bold; position: relative;">
                                    <p style="position: relative; top: -5px;">Accion</p>
                                </b-list-group-item>
                            </b-list-group>
                        </b-list-group-item>

                        <b-list-group v-if="analistas.length == 0">
                            <b-list-group-item>
                                <div class="text-center lsa-light-blue-text row">
                                    <div class="col">
                                        <b-icon icon="file-earmark-break" animation="fade" variant="secondary"></b-icon>
                                        <div style="font-weight:bold; color:gray">No hay analistas asignados para la
                                            muestra.</div>
                                    </div>

                                </div>
                            </b-list-group-item>
                        </b-list-group>
                        <b-list-group-item v-for="(analista, k) in analistas" :key="k" style="padding:0px">
                            <b-list-group horizontal>
                                <b-list-group-item class="text-center" style="width:30%">

                                    <b-form-select v-model="analista.rut_empleado" :options="getOpcionesDisponibles(k)" :disabled="analista.estado !== 'Sin iniciar'" 
                                        value-field="rut_empleado" text-field="nombre_analista"></b-form-select>



                                </b-list-group-item>
                                <b-list-group-item class="text-center" style="width:15%">
                                    <ValidationProvider :name="'N° orden ' + (k + 1)" rules="required"
                                        v-slot="validationContext">
                                        <b-form-input :state="getValidationState(validationContext)"
                                            :placeholder="'N° orden ' + (parseInt(k) + 1)" style="height:30px" type="number" :min=ultimo_orden_de_analisis :disabled="analista.estado !== 'Sin iniciar'"
                                            class="form-control" v-model="analista.orden_de_analisis" onkeydown="return false"/>

                                        <b-form-invalid-feedback id="ciudad-live-feedback">{{
                                            validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </ValidationProvider>
                                </b-list-group-item>
                                <b-list-group-item rules="required" v-model="analista.parametros" class="text-center" style="width:20%">

                                    <b-list-group>

                                        <template v-if="analista.parametros.length">
                                            <b-list-group-item v-if="analista.parametros.length > 0"
                                                v-b-toggle="'collapse-param' + k.toString()"
                                                style="padding:2px; border: none; border-bottom: solid 1px #dbdbdb; ">
                                                {{ analista.parametros[0].nombre_parametro }}
                                                <b-icon v-if="analista.parametros.length > 1"
                                                    style="position:absolute; right:0px; top:25%; color: #949494"
                                                    icon="caret-down-fill"></b-icon>
                                            </b-list-group-item>
                                            <b-list-group-item v-else
                                                style="padding:2px; border: none; border-bottom: solid 1px #dbdbdb; ">{{
                                                    analista.parametros[0].nombre_parametro }}
        
                                            </b-list-group-item>

                                            <div v-if="analista.parametros.length > 1">
                                                <b-collapse :id="'collapse-param' + k.toString()">
                                                    <b-list-group-item
                                                        style="padding:2px;  border: none; border-bottom: solid 1px #dbdbdb;"
                                                        v-for="index in analista.parametros.length - 1" :key="index">{{
                                                            analista.parametros[index].nombre_parametro }}</b-list-group-item>
                                                </b-collapse>
                                            </div>
                                        </template>
                                        <template v-else>
                                            <div style="font-weight: bold; color:red">Sin parámetros asignados.</div>
                                        </template>
                                    </b-list-group>



                                </b-list-group-item>
                                <b-list-group-item class="text-center" style="width:20%">

                                    <ValidationProvider :name="'Fecha de entrega ' + (k + 1)" rules="required"
                                        v-slot="validationContext">
                                        <b-form-datepicker placeholder="Seleccione una fecha." :disabled="analista.estado !== 'Sin iniciar'" :min="FechaActual"
                                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                            :state="getValidationState(validationContext)" v-model="analista.fecha_object"
                                            :id="'datepicker-' + (k + 1)"></b-form-datepicker>
                                        <b-form-invalid-feedback id="fecha-live-feedback">{{
                                            validationContext.errors[0] }}
                                        </b-form-invalid-feedback>
                                    </ValidationProvider>
                                </b-list-group-item>
                                <b-list-group-item class="p-3 text-center" style="width:15%">
                                    {{ analista.estado }}
                                </b-list-group-item>

                                <b-list-group-item class="p-3 text-center " style="width:15%;font-weight: bold;">
                                    <b-button variant="lsa-gray" @click="abrirAgregarParametrosAnalista(analista)"
                                        style="padding:1px; aspect-ratio: 1 / 1; height: 30px; width: 30px">
                                    <b-icon icon="pencil"></b-icon>
                                    </b-button>
                                    <b-button  class="boton-ojo-metodo" :id="'button-' + k" @click="abrirObservacionesAnalista(analista)" variant="lsa-gray">
                                    <b-icon  icon="sticky" ></b-icon>
                                    </b-button>
                                    <b-button variant="danger" @click="remove(k)" class="ml-2" style="padding:1px; aspect-ratio: 1 / 1; height: 30px; width: 30px">
                                        <b-icon-trash-fill></b-icon-trash-fill>
                                    </b-button>
                                    
                                </b-list-group-item>
                            </b-list-group>
                        </b-list-group-item>
                    </b-list-group>
                    <b-alert variant="success" :show="alertaObservacionAnalista" dismissible @dismissed="alertaObservacionAnalista = false" style="margin-top: 10px;">
                                        Observación guardada con éxito </b-alert>
                    <b-alert variant="danger" :show="alertaAnalistaNoEditable" dismissible style="margin-top: 10px;" @dismissed="alertaAnalistaNoEditable = false">
                            No se puede modificar/eliminar analista que ya inició o terminó su trabajo.  </b-alert>
                    <div class="mt-4 d-flex justify-content-start ml-3">

                        <b-row>
                            <b-button class="lsa-orange reactive-button" @click="add"
                                style="border: none; font-weight: bold;">
                                Agregar analista
                                <b-icon icon="plus"></b-icon>
                            </b-button>
                        </b-row>
                    </div>


                </div>
            </div>
            <b-modal id="modal-Agregar-Parametros" ref="modal" :title="`Agregar parámetro(s) a analista`" size="lg">
                <template #modal-header="{ close }">
                    <b-row class="d-flex justify-content-around">
                        <div class="pl-3">Asignar parámetro(s) a analista</div>
                    </b-row>
                    <button type="button" class="close" aria-label="Close" @click="close()">
                        <span aria-hidden="true" style="color:white">&times;</span>
                    </button>
                </template>
                <b-row>
                    <b-col>
                        <b-form-group label="Seleccione un parámetro:">
                            <b-form-select v-model="parametroSeleccionadoIngreso" :options="parametrosOpciones"
                                text-field="nombre_parametro" value-field="id_parametro"
                                @change="agregarParametroSeleccionado(analistaSeleccionado, parametroSeleccionadoIngreso)">
                                <template #first>
                                    <b-form-select-option :value="null" :disabled="parametrosOpciones.length === 0" v-if="parametrosOpciones.length === 0">No hay parámetros por asignar</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row v-if="analistaSeleccionado && analistaSeleccionado.parametros.length > 0" class="mt-3">
                    <b-col>
                        <b-form-group label="Parámetros seleccionados:">
                            <div v-for="(parametro, index) in analistaSeleccionado.parametros" :key="index"
                                class="d-flex align-items-center objetos-item mb-3">
                                <b-input readonly :value="parametro.nombre_parametro" class="mr-2"></b-input>
                                <b-button variant="danger"
                                    @click="eliminarParametroSeleccionado(analistaSeleccionado, index)" class="ml-2">
                                    <b-icon-trash-fill></b-icon-trash-fill>
                                </b-button>
                            </div>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-alert variant="danger" :show="alertaDuplicado" dismissible @dismissed="alertaDuplicado = false">
                    El parámetro ya se encuentra agregado.
                </b-alert>

                <b-alert variant="success" :show="alertaExito" dismissible @dismissed="alertaExito = false">
                    Parámetro agregado exitosamente.
                </b-alert>
                <!-- //////////////////////////////////////////MODAL-FOOTER////////////////////////////////////////////////////////////////////////////////// -->
                <template #modal-footer="{ close }">
                    <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button"
                        style="font-weight:bold">
                        Cerrar
                    </b-button>
                </template>
            </b-modal>
            <template #modal-footer>

                <b-overlay :show="Cargando" rounded opacity="0.6" spinner-small spinner-variant="primary"
                    class="d-inline-block">
                    <b-button @click="enviarFormulario()" variant="primary" size="xl"
                        class="float-right reactive-button lsa-light-blue" style="font-weight:bold">
                        Editar analistas
                    </b-button>
                </b-overlay>

            </template>

        </b-modal>
        <b-modal centered id="modal-agregar-observacion-analista_editar" ref="modal_observacion_analista_editar" title="Agregar observacion de asignacion" size="md" @hidden='OnModalCloseObservacion'>

            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->

                <b-row class="d-flex justify-content-around">
                    <div class="pl-3">Ingrese observación</div>

                </b-row>

                <button type="button" class="close" aria-label="Close" @click="close()">
                    <span aria-hidden="true" style="color:white">&times;</span>
                </button>
            </template>

            <template #modal-footer>
                <b-overlay 
                    class="d-inline-block">
                    <b-button @click="guardarObservacion(analistaSeleccionadoModal)" variant="primary" size="xl" class="float-right reactive-button"
                        style="font-weight:bold">
                        Guardar observación
                    </b-button>
                </b-overlay>
            </template>

            <b-row class="d-flex justify-content-center">
                <b-col class="col-12">

                    <label for="input-live">Observación:</label>
                
                        <b-form-textarea id="textarea" v-model="observacion_analista" 
                            placeholder="Ingrese observación..." rows="3" max-rows="6"></b-form-textarea>
                
                </b-col>
                <br />

            </b-row>

            </b-modal>

    </validation-observer>
</template>
<script>
import MuestraSupervisorService from '@/helpers/api-services/Muestra-supervisor.service';
//import MuestraSupervisorService from '@/helpers/api-services/Muestra-supervisor.service';
export default {
    components: {},
    props: {
        muestraData: Object
    },
    data() {
        return {
            Cargando: false,
            RUM: '',
            loading: false,
            analistas: [],
            analistasOpciones: [],
            parametrosOpciones: [],
            analistas_antiguos: [],
            analistas_agregar: [],
            analistas_eliminar: [],
            alertaDuplicado: false,
            alertaExito: false,
            alertaAnalistaNoEditable: false,
            analistaSeleccionado: "",
            parametroSeleccionadoIngreso: "",
            parametros_por_asignar: [],
            ultimo_orden_de_analisis: 1,
            analistasSeleccionados: [],
            observacion_analista:'',
            alertaObservacionAnalista:false,
            FechaActual: new Date().toISOString().split('T')[0],

        }
    },
    
    methods: {

        enviarFormulario() {

            this.$refs.form.validate().then(success => {
                if (!success) {
                    return;
                }
                else if (this.analistas.some(analista => analista.rut_empleado === ''  || analista.orden_de_analisis === ''  || analista.fecha_object === null)) {
                    this.$bvToast.toast("Existen datos sin completar en la asignación de analistas", {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: "danger",
                        appendToast: true
                    });
                    return;
                } 
                else if (this.parametrosOpciones.length !== 0) {
                    this.$bvToast.toast("Hay parametros que no se han asignado a ningun analista, intentelo denuevo", {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: "danger",
                        appendToast: true
                    });
                    return;
                }
                else if (this.analistas.some(analista => analista.parametros.length === 0)) {
                    this.$bvToast.toast("Existen analistas sin parametros asociados", {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: "danger",
                        appendToast: true
                    });
                    return;
                }
                else if (!(this.verificarOrdenAnalisis(this.analistas))) {
                    this.$bvToast.toast("Verifique que el orden de analisis de los analistas", {
                        title: 'Error',
                        toaster: 'b-toaster-top-center',
                        solid: true,
                        variant: "danger",
                        appendToast: true
                    });
                    return;
                }
                else {
                    this.Cargando = true;

                    this.analistas.forEach(analista => {
                        if(analista.estado == 'Sin iniciar'){
                            analista.parametros.forEach(param => {
                                if(typeof analista.fecha_object == 'string'){
                                    this.analistas_agregar.push({
                                    rut_empleado: analista.rut_empleado,
                                    orden_de_analisis: analista.orden_de_analisis,
                                    id_parametro: param.id_parametro,
                                    fecha_entrega: analista.fecha_object,
                                    observacion_analista: analista.observacion_analista,

                                })
                                }else{
                                    this.analistas_agregar.push({
                                    rut_empleado: analista.rut_empleado,
                                    orden_de_analisis: analista.orden_de_analisis,
                                    id_parametro: param.id_parametro,
                                    fecha_entrega: analista.fecha_object.toISOString().split('T')[0],
                                    observacion_analista: analista.observacion_analista,

                                })
                                }
                                
                            })
                        }
                        
                    })
                    var data = {
                        RUM: this.RUM,
                        empleados_agregar: this.analistas_agregar,
                        empleados_eliminar: this.analistas_eliminar,
                    }
                    MuestraSupervisorService.modificarAnalistas(data).then((response) => {

                        this.Cargando = false;
                        if(response == undefined){
                            this.analistas_agregar = [];
                            this.$bvToast.toast(`Error al modificar analistas.`, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                solid: true,
                                variant: "warning",
                                appendToast: true
                            })
                        }
                        if (response.request.status == 200) {
                            this.$bvToast.toast(`Los analsitas han sido modificados exitosamente.`, {
                                title: 'Éxito',
                                toaster: 'b-toaster-top-center',
                                solid: true,
                                variant: "success",
                                appendToast: true
                            })
                            this.$bvModal.hide('modal-editar-analistas-supervisor')
                            this.$emit('refrescar');

                        } else {
                            this.analistas_agregar = [];
                            this.$bvToast.toast(`Error al modificar analistas.`, {
                                title: 'Error',
                                toaster: 'b-toaster-top-center',
                                solid: true,
                                variant: "warning",
                                appendToast: true
                            })
                        }
                    })
                }});


        },
        abrirAgregarParametrosAnalista(analista) {

            if(analista.estado != 'Sin iniciar'){
                this.alertaAnalistaNoEditable = true;
                return;
            }
            this.analistaSeleccionado = analista;
            this.$bvModal.show('modal-Agregar-Parametros')
        },
        abrirObservacionesAnalista(analista) {        
            if(analista.estado != 'Sin iniciar'){
                this.alertaAnalistaNoEditable = true;
                return;
            }
            this.analistaSeleccionadoModal = analista;
            this.observacion_analista = this.analistaSeleccionadoModal.observacion_analista;
            this.$bvModal.show('modal-agregar-observacion-analista_editar');
        },
        guardarObservacion(analistaSeleccionado){
            analistaSeleccionado.observacion_analista = this.observacion_analista;
            this.alertaObservacionAnalista = true;
            this.$refs.modal_observacion_analista_editar.hide();

        },
        OnModalCloseObservacion(){
            this.analistaSeleccionadoModal = null;
            this.observacion_analista = '';
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        verificarOrdenAnalisis(analistas) {
            // Extraemos los valores de "Orden de análisis" en un array
            const ordenes = analistas.map(analista => analista.orden_de_analisis);
            console.log(this.ultimo_orden_de_analisis);
            // Filtramos para eliminar duplicados y ordenamos los valores numéricos
            const ordenesUnicos = [...new Set(ordenes)].sort((a, b) => a - b);
            console.log(ordenesUnicos);

            // Verificamos si los valores únicos son consecutivos comenzando desde el menor valor
            let primer_numero = parseInt(ordenesUnicos[0]);
            for (let i = 0; i < ordenesUnicos.length; i++) {
                if (parseInt(ordenesUnicos[i]) !== primer_numero) {
                    return false;
                }
                primer_numero++;
            }
            return true;
        },
        getOpcionesDisponibles(filaIndex) {
            // Filtrar las opciones disponibles excluyendo los analistas ya seleccionados
            return this.analistasOpciones.filter(opcion => !this.analistasSeleccionados.includes(opcion.rut_empleado) || opcion.rut_empleado === this.analistas[filaIndex].rut_empleado);
        },

        add() {
            this.analistas.push({
                fecha_entrega: null,
                fecha_object: null,
                rut_empleado: '',
                parametros: [],
                orden_de_analisis: this.ultimo_orden_de_analisis,
                estado: 'Sin iniciar',
                observacion_analista: null,
            })

        },

        remove(index) {
            if(this.analistas[index].estado != 'Sin iniciar'){
                this.alertaAnalistaNoEditable = true;
                return;
            }
            this.analistas[index].parametros.forEach(parametro =>{
                this.parametrosOpciones.push({nombre_parametro: parametro.nombre_parametro,id_parametro:parametro.id_parametro});
            })
            console.log(this.parametrosOpciones)
            this.analistas.splice(index, 1) // eliminamos solo visualmente ya que la eliminacion, o ya ocurrio durante la obtencion de los analistas, o dicho analista no existe en la BD
        },
        obtenerAnalistasDesignados(rum) {
            this.analistas_agregar = [];
            this.analistas_eliminar = [];
            MuestraSupervisorService.obtenerAnalistasDesignados(rum).then((response) => {
                if (response != null) {
                    if (response.status == 200 && response.data != null) {
                        const analistas = response.data;
                        for (var i = 0; i < analistas.length; i++) {
                            const analistaExistente = this.analistas.find(analista => analista.rut_empleado == analistas[i].rut_empleado);
                            if (analistaExistente == null) {
                                this.analistas.push({
                                    RUM: analistas[i].RUM,
                                    rut_empleado: analistas[i].rut_empleado,
                                    nombre: analistas[i].nombre,
                                    apellido: analistas[i].apellido,
                                    orden_de_analisis: analistas[i].orden_de_analisis,
                                    parametros: [{
                                        id_parametro: analistas[i].id_parametro,
                                        nombre_parametro: analistas[i].nombre_parametro
                                    }],
                                    fecha_entrega: analistas[i].fecha_entrega,
                                    fecha_object: new Date(analistas[i].fecha_entrega),
                                    fecha_entrega_formateada: analistas[i].fecha_entrega_formateada,
                                    estado: analistas[i].estado,
                                    observacion_analista: analistas[i].observacion_analista,
                                    id_aux: i,
                                })
                            } else {
                                analistaExistente.parametros.push({
                                    id_parametro: analistas[i].id_parametro,
                                    nombre_parametro: analistas[i].nombre_parametro
                                })
                            }
                        }
                        
                        // Se borran los analistas actuales y se reemplazaran por los nuevos, lo que significa que crear un analistas_editar no es necesario
                        this.analistas.forEach(analista => {
                            if(analista.estado == 'Sin iniciar'){
                                analista.parametros.forEach(param => {
                                    this.analistas_eliminar.push({
                                        rut_empleado: analista.rut_empleado,
                                        orden_de_analisis: analista.orden_de_analisis,
                                        id_parametro: param.id_parametro,
                                        fecha_entrega: analista.fecha_entrega,
                                        estado: analista.estado,
                                        observacion_analista: analista.observacion_analista,

                                    })
                                })
                            }else{
                                if(analista.orden_de_analisis > this.ultimo_orden_de_analisis){
                                    this.ultimo_orden_de_analisis = analista.orden_de_analisis;
                                }
                            }
                           

                            
                        })
                        console.log(this.ultimo_orden_de_analisis);
                        this.obtenerAnalistas();

                    }
                }
            })
        },
        obtenerAnalistas() {
            this.analistasOpciones = [];

            MuestraSupervisorService.obtenerEmpleados().then((response) => {
                if (response.data != null && response.status == 200) {
                    console.log("data analista", response.data);
                   for (var i = 0; i < response.data.length; i++) {
                        this.analistasOpciones.push({
                            nombre_analista: response.data[i].nombre + " " + response.data[i].apellido,
                            rut_empleado: response.data[i].rut_empleado
                        })
                    }

                    
                }
            })

        },
        
        agregarParametroSeleccionado(analista, id_param) {
            if(this.parametroSeleccionadoIngreso == ''  ||  this.parametroSeleccionadoIngreso == undefined){
                return;
            }
            var parametro = this.parametrosOpciones.find(param => param.id_parametro == id_param);
            console.log('parametro a agregar', parametro)
            const parametroExistente = analista.parametros.find(param => param.id_parametro == parametro.id_parametro);
            if (parametroExistente == null) {
                analista.parametros.push({
                    id_parametro: parametro.id_parametro,
                    nombre_parametro: parametro.nombre_parametro
                })
                this.parametrosOpciones = this.parametrosOpciones.filter(param => !(param.id_parametro == id_param))
                this.alertaExito = true;
            } else {
                this.alertaDuplicado = true;
            }
            this.parametroSeleccionadoIngreso = '';
            console.log(analista)

        },
        eliminarParametroSeleccionado(analista, index) {
            this.parametrosOpciones.push({
                nombre_parametro: analista.parametros[index].nombre_parametro,
                id_parametro:  analista.parametros[index].id_parametro,
            })
            analista.parametros.splice(index, 1); // aca tambien la eliminacion es solo visual ya que la eliminacion de la BD ya ocurrio
        },
        onModalClose() {
            this.analistas = [];
            
        },
    },
    watch: {
        muestraData: {
            handler() {
                this.RUM = this.muestraData.RUM;
                console.log(this.muestraData);
                this.obtenerAnalistasDesignados(this.RUM);
            }
        },
        analistas: {
            handler(newVal) {
                // Actualizar el estado de analistasSeleccionados cada vez que cambia filas
                console.log('xd')
                this.analistasSeleccionados = newVal.filter(fila => fila.rut_empleado !== null).map(fila => fila.rut_empleado);
            },
            deep: true,
        },
    }
}
</script>
