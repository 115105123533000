<template>
    <div>  
        <!-- <validation-observer ref="form"> -->
          <modal_cantidadMuestra :n-muestras="nMuestras" :objetosSeleccionados="objetosSeleccionados" 
          :sub="sub" @datosIngresados="capturarDatos" @eliminados="SubEliminadas" @agregar-sub-param="agregarParametroSubmuestra"
           @eliminar-fila="eliminarSubmuestra" @agregar-submuestra="agregarSubmuestra" @identificacionEliminada="identificadores" 
           :identificaciones="identificacion" :id_submuestra="id_submuestra" :parametros="prevP" :metodologias="prevM" :actualizarModal="actualizarModal"
           :parametro_submuestra_agregar="parametro_submuestra_agregar" @eliminar-parametro-submuestra-agregar="eliminarParametroSubmuestraAgregar"
           :parametro_submuestra_eliminar="parametro_submuestra_eliminar" @eliminar-parametro-submuestra-eliminar='eliminarParametroSubmuestraEliminar'
           :opcionesParametrosSeleccionados="opcionesParametrosSeleccionados" />
            <modal_agregarEmpresa @refrescar="obtenerEmpresas" />
            <modal_agregarSolicitante />

            <m_agregarParametro @refrescar="this.obtenerParametro"/>
            <m_agregarMetodologia @refrescar="this.obtenerParametro"/>
        <div> 
            <b-card no-body > 
                <div style="height:20px"></div>

                <div class="rum-container">
                    <strong class="rum-label">RUM: </strong>
                    <span class="rum-number">{{ RUM }}</span>
                </div>
                <b-row class="d-flex justify-content-center" style='width:2300px'>
                    <b-col class="col-8" >
                        <b-tabs active-nav-item-class="lsa-orange" no-fade v-model="tabIndex" pills card vertical
                            style="height:65vh">
                            <b-row class="d-flex justify-content-center">
                                <b-col class="col-12">
  
                                    <b-tab title="Recepcionista">
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon v-if="!recepcionista_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Recepcionista</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valrecepcionista">

                                        <strong> Datos de la recepcion </strong>
                                        <b-card>
                                            <b-row class="pb-2">
                                                <b-col class="col-6">
                                                    <ValidationProvider name="Rut Recepcionista" rules="required|rut"
                                                        v-slot="validationContext">
                                                        <label for="RutRecepcionista-input">RUT del recepcionista:</label>
                                                        <b-form-input id="Rut Recepcionista-input" readonly
                                                            v-model="recepcionistaRUT"
                                                            :state="getValidationState(validationContext)"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                        }}</b-form-invalid-feedback>
                                                    </ValidationProvider>
                                                </b-col>
                                                <b-col class="col-6">
                                                    <ValidationProvider name="NombreRecepcionista" rules="required"
                                                        v-slot="validationContext">
                                                        <label for="input-live">Nombre del recepcionista:</label>
                                                        <b-form-input v-model="recepcionista" readonly
                                                            :state="getValidationState(validationContext)"
                                                            placeholder="Seleccione un recepcionista"></b-form-input>
                                                        <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                        }}</b-form-invalid-feedback>
                                                    </ValidationProvider>
                                                </b-col>

                                            </b-row>                                            
                                        </b-card>
                                        </validation-observer>
                                        <strong> Identificacion del cliente </strong>
                                        <b-card>
                                            <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        
                                                            <label for="input-live">RUT Solicitante:</label>
                                                            <div class="d-flex align-items-center ">
                                                                <b-input-group>
                                                                <!-- Input como filtro de opciones -->
                                                                <b-form-input
                                                                    v-model="rutFilter"
                                                                    placeholder="Buscar solicitante..."
                                                                    :disabled="solicitanteIngresado"
                                                                ></b-form-input>
                                                                <b-button class=" lsa-orange reactive-button"
                                                                    @click="obtenerDatosSolicitante()">
                                                                    <b-icon icon="search"></b-icon>
                                                                </b-button>
                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        v-b-modal.modal-solicitante>
                                                                        <b-icon style="color:white" icon="person-plus">
                                                                        </b-icon>
                                                                    </b-button>
                                                                    <b-button class=" lsa-orange reactive-button"
                                                                    @click="limpiarDatosIdentificacion()">
                                                                    <b-icon icon="arrow-clockwise"></b-icon>
                                                                    </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                            </div>                                                        
                                                        <b-alert :show="dismissCountDown" dismissible fade variant="danger"
                                                            @dismiss-count-down="countDownChanged">
                                                            El RUT del solicitante no está registrado en la base de datos.
                                                        </b-alert>

                                                        <label for="NombreSolicitante-input">Nombre solicitante:</label>
                                                        <b-form-input id="Nombre Solicitante-input" readonly v-model="nombreSolicitante" ></b-form-input>
                                                        
                                                        <label for="ObservacionCliente-input">Observacion Cliente:</label>
                                                        <b-form-input id="Observacion Cliente-input" v-model="observacionCliente" ></b-form-input>

                                                        
                                                    </b-col>
                                                    <b-col class="col-6">
                                        
                                                        <label for="input-live">Nombre de la empresa:</label>
                                                        <div class="d-flex align-items-center ">
                                                            <b-input-group>
                                                                <b-form-select id="input-live" v-model="empresaSolicitante"
                                                                :options="empresasDelSolicitante"
                                                                
                                                                :disabled="empresaSolicitante === 'Particular'" 
                                                                text-field="nombre_empresa"
                                                                value-field="rut_empresa"
                                                                @input="actualizarSelectedEmpresa" filter>
                                                                <template #first>
                                                                    <b-form-select-option v-if="tieneEmpresa" disabled :value="null">Selecciona una empresa</b-form-select-option>
                                                                    <b-form-select-option v-if="!tieneEmpresa" disabled :value="null">Solicitante sin empresas asociadas</b-form-select-option>
                                                                </template>   
                                                            </b-form-select>
                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        v-b-modal.modal-empresa>
                                                                        <b-icon style="color:white" icon="person-plus">
                                                                        </b-icon>
                                                                    </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </div>


                                                        <label for="input-live">Dirección de la empresa:</label>
                                                        <b-form-select id="input-live" v-model="direccion"
                                                            :options="opcionesDireccion"
                                                            :disabled="empresaSolicitante === '' ||empresaSolicitante === 'Particular'||!empresaSeleccionada || empresaSolicitante=== null"
                                                            text-field="direccionConCiudad" value-field="id_ciudad">
                                                        </b-form-select>

                                                        <label class="mt-1" for="input-live">Cotización:</label>
                                                        <b-form-select id="input-live" v-model="cotizacion"  :options="opcionesCotizacion" text-field="idconNombre" value-field="id_cotizacion" aria-describedby="input-live-help Cotizacion-live-feedback">
                                                            <template #first>
                                                                <b-form-select-option v-if="solicitanteIngresado && tieneCotizaciones" disabled :value="null">Seleccione una cotización</b-form-select-option>
                                                                <b-form-select-option v-if="!solicitanteIngresado" disabled :value="null">Ingrese solicitante para visualizar sus cotizaciones</b-form-select-option>
                                                                <b-form-select-option v-if="solicitanteIngresado && !tieneCotizaciones" disabled :value="null">Solicitante sin cotizaciones asociadas</b-form-select-option>
                                                            </template>
                                                        </b-form-select>

                                                    </b-col>
                                            </b-row>      
                                        </b-card>
                                    </b-tab>
                                    <b-tab title="Pago">
                                        <strong>Datos del Pago</strong>
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Pago</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valpago">
                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                            <label for="input-pago">Forma de pago:</label>
                                                            <b-form-select id="input-pago" v-model="tipo_pago"
                                                                :options="opcionesPago">
                                                            </b-form-select>    
                                                    </b-col>
                                                   
                                                    <b-col class="col-6">
                                                            <label for="input-valor">Valor neto del pago:</label>
                                                            <ValidationProvider name="valor neto" rules="numeric"
                                                                 v-slot="validationContext">
                                                            <b-input-group size="sm">
                                                                <b-form-input id="input-valor" v-model="valor_neto" :state="getValidationState(validationContext)" >
                                                                </b-form-input>
                                                                <b-form-invalid-feedback id="movil-live-feedback">{{
                                                                validationContext.errors[0] }}
                                                            </b-form-invalid-feedback>
                                                            </b-input-group>
                                                            
                                                        </ValidationProvider>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </validation-observer>
                                    </b-tab>
                                    <b-tab title="Muestra">
                                        <strong> Datos de la muestra</strong>
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
  
                                                    <b-icon v-if="!muestra_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Muestra</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valmuestra">
  
                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        <ValidationProvider name="nMuestras" rules="required|numeric"
                                                            v-slot="validationContext">
                                                            <label for="input-live">N° de muestras:</label>
                                                            <div class="d-flex align-items-center">
                                                                <b-input-group size="sm">  
                                                                    <b-form-input readonly id="nMuestras-input" v-model="nMuestras"
                                                                        :state="getValidationState(validationContext)"
                                                                        aria-describedby="nMuestras-live-feedback"></b-form-input>                                                                    
                                                                </b-input-group>
  
                                                            </div>
                                                            <b-form-invalid-feedback id="nMuestras-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>


                                                        <ValidationProvider name="fecha de recepción" rules="required"
                                                            v-slot="validationContext">
                                                        <label for="input-live">Fecha de recepción:</label>
                                                        <b-form-datepicker
                                                            :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                                                            id="input-live" v-model="fecha_recepcion"
                                                            :state="getValidationState(validationContext)"
                                                            aria-describedby="input-live-help fecha de recepción-live-feedback"
                                                            placeholder="Seleccione fecha">
                                                        </b-form-datepicker>
                                                        <b-form-invalid-feedback id="fecha de recepción-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>

                                                        <ValidationProvider name="hora de recepcion" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-time">Hora de recepción:</label>
                                                            <b-form-input 
                                                                id="input-time" 
                                                                v-model="hora_recepcion"
                                                                time-format="HH:mm"
                                                                aria-describedby="input-live-help hora-de-recepcion-live-feedback"
                                                                :state="getValidationState(validationContext)"
                                                                :type="'time'"
                                                                placeholder="Ingrese hora">
                                                            </b-form-input>
                                                            <b-form-invalid-feedback id="hora de recepcion-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
  
                                                        <ValidationProvider name="fecha de muestreo" rules="required"
                                                            v-slot="validationContext">
                                                            <label class="mt-1" for="input-live">Fecha de muestreo:</label>
                                                            <b-form-datepicker
                                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                id="input-live" v-model="fecha"
                                                                :state="getValidationState(validationContext)"                                                              
                                                                placeholder="Seleccione fecha"></b-form-datepicker>
                                                                <b-form-invalid-feedback id="fecha de muestreo-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
                                                           
  
                                                        <ValidationProvider name="Hora de mustreo" rules="required"
                                                            v-slot="validationContext">
                                                        <label for="input-time">Hora de muestreo:</label>
                                                        <b-form-input 
                                                            id="input-time" 
                                                            v-model="hora"
                                                            time-format="HH:mm"
                                                            aria-describedby="input-live-help hora-de-muestreo-live-feedback"
                                                            :state="getValidationState(validationContext)"
                                                            :type="'time'"
                                                            placeholder="Ingrese hora">
                                                        </b-form-input>
                                                        <b-form-invalid-feedback id="hora de muestreo-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
                                                            
  
                                                        <ValidationProvider name="Fecha de entrega" :rules="{ required: true, after: FechaActual  }"
                                                            v-slot="validationContext"><label class="mt-1"
                                                                for="input-live">Fecha de entrega:</label>
                                                            <b-form-datepicker
                                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                                                :state="getValidationState(validationContext)"
                                                                id="input-live" v-model="fechaEntrega"
                                                                placeholder="Seleccione la fecha de entrega:"
                                                                :min="FechaActual"></b-form-datepicker>
  
                                                            <b-form-invalid-feedback id="entrega-live-feedback">{{
                                                                validationContext.errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </ValidationProvider>

  
                                                        
                                                            
                                                            
                                                    </b-col>
  
                                                    <b-col class="col-6">
                                                        <ValidationProvider name="muestreado" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Muestreado por:</label>
                                                            <b-form-select id="input-live" v-model="muestreado"
                                                                :options="opcionesMuestreado"
                                                                aria-describedby="input-live-help muestreado-live-feedback"
                                                                :state="getValidationState(validationContext)"></b-form-select>
                                                            <b-form-invalid-feedback id="muestreado-live-feedback">{{
                                                                validationContext.errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </ValidationProvider>
  
                                                        <ValidationProvider name="prioridad" rules="required"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Prioridad:</label>
                                                            <b-form-select class="mt-1" id="input-live" v-model="prioridad"
                                                                :options="opcionesPrioridad"
                                                                aria-describedby="input-live-help prioridad-live-feedback"
                                                                :state="getValidationState(validationContext)"></b-form-select>
                                                            <b-form-invalid-feedback id="prioridad-live-feedback">{{
                                                                validationContext.errors[0] }}
                                                            </b-form-invalid-feedback>
                                                        </ValidationProvider>
  
                                                        <ValidationProvider name="TipoMatriz" :rules="isCheckboxCheckedMatriz ? '' : 'required'"
                                                            v-slot="validationContext">
                                                            <label for="input-live">Matriz:</label>
                                                            <b-input-group size="sm" class="mb-2">
                                                            <b-form-select id="input-live" v-model="TipoMatriz"
                                                                :options="opcionesMatriz"
                                                                aria-describedby="input-live-help tipoMatriz-live-feedback"
                                                                :state="getValidationState(validationContext)"
                                                                text-field="nombre_matriz"
                                                                value-field="id_matriz"
                                                                :disabled="isCheckboxCheckedMatriz">
                                                            </b-form-select>
                                                            <b-input-group-append is-text>
                                                                <b-form-checkbox v-model="isCheckboxCheckedMatriz" switch @input="TipoMatriz = isCheckboxCheckedMatriz ? '' : TipoMatriz" class="mr-n2 mb-n1"></b-form-checkbox>
                                                            </b-input-group-append>
                                                            </b-input-group>
                                                            <b-form-invalid-feedback id="TipoMatriz-live-feedback">{{
                                                                validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
                                                       
                                                        <label for="input-live">Temperatura (°C):</label>
                                                        <b-input-group size="sm" class="mb-2">
                                                            <b-form-input id="Temperatura-input" v-model="Temperatura" aria-describedby="Temperatura-live-feedback" :disabled="isCheckboxCheckedTemp"></b-form-input>
                                                            <b-input-group-append is-text>
                                                                <b-form-checkbox v-model="isCheckboxCheckedTemp" switch @input="clearInput" class="mr-n2 mb-n1"></b-form-checkbox>
                                                            </b-input-group-append>
                                                        </b-input-group>                   
  
                                                                                                                    
  
                                                        <label class="mt-1" for="input-live">Observaciones:</label>
                                                        <b-form-textarea id="input-live" v-model="observaciones" rows="1"
                                                            aria-describedby="input-live-help observaciones-live-feedback"></b-form-textarea>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
  
                                        </validation-observer>
                                    </b-tab>
                                    
                                    <b-tab title="Transportista">
                                        <strong>Datos del transportista</strong>
  
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
                                                    <b-icon v-if="!transportista_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Datos del transportista</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
                                        <validation-observer ref="valtransportista">
  
                                            <b-card>
                                                <b-row class="pb-2">
                                                    <b-col class="col-6">
                                                        <ValidationProvider name="Rut Transportista" rules="rut|rutSinPuntoGuion|max:9"
                                                            v-slot="validationContext">
                                                        <label for="input-live">RUT:</label>
                                                        <b-form-input id="transportistaRut-input" class="mb-1"
                                                            v-model="transportistaRut"
                                                            aria-describedby="transportistaRut-live-feedback"
                                                            :state="getValidationState(validationContext)"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0]
                                                            }}</b-form-invalid-feedback>
                                                        </ValidationProvider>
                                                                                             
                                                        <label for="input-live">Teléfono móvil:</label>
                                                       
                                                       <ValidationProvider name="número móvil" rules="numeric|min:8|max:15"
                                                           v-slot="validationContext">
                                                           <b-input-group size="sm" class="mb-1">

                                                               <b-input-group-prepend is-text>
                                                                   +56 9
                                                               </b-input-group-prepend>
                                                               <b-form-input id="input-live" v-model="telefono_transportista"
                                                                   aria-describedby="input-live-help movil-live-feedback"
                                                                   :state="getValidationState(validationContext)" placeholder=""></b-form-input>
                                                               <b-form-invalid-feedback id="movil-live-feedback">{{
                                                                   validationContext.errors[0] }}
                                                               </b-form-invalid-feedback>
                                                           </b-input-group>

                                                       </ValidationProvider>
  
                                                    </b-col>
  
                                                    <b-col class="col-6">
                                                        <label for="input-live">Nombre y apellido:</label>
                                                        <b-form-input class="mb-1" id="input-live" v-model="transportista"
                                                            aria-describedby="input-live-help transportista-live-feedback"
                                                            placeholder="" trim></b-form-input>
  
                                                        <label for="input-live">Patente:</label>
                                                        <b-form-input class="mb-1" id="input-live" v-model="patente"
                                                            aria-describedby="input-live-help patente-live-feedback"
                                                            placeholder="" trim></b-form-input>
                                                    </b-col>
                                                </b-row>
                                            </b-card>
                                        </validation-observer>
                                    </b-tab>
  
                                    <b-tab title="Parámetros">
                                        <strong> Parámetros</strong>
  
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-between">
  
                                                    <b-icon v-if="!parametros_incompleto" icon="check-square"></b-icon>
                                                    <b-icon v-else-if="revisado" icon="exclamation-square"></b-icon>
                                                    <b-icon v-else icon="arrow-right-short"></b-icon>
                                                    <strong style="padding-left:30px">Parámetros</strong>
                                                </b-row>
                                            </b-col>
                                        </template>
  
                                        <validation-observer ref="valparametros">
  
                                            <b-card>
                                                <b-row>
                                                    <b-col class="col-6">
                                                      <b-form-group label="Seleccione una norma">
                                                        <b-input-group>
                                                        <b-form-select v-model="norma" 
                                                            text-field="nombre_norma"
                                                            value-field="id_norma" 
                                                            :disabled="normaDisabled"
                                                            @change="obtenerTablasNormas">
                                                            <option v-for="opcion in opcionesNorma" 
                                                            :key="opcion.id_norma" 
                                                            :value="opcion.id_norma">
                                                            {{opcion.nombre_norma}}</option>
                                                        </b-form-select>
                                                        <b-input-group-append>
                                                                <b-button size="sm" class="lsa-orange reactive-button"
                                                                    style="aspect-ratio:1;"
                                                                    @click="PushParametrosMetodologias()">
                                                                    <b-icon style="color:white"
                                                                        icon="box-arrow-in-down-left"></b-icon>
                                                                </b-button>
                                                            </b-input-group-append>
                                                        </b-input-group>
                                                        <b-form-checkbox v-model="normaDisabled" switch @input="norma = normaDisabled ? null : norma;tabla = normaDisabled ? null : tabla" @change="resetOpcionesParametrosInciales">
                                                                    Desactivar norma
                                                        </b-form-checkbox>
                                                      </b-form-group>
                                                    </b-col>
  
                                                    <b-col class="col-6">
                                                        <b-form-group label="Seleccione una tabla:">
                                                            <b-input-group>
                                                                <b-form-select :disabled="!norma" v-model="tabla"
                                                                :options="opcionesTabla" value-field="id"
                                                                @change="actualizarParametrosTabla">
                                                                </b-form-select>

                                                                <b-input-group-append>
                                                                    <b-button size="sm" class="lsa-orange reactive-button"
                                                                        style="aspect-ratio:1; border: none"
                                                                        @click="PushParametrosMetodologiasTabla()">
                                                                    <b-icon style="color:white"
                                                                        icon="box-arrow-in-down-left">
                                                                    </b-icon>
                                                                </b-button>
                                                                </b-input-group-append>
                                                            </b-input-group>
                                                        </b-form-group>
                                                    </b-col>
                                                </b-row>
  
                                                <b-row>
                                                    <b-col>
  
                                                        <label for="input-live">Seleccione un parámetro:</label>
                                                        <b-input-group>
  
                                                            <b-form-select v-model="parametroTablaSeleccionado"
                                                                :options="opcionesParametro"
                                                                placeholder="Seleccione un Parámetro"
                                                                @change="agregarObjetosTablaSeleccionados"></b-form-select>
  
                                                            <b-input-group-append>
                                                                <b-button size="sm" class="lsa-orange reactive-button"
                                                                    style="aspect-ratio:1;"
                                                                    v-b-modal.modal-Agregar-Parametro>
                                                                    <b-icon style="color:white"
                                                                        icon="plus-circle-fill"></b-icon>
                                                                </b-button>
                                                            </b-input-group-append>
  
                                                        </b-input-group>
                                                    </b-col>
  
                                                    <b-col>
                                                        <label for="input-live">Seleccione una metodología:</label>
                                                        <b-input-group>

                                                            <b-form-select v-model="metodologiaTablaSeleccionada"
                                                                :options="opcionesMetodologiaTabla"
                                                                placeholder="Seleccione una metodología"
                                                                :disabled="metodologiaDeshabilitada"
                                                                @change="agregarObjetosTablaSeleccionados"
                                                                value-field="nombre_metodologia" 
                                                                text-field="nombre_metodologia"></b-form-select>

                                                            <b-input-group-append>
                                                                <b-button size="sm" class="lsa-orange reactive-button"
                                                                    style="aspect-ratio:1; border: none"
                                                                    v-b-modal.modal-Agregar-Metodologia>
                                                                    <b-icon style="color:white" icon="plus-circle-fill">
                                                                    </b-icon>
                                                                </b-button>
                                                            </b-input-group-append>

                                                        </b-input-group>
                                                    </b-col>
                                                </b-row>
  
                                                <b-row v-if="objetosSeleccionados.length > 0" class="mt-3">
                                                    <b-col>
                                                        <label style="font-weight: bold;">Parámetros seleccionados:</label>
                                                            <b-form-group label-size="sm"
                                                                style="overflow-y: scroll; height: 250px; border:1px solid lightgray;"
                                                                >
                                                                <b-list-group horizontal
                                                                    v-for="(objetos, index) in objetosSeleccionados"
                                                                    :key="index" >
                                                                    <b-list-group-item class="d-flex align-items-center justify-content-center"  style="width:45%; padding:2px; height:40px">
                                                                        {{ objetos.parametro }}
                                                                    </b-list-group-item>
                                                                    <b-list-group-item class="d-flex align-items-center justify-content-center" style="width:45%; padding:2px; height:40px">
                                                                        {{ objetos.metodologia }}
                                                                    </b-list-group-item>
                                                                    <b-list-group-item class="d-flex align-items-center justify-content-center" style="width:10%; padding:2px; height:40px">
                                                                        <b-button  class="reactive-button d-flex align-items-center justify-content-center" variant="danger" style="height:30px; width:30px;"
                                                                            @click="eliminarObjetosTablaSeleccionados(index)"
                                                                            >
                                                                           <b-icon scale="0.8" icon="trash-fill"></b-icon>
                                                                        </b-button>
                                                                    </b-list-group-item>

                                                                </b-list-group>
                                                            </b-form-group>
                                                    </b-col>
                                                </b-row>
  
                                                <b-alert variant="danger" :show="alertaDuplicado" dismissible
                                                    @dismissed="alertaDuplicado = false">
                                                    El parámetro y su metodologia ya se encuentra agregado. 
                                                </b-alert>
                                                <b-alert variant="success" :show="alertaExito" dismissible @dismissed="alertaExito = false">
                                                    Parámetro agregado con éxito.
                                                </b-alert>
                                            </b-card>
                                        </validation-observer>
                                    </b-tab>
  
                                    <b-tab title="Asignar identificadores a la muestra">        
                                        <template #title>
                                            <b-col class="col-12">
                                                <b-row class="d-flex justify-content-end">
                                                    <b-icon icon="arrow-right-short"></b-icon>                                                  
                                                    <strong style="padding-left:30px">
                                                        Asignar identificadores a la muestra
                                                    </strong>
                                                </b-row>
                                            </b-col>
                                        </template>
  
                                        <b-card>
                                            <div class="mt-5">
                                                <b-button class="lsa-orange reactive-button" @click="agregar()"
                                                    style="border: none" pill size="md">
                                                    Asignar identificadores a la muestra
                                                    <b-icon icon="plus-circle-fill"></b-icon>
                                                </b-button>
                                            </div>
                                        </b-card>
                                    </b-tab>
                                  <b-tab title="Asignar analista">

                                    <template #title>
                                        <b-col class="col-12">
                                            <b-row class="d-flex justify-content-end">
                                                <b-icon icon="arrow-right-short"></b-icon>                       

                                                <strong style="padding-left:146px"> Asignar analista</strong>
                                            </b-row>
                                        </b-col>
                                    </template>
                                    <div style="display: flex; flex-direction: row; align-items: flex-start; width: 300px; margin-bottom:10px;" >
                                        <div style="flex-grow: 1; text-align: center; background-color: #f8f9fa;padding:12px; border: 1px solid #dee2e6; padding-left:57px; padding-right:57px; white-space: nowrap;">
                                            <strong>Parametros por asignar: </strong>
                                        </div>
                                    <div style="flex-grow: 2; position: relative;">
                                        <b-list-group v-if="parametrosPorAsignar.length > 0">
                                        <b-list-group-item v-b-toggle="'collapse-pym-0'" style="position: relative; background-color: #f8f9fa; width: 400px;">
                                            <strong style="text-align: center;">{{ parametrosPorAsignar[0].parametro }}</strong>
                                            <b-icon style="position: absolute; right: 10px; top: 50%; transform: translateY(-50%); color: #949494;" icon="caret-down-fill" v-if="parametrosPorAsignar.length > 1"></b-icon>
                                        </b-list-group-item>

                                        <b-collapse id="collapse-pym-0" style="position: absolute; z-index: 1; margin-top:50px; width: 400px;" >
                                            <b-list-group>
                                            <b-list-group-item v-for="(item, index) in parametrosPorAsignar.slice(1)" :key="index" style="background-color: #f8f9fa; padding-right:20px">
                                                <strong style="text-align: center; ">{{ item.parametro }}</strong>
                                            </b-list-group-item>
                                            </b-list-group>
                                        </b-collapse>
                                        </b-list-group>
                                        <b-list-group-item v-else variant="success" style="background-color: #f8f9fa;">
                                        <strong> Ninguno</strong>
                                        </b-list-group-item>
                                    </div>
                                    </div>

                                      <b-card style='width:700px;'>
                                        <b-alert variant="success" :show="alertaObservacionAnalista" dismissible @dismissed="alertaObservacionAnalista = false">
                                        Observación guardada con éxito
                                    </b-alert>
                                      <b-table :items="empleadosPorAsignar" :fields="tableFields" >                                               
  
                                          <template #cell(rut_empleado)="row">
                                              <b-form-select v-model="row.item.rut_empleado" :options="getOpcionesDisponibles(row.index)" value-field="rut_empleado" text-field="nombre_analista"></b-form-select>
                                          </template>                                               
                                          
                                          <template #cell(parametro)="row">  
                                            
                                              <b-list-group v-if="row.item.parametro.length > 0">
                                                
                                                  <b-list-group-item v-if="row.item.parametro.length > 1" v-b-toggle="'toggle-' + row.index" style="padding:2px; border: none; border-bottom: solid 1px #dbdbdb; ">{{ row.item.parametro[0] }}
                                                    <b-icon style="position:absolute; right:0px; top:25%; color: #949494" icon="caret-down-fill"></b-icon>
                                                  </b-list-group-item>
                                                  <b-list-group-item v-if="row.item.parametro.length === 1" style="padding:2px; border: none; border-bottom: solid 1px #dbdbdb; ">{{ row.item.parametro[0] }}</b-list-group-item>
                                                  <div v-if="row.item.parametro.length > 1">
                                                    <b-collapse :id="'toggle-' + row.index">
                                                      <b-list-group-item style="padding:2px;  border: none; border-bottom: solid 1px #dbdbdb;" v-for="(parametro, index) in row.item.parametro.slice(1)" :key="index">{{ parametro }}</b-list-group-item>
                                                    </b-collapse>
                                                  </div>
                                              </b-list-group>

                                          </template>                                                
  
                                          <template #cell(orden_de_analisis)="row">
                                              <b-input v-model="row.item.orden_de_analisis" type="number" min="1" class="small-input" onkeydown="return false"></b-input>
                                          </template>
                                      
                                          <template #cell(fecha_entrega_submuestra)="row">
                                            <b-form-datepicker locale="es" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }" v-model="row.item.fecha_entrega " :min="FechaActual"></b-form-datepicker>
                                          </template> 
                                          
                                          <template #cell(accion)="row">
                                              <b-dropdown right size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
                                                  <template #button-content>
                                                      <b-icon style="height: 80%; width: 80%; align-items: center;" icon="three-dots" variant="dark" aria-hidden="true"></b-icon>
                                                  </template>
                                                  <b-dropdown-item v-if="row" @click="abrirParam(row.item)">
                                                       <b-icon icon="person-plus-fill" aria-hidden="true" class="mr-2"></b-icon>Agregar parámetro
                                                  </b-dropdown-item>
                                                  <b-dropdown-item v-if="row" @click="eliminarFila(row.item)">
                                                       <b-icon icon="trash-fill" aria-hidden="true" class="mr-2"></b-icon>Eliminar fila
                                                  </b-dropdown-item>        
                                                  <b-dropdown-item v-if="row" @click="abrirObservacionesAnalista(row.item)">
                                                       <b-icon icon="sticky" aria-hidden="true" class="mr-2"></b-icon>Añadir Observación
                                                    </b-dropdown-item>                                                                                                  
                                              </b-dropdown>

                                          </template>
                                         
                                      </b-table>
                                      <b-button size="sm" class="lsa-orange reactive-button"
                                        style="aspect-ratio:1;" @click="agregarFila"><b-icon style="color:white"
                                            icon="plus-circle-fill"></b-icon>
                                      </b-button>                                      
                                      </b-card>
                                  </b-tab>
  
                                </b-col>
                                <div style="position:absolute; right:20px; bottom:15px; width:45%">
                                    <b-row class="d-flex justify-content-between">
                                      <b-col class="col-6">
                                        <b-button v-if="tabIndex > 0" block class="lsa-blue reactive-button" pill @click="goToPreviousTab">Atrás</b-button>
                                      </b-col>
                                      <b-col class="col-6">
                                        <b-button v-if="shouldShowNextButton()" block class="lsa-blue reactive-button" pill @click="goToNextTab">Siguiente</b-button>
                                      </b-col>
                                    </b-row>
  
                                    <b-button @click="enviarFormulario()" variant="primary" size="xl"
                                        class="reactive-button lsa-light-blue"
                                        style="font-weight:bold; margin-top:30px; position:absolute; width:100%; right:0px">
                                        Ingresar muestra
                                    </b-button>
                                </div>
                            </b-row>
  
                        </b-tabs>
                    </b-col>
  
                </b-row>
  
            </b-card>
            <!--
                <b-row class="d-flex justify-content-center mt-2">
                    <b-col class="col-10">
                        <b-button @click="enviarFormulario()" variant="primary" size="xl"
                            class="reactive-button" style="font-weight:bold;">
                            Recepcionar Muestra
                        </b-button>
                    </b-col>
                </b-row>
                -->
            <!-- Control buttons-->
  
            <b-modal centered id="modal-Agregar-Opciones" ref="modal" :title="`Agregar parámetro a opciones`" size="lg"
                @shown="onModalShown">
                <template #modal-header="{ close }">
                    <b-row class="d-flex justify-content-around">
                        <div class="pl-3">Agregar parámetros:</div>
                    </b-row>
                    <button type="button" class="close" aria-label="Close" @click="close()">
                        <span aria-hidden="true" style="color:white">&times;</span>
                    </button>
                </template>
                <b-row>
                    <b-col>
                        <label>Seleccione un parámetro:</label>
                        <b-input-group>
                            
                            <b-form-select v-model="parametroSeleccionado" :options="TodasopcionesParametro"
                                placeholder="Seleccione un Parámetro" @change="agregarObjetosSeleccionados"></b-form-select>
                            <b-input-group-append>
                                <b-button size="sm" class="lsa-orange reactive-button" style=" border: none"
                                    v-b-modal.modal-Agregar-Parametro>
                                    Nuevo
                                    <b-icon style="color:white" icon="pencil-square"></b-icon>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
  
                    <b-col>
                        <label>Seleccione una metodología:</label>
                        <b-input-group>
                           
                            <b-form-select v-model="metodologiaSeleccionada" :options="opcionesMetodologia"
                                placeholder="Seleccione una metodología" :disabled="metodologiaDeshabilitada"
                                @change="agregarObjetosSeleccionados"></b-form-select>
                            <b-input-group-append>
                                <b-button size="sm" class="lsa-orange reactive-button" style="border: none"
                                    v-b-modal.modal-Agregar-Metodologia>
                                    Nuevo
                                    <b-icon style="color:white" icon="pencil-square"></b-icon>
                                </b-button>
                            </b-input-group-append>
                        </b-input-group>
                    </b-col>
                </b-row>
  
                <b-alert variant="danger" :show="alertaDuplicado" dismissible @dismissed="alertaDuplicado = false">
                    Los parámetros y metodologías ya se encuentran agregados.
                </b-alert>
  
                <b-alert variant="success" :show="alertaExito" dismissible @dismissed="alertaExito = false">
                    Parámetro y metodología agregados con éxito.
                </b-alert>
                <!-- //////////////////////////////////////////MODAL-FOOTER////////////////////////////////////////////////////////////////////////////////// -->
                <template #modal-footer="{ close }">
                    <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button"
                        style="font-weight:bold">
                        Cerrar
                    </b-button>
                </template>
            </b-modal>
            <b-modal id="modal-Agregar-Parametros" ref="modal" :title="`Agregar parámetro a analista`" size="lg" @hidden="onModalClose">
                      <template #modal-header="{ close }">
                          <b-row class="d-flex justify-content-around">
                              <div class="pl-3">Asignar parámetros a analista</div>
                          </b-row>
                          <button type="button" class="close" aria-label="Close" @click="close()">
                              <span aria-hidden="true" style="color:white">&times;</span>
                          </button>
                      </template>
                      <b-row>
                          <b-col>
                            <b-form-group label="Seleccione un parámetro">
                            <b-form-select v-model="parametroSeleccionadoIngreso" :options="parametrosPorAsignar" text-field="parametro" value-field="id_parametro" @change="agregarParametro(filaSeleccionada)">
                                <template #first>
                                    <b-form-select-option :value="null" :disabled="parametrosPorAsignar.length === 0" v-if="parametrosPorAsignar.length === 0">No hay parámetros por asignar</b-form-select-option>
                                </template>
                            </b-form-select>
                        </b-form-group>
                          </b-col>                       
                      </b-row>
  
                      <b-row v-if="filaSeleccionada && filaSeleccionada.id_parametro.length > 0" class="mt-3">
                          <b-col>
                              <b-form-group label="Parámetros Seleccionados:">
                                  <div v-for="(id_parametro, index) in filaSeleccionada.id_parametro" :key="index" class="d-flex align-items-center objetos-item mb-3">
                                      <b-input readonly :value="getParametroNombre(id_parametro)" class="mr-2"></b-input>                                    
                                      <b-button variant="danger" @click="eliminarParametro(filaSeleccionada, index)" class="ml-2">
                                          <b-icon-trash-fill></b-icon-trash-fill>
                                      </b-button>
                                  </div>
                              </b-form-group>
                          </b-col>
                      </b-row>
                  
                      <b-alert variant="danger" :show="alertaDuplicado2" dismissible @dismissed="alertaDuplicado2 = false">
                          El parámetro ya se encuentra agregado.
                      </b-alert>
                  
                      <b-alert variant="success" :show="alertaExito2" dismissible @dismissed="alertaExito2 = false">
                          Parámetro agregado con éxito.
                      </b-alert>
                      <!-- //////////////////////////////////////////MODAL-FOOTER////////////////////////////////////////////////////////////////////////////////// -->
                      <template #modal-footer="{ close }">                        
                          <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">
                            Cerrar
                          </b-button>
                      </template>
                  </b-modal> 
                  <b-modal centered id="modal-agregar-observacion-analista" ref="modal_observacion_analista" title="Agregar observacion de asignacion" size="md" @hidden='OnModalCloseObservacion'>

                    <template #modal-header="{ close }">
                        <!-- Emulate built in modal header close button action -->

                        <b-row class="d-flex justify-content-around">
                            <div class="pl-3">Ingrese observación</div>

                        </b-row>

                        <button type="button" class="close" aria-label="Close" @click="close()">
                            <span aria-hidden="true" style="color:white">&times;</span>
                        </button>
                    </template>

                    <template #modal-footer>
                        <b-overlay 
                            class="d-inline-block">
                            <b-button @click="guardarObservacion(analistaSeleccionadoModal)" variant="primary" size="xl" class="float-right reactive-button"
                                style="font-weight:bold">
                                Guardar observación
                            </b-button>
                        </b-overlay>
                    </template>

                    <b-row class="d-flex justify-content-center">
                        <b-col class="col-12">

                            <label for="input-live">Observación:</label>
                           
                                <b-form-textarea id="textarea" v-model="observacion_analista" 
                                    placeholder="Ingrese observación..." rows="3" max-rows="6"></b-form-textarea>
                           
                        </b-col>
                        <br />

                    </b-row>

                    </b-modal>
        </div>
        <!--  </validation-observer> -->
    </div>
  </template>

<style>
.left-aligned-list {
  text-align: left;
}
.left-aligned-list li {
  margin-bottom: 10px; /* Ajusta el margen inferior */
}
</style>

<script>
import MuestraService from '@/helpers/api-services/Muestra.Service';
import modal_cantidadMuestra from '@/components/recepcionMuestra/modal_cantidadMuestra.vue';
import ElementosService from '@/helpers/api-services/Elementos.service';
import PersonalService from '@/helpers/api-services/Personal.service';
import solicitanteService from "@/helpers/api-services/Solicitante.service";

import modal_agregarSolicitante from '@/components/admSolicitante/modal_agregarSolicitante.vue';
import modal_agregarEmpresa from '@/components/admEmpresa/modal_agregarEmpresa.vue';
import m_agregarParametro from '@/components/admParametro/modal_agregarParametro.vue';
import m_agregarMetodologia from '@/components/admElementosMuestra/modal_agregarMetodologia.vue';

import {
    getUserInfo
} from "@/helpers/api-services/Auth.service";
import {
    isLoggedIn
} from "@/helpers/api-services/Auth.service";

export default {

    components: {
        modal_cantidadMuestra,
        //modal_agregarMetodologia,
        //modal_agregarParametro

        m_agregarParametro,
        m_agregarMetodologia,

        modal_agregarSolicitante,
        modal_agregarEmpresa,
    },

    data() {
        return {
            observacionCliente: '',
            nombreSolicitante: '',
            rutSolicitante: '',
            rutFilter:'',
            empresasDelSolicitante: [],
            RUM : '',
            direccionOG : "",       
            rutOG : "",
            recepcionistaOG : "",
            nMuestrasOG : "",
            fechaOG : "",
            horaOG : "",
            fechaEntregaOG : "",
            TipoMatrizOG : "",
            muestreadoOG : "",
            observacionesOG : "",
            prioridadOG : "",
            transportistaRutOG : "",
            transportistaOG : "",                      
            TemperaturaOG : "",
            patenteOG : "",
            telefonos_agregarOG : "",
            idParamOG : "",
            idMetOG : "",
            normaOG : "",
            tablaOG : "",
            nombreMatrizOG : "",
            nombreNormaOG : "",
            nombreTablaOG : "",
            prevP : [],
            prevM : [],
            subMuestra : [],
            tablaArray : [],            
            tablaItems : [],
            empleados: [],
            parametroSeleccionadoIngreso: '',
            parametrosAnalista: [],
            parametroTablaSeleccionado:'',
            metodologiaTablaSeleccionada: '',
            submuestrasOG : [],
            empleadosOG: [],

            recepcionista: '',
            recepcionistaRUT: '',
            nombre_empresa: '',
            empresaSolicitante: '',
            rut: '',
            tipo_pago: '',
            valor_neto: '',
            id_orden_compra: '',
            rut_empresa: '',
            metodologiaSeleccionada: '',
            objetosSeleccionados: [],
            direccion: '',
            direccion_empresa: '',
            muestreado: '',
            cotizacion: '',            
            analistasOpciones: [],
            opcionesCotizacion: [],
            opcionesDireccion: [],
            opcionesMuestreado: [{
                    value: 'UCN-LSA',
                    text: 'UCN-LSA'
                },
                {
                    value: 'Cliente',
                    text: 'Cliente'
                }
            ],
            prioridad: null,
            opcionesPrioridad: [{
                    value: 'Normal',
                    text: 'Normal'
                },                
                {
                    value: 'Urgente',
                    text: 'Urgente'
                }
            ],
            opcionesPago: [{
                    value: 'Efectivo',
                    text: 'Efectivo'
                },
                {
                    value: 'Tarjeta de crédito',
                    text: 'Tarjeta de crédito'
                },
                {
                    value: 'Transferencia bancaria',
                    text: 'Transferencia bancaria'
                },
                {
                    value: 'Cheque',
                    text: 'Cheque'
                },
                {
                    value: 'Otro',
                    text: 'Otro'
                }
            ],
            opcionesOrdenCompra: [],
            TipoMatriz: null,
            opcionesMatriz: [],
            opcionesRecepcionistas: [],
            opcionesClientes: [],
            opcionesEmpresa: [],
            opcionesParametro: [],
            norma: null,
            opcionesNorma: [],
            tabla: '',
            opcionesTabla: [],
            parametroSeleccionado: '',
            ParametrosSeleccionados: [],
            parametros: [],
            alertaDuplicado: false,
            alertaDuplicado2: false,
            alertaExito: false,
            alertaExito2: false,
            alertaExiste: false,
            alertaNOexiste: false,
            transportista: '',
            Temperatura: '',
            transportistaRut: '',
            fono: '',
            fechaEntrega: '',
            observaciones: '',
            nMuestras: null,
            muestras: [],
            fecha: "",
            hora: "",
            patente: "",
            estado: null,
            tabIndex: 0,
            identificacion: [],
            telefonos_agregar: [],
            TodasopcionesParametro: [],
            submuestra_agregar: [],
            userData: '',
            id_ciudad: '',
            dismissSecs: 2,
            dismissCountDown: 0,
            parametrosTablaSeleccionada: [],
            opcionesMetodologia: [],
            metodologiaDeshabilitada: true,
            parametroDeshabilitado: true,
            metodologias: [],
            metodologiasData: [],
            parametros_agregar: [],
            selectedEmpresa: {
                id_ciudad: '',
                nombre_ciudad: '',
                direccion: '',
            },
            id_tabla: '',
            parametros_ya_en_sistema: [],
            parametros_eliminar: [],
            telefonos_eliminar: [],
            submuestra_eliminar: [],
            FechaActual: new Date().toISOString().split('T')[0],
            subEliminar:[],
            analista: [],
            AnalistaAsignado: '',
            tableFields: [
                { key: 'rut_empleado', label: 'Analista' },                
                { key: 'orden_de_analisis', label: 'Orden de análisis'},                
                { key: 'parametro', label: 'Parámetro(s)'},              
                { key: 'fecha_entrega_submuestra', label: 'Fecha Entrega'},
                { key: 'accion', label: 'Acción'} 
            ],
            filaSeleccionada: null,
            parametrosOptions: [],
            empleados_eliminar: [],
            opcionesMetodologiaTabla: [],
            met:[],
            tablaE : [],
            EmpleadosArray: [],
            empleados_agregar : [],
            nuevaobs: [],
            empresasDirecciones: [],
            datosCargados: false,
            empleados_objeto: [],
            recepcionista_incompleto: true,
            muestra_incompleto: true,
            transportista_incompleto: true,
            parametros_incompleto: true,
            revisado: false,
            id_submuestra: [],
            parametros_metodologias: [],
            empleados_originales: [],
            emp: [],
            diferentes: [],
            empleadosNuevos: [],
            sub : [],
            empleadosPorAsignar: [],
            empleadosDesagrupados: [],
            parametro_submuestra_eliminar: [],
            hora_recepcion: '',
            fecha_recepcion: '',
            totalTabs: 6,
            submuestra_editar: [],
            parametro_submuestra_agregar: [],

            isCheckboxCheckedTemp: false,
            isCheckboxCheckedMatriz: false,
            normaDisabled: false,
            opcionesIniciales: [ /* Aquí guardaremos las opciones iniciales */ ],
            solicitanteIngresado: false,
            tieneEmpresa: true,
            tieneCotizaciones: false,
            opcionesParametrosSeleccionados: [],
            cantSubmuestrasIniciales:0,
            actualizarModal: 0,
            parametrosPorAsignar: [],
            analistasSeleccionados: [],
            analistaSeleccionadoModal: null,
            observacion_analista: '',
            alertaObservacionAnalista: false,
            empresaSeleccionada: null,
            telefono_transportista: '',

        };
    },

    async created() {
        if (isLoggedIn()) {
            this.userData = getUserInfo();
        }
        this.parametros_ya_en_sistema = [];
        this.objetosSeleccionados = [];
        this.parametros_eliminar = [];
        this.RUM = this.$route.query.RUM;
        await MuestraService.obtenerDatosMuestra(this.RUM).then((response)=>{
            this.datos = response.data;
            this.RellenarForm(response.data);        
            
            const parametros = response.data.parametros_metodologias;

            for (var i = 0; i < parametros.length; i++) {
                this.parametros_ya_en_sistema.push({
                    id_parametro: parametros[i].id_parametro,
                    id_metodologia: parametros[i].id_metodologia,
                    nombre_parametro: parametros[i].nombre_parametro,
                    nombre_metodologia: parametros[i].nombre_metodologia,
                });
            }
                    
            for (var v = 0; v < this.parametros_ya_en_sistema.length; v++) {
                this.objetosSeleccionados.push({
                    parametro: this.parametros_ya_en_sistema[v].nombre_parametro,
                    metodologia: this.parametros_ya_en_sistema[v].nombre_metodologia,
                    id_parametro: this.parametros_ya_en_sistema[v].id_parametro,
                    id_metodologia: this.parametros_ya_en_sistema[v].id_metodologia
                })
            }
            this.obtenerTablasNormas();

        }).catch(error => {
            console.error(error);
        });
        
        await this.obtenerParametro();       

        await this.obtenerMatriz(), 
        
        await this.obtenerNormas(), 
        
        await this.obtenerEmpresas(),       

        await PersonalService.obtenerTodosPersonal().then((response) => {
            if (response.data != null) {
                this.analistas = response.data;
                this.rutEmpleados = this.analistas.map((analista) => analista.rut_empleado);
                this.analistasOpciones = this.analistas.filter((analista) => analista.rol === "Analista Químico" || analista.rol === "Químico").map((analista) => ({
                    rut_empleado: analista.rut_empleado,
                    nombre_analista: analista.nombre + ' ' + analista.apellido,
                    
                }));
                this.analista = response.data;
                this.recepcionista = this.analista.find((analista) => analista.rut_empleado === this.recepcionistaRUT);

                this.recepcionista = `${this.recepcionista.nombre} ${this.recepcionista.apellido}`;
                        
            }
        })   

    },
    


    async mounted() {        
        this.$root.$on('refrescar', () => {
            this.obtenerParametro();
        });   
             
    },

    watch: {

        parametroSeleccionado: function (newParametro) {
            if (newParametro) {
                this.actualizarMetodologias();
                if (this.parametroSeleccionado) {
                  const parametroData = this.metodologiasData.find(item => item.nombre_parametro === this.parametroSeleccionado);
                  this.opcionesMetodologiaTabla = parametroData.metodologias.map(metodologia => metodologia.nombre_metodologia);
                } else {
                  this.opcionesMetodologiaTabla = [];
                }
                this.metodologiaDeshabilitada = false; // Habilita el input 
            } else {
                this.metodologiaDeshabilitada = true;
            }
        },

        parametroTablaSeleccionado: function (newParametro){
            if(newParametro){
                this.actualizarMetodologiasTabla();              
                this.metodologiaDeshabilitada = false; // Habilita el input 
            } else {
                this.metodologiaDeshabilitada = true;            
            }
        },

        tabla: function (nuevoValor) {
            const tablaSeleccionada = this.tablasProcesadas.find(tabla => tabla.nombre_tabla === nuevoValor);
            if (tablaSeleccionada) {
                const idTabla = tablaSeleccionada.id_tablas[0]; // Suponiendo que solo haya una id_tabla por cada nombre_tabla
                this.id_tabla = idTabla
                this.parametroDeshabilitado = false;
            } else {
                this.parametroDeshabilitado = true;
            }
        },

        direccion(newValue) {
            const direccionSeleccionada = this.opcionesDireccion.find(opcion => opcion.id_ciudad === newValue);
            if (direccionSeleccionada) {
                const direccion = direccionSeleccionada.direccion;
                // Realiza cualquier otra acción necesaria con la dirección seleccionada
                this.direccion_empresa = direccion;
            } else {
                console.log("No se encontró la dirección seleccionada.");
            }
        },
        empleadosPorAsignar: {
            handler(newVal) {
                // Actualizar el estado de analistasSeleccionados cada vez que cambia filas
                this.analistasSeleccionados = newVal.filter(fila => fila.rut_empleado !== null).map(fila => fila.rut_empleado);
            },
            deep: true,
        },
    },

    methods: {

        obtenerArchivos(){
        solicitanteService.obtenerDetallesCompletosSolicitante(this.userData.rut_solicitante).then((response)=>{
            if(response.request.status == 200){
                this.Cotizaciones = response.data.cotizaciones;

            }
        })},
        guardarObservacion(analistaSeleccionado){
            analistaSeleccionado.observacion_analista = this.observacion_analista;
            this.alertaObservacionAnalista = true;
            this.$refs.modal_observacion_analista.hide();

        },
        OnModalCloseObservacion(){
            this.analistaSeleccionadoModal = null;
            this.observacion_analista = '';
        },
        limpiarDatosIdentificacion() {
            this.nombreSolicitante = '';
            this.rutSolicitante = '';
            this.direccion = '';
            this.empresaSolicitante = '';
            this.opcionesDireccion  = [];
            this.empresasDelSolicitante = this.opcionesEmpresa;
            this.nombre_empresa = '';
            this.tieneEmpresa = true;
            this.solicitanteIngresado = false;
            this.rutFilter = '';
            this.opcionesCotizacion = [];
            this.cotizacion = '';
            this.tieneCotizaciones = false;
            this.empresaSeleccionada = false;

        },
        getOpcionesDisponibles(filaIndex) {
            // Filtrar las opciones disponibles excluyendo los analistas ya seleccionados
            return this.analistasOpciones.filter(opcion => !this.analistasSeleccionados.includes(opcion.rut_empleado) || opcion.rut_empleado === this.empleadosPorAsignar[filaIndex].rut_empleado);
        },
        obtenerDatosSolicitante() {
            

            solicitanteService.obtenerDetallesSolicitante(this.rutFilter).then((response) => {
                
                try{
                    if (response.request.status == 200 && this.rutFilter !== "") {
                    this.solicitanteIngresado = true;
                    this.empresaSeleccionada = false;
                    this.opcionesDireccion  = [];
                    this.direccion = '';
                    this.empresaSolicitante = '';
                    this.nombre_empresa = '';
                    //obten nombre y apellido
                    this.nombreSolicitante = `${response.data.nombre} ${response.data.primer_apellido} ${response.data.segundo_apellido}`;
                    this.rutSolicitante = response.data.rut_solicitante; 
                    this.telefono_solicitante = response.data.telefono;
                    this.correo_solicitante = response.data.correo;

                    if(response.data.detalles_empresas.length > 0){
                        this.tieneEmpresa = true;
                    }else{
                        this.tieneEmpresa = false;
                        ///
                    }
                    const empresas = response.data.detalles_empresas;
                    this.empresasDelSolicitante = empresas;

                    
                    if(response.data.tipo_cliente === 'Particular'){
                        this.empresaSolicitante = 'Particular';
                        this.nombre_empresa = 'Particular';
                        this.rut_empresa =null;
                        this.empresasDelSolicitante = [{nombre_empresa: 'Particular',rut_empresa:'Particular'}];
                    }
                    const data = { rut_solicitante: this.rutSolicitante };
                    solicitanteService.CotizacionSolicitante(data).then((response) => {
                        if (response.data != null && response.status === 200) {
                            this.opcionesCotizacion = response.data.map(cotizacion => ({
                                id_cotizacion: cotizacion.id_cotizacion,
                                nombre_original_documento: cotizacion.nombre_original_documento,
                                idconNombre: `${cotizacion.id_cotizacion} - ${cotizacion.nombre_original_documento}`
                            }))
                            if(this.opcionesCotizacion.length>0){
                                this.tieneCotizaciones = true;
                            }else{
                                this.tieneCotizaciones = false;
                            }
                        }
                    })

                }

                }catch{
                    this.showAlert();
                }
                
                
            
            })
        },
        matrizRules() {
            return this.isMatrizRequired ? 'required' : '';
        },
        showAlert() {
            this.dismissCountDown = 5; // Ajusta el valor según el tiempo que quieras que dure la alerta
        },
        clearInput() {
            if (this.isCheckboxCheckedTemp) {
                this.Temperatura = ''; // Borramos el contenido cuando el interruptor se activa
            }
        },
        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
        

       

        obtenerNombreParametro(idParametro) {
          const parametro = this.metodologiasData.find(item => item.id_parametro === idParametro);
          return parametro ? parametro.nombre_parametro : '';
        },

        getParametroNombre(id_parametro) {
            const parametro = this.parametrosOptions.find(param => param.id_parametro === id_parametro);
            return parametro ? parametro.nombre_parametro : '';
        },

        obtenerNombreMetodologia(idMetodologia) {
          for (const parametro of this.metodologiasData) {
            const metodologia = parametro.metodologias.find(item => item.id_metodologia === idMetodologia);
            if (metodologia) {
              return metodologia.nombre_metodologia;
            }
          }
          return '';
        }, 
        
        obtenerEmpresas () {
            MuestraService.obtenerEmpresas().then((response) => {
                const empresas = response.data;
                this.opcionesEmpresa = empresas;
            })
        },

        goToPreviousTab() {
            if (this.tabIndex > 0) {
                this.tabIndex--;
            }
        },

        goToNextTab() {
            if (this.tabIndex < this.totalTabs - 1) {
                this.tabIndex++;
            }
        },

        shouldShowNextButton() {
            return this.tabIndex < this.totalTabs - 1;
        },

        onModalShown() {
            this.alertaExito = false;
            this.alertaDuplicado = false;
        },

        agregarFila() {
            this.empleadosPorAsignar.push({ 
                RUM: this.RUM,
                estado: 'Sin iniciar',                
                orden_de_analisis: null,
                fecha_entrega: null,
                id_parametro: [],
                parametro: [],
                rut_empleado: null,
                observacion_analista: ''
            });
        },

        eliminarFila(fila) {
          const index = this.empleadosPorAsignar.indexOf(fila);
          if (index !== -1) {
            if (this.empleadosPorAsignar[index].parametro.length === 0) {
              this.empleadosPorAsignar.splice(index, 1);
            } else {
              for(var i = 0; i < this.empleadosPorAsignar[index].parametro.length; i++){
                this.parametrosPorAsignar.push({id_parametro: this.empleadosPorAsignar[index].id_parametro[i], parametro: this.empleadosPorAsignar[index].parametro[i]})
              }
              this.empleadosPorAsignar.splice(index, 1);
            }
          }
        },

        // Función para verificar si dos objetos son iguales
        sonObjetosIguales(objeto1, objeto2) {
            const keys1 = Object.keys(objeto1);
            const keys2 = Object.keys(objeto2);
            
            if (keys1.length !== keys2.length) {
              return false;
            }
          
            for (const key of keys1) {
              if (objeto1[key] !== objeto2[key]) {
                return false;
              }
            }
          
            return true;
        },
        verificarOrdenAnalisis(analistas) {
            // Extraemos los valores de "Orden de análisis" en un array
            const ordenes = analistas.map(analista => analista.orden_de_analisis);

            // Filtramos para eliminar duplicados y ordenamos los valores numéricos
            const ordenesUnicos = [...new Set(ordenes)].sort((a, b) => a - b);

            // Verificamos si los valores únicos son consecutivos comenzando desde 1
            for (let i = 0; i < ordenesUnicos.length; i++) {
                if (parseInt(ordenesUnicos[i]) !== i + 1) {
                return false;
                }
            }
            return true;
        },
        agregarSubmuestra (orden) {
            const PYMArray = this.objetosSeleccionados;
            const nuevaFila = {
                id_submuestra: '',       
                orden: orden + 1,
                identificador: '',
                PYM: [...PYMArray]
            };
            this.sub.push(nuevaFila);
            const nuevaFilaEnBD = {      
                orden: orden + 1,
                identificador: '',
                parametros_agregar: [...PYMArray]
            };
            this.submuestra_agregar.push(nuevaFilaEnBD);
            this.nMuestras++;
            this.nMuestras = this.nMuestras.toString();

        },

        eliminarSubmuestra(filaSeleccionada, index) {  

          const nuevoSub = [...this.sub];
                
          const submuestraEliminadaId = filaSeleccionada.id_submuestra;          
          if(submuestraEliminadaId != ''){
            this.submuestra_eliminar.push({ id_submuestra: submuestraEliminadaId });
            this.parametro_submuestra_agregar = this.parametro_submuestra_agregar.filter(submuestra => submuestra.id_submuestra != submuestraEliminadaId);
            this.parametro_submuestra_eliminar= this.parametro_submuestra_eliminar.filter(submuestra => submuestra.id_submuestra != submuestraEliminadaId);
            this.submuestra_editar = this.submuestra_editar.filter(submuestra => submuestra.id_submuestra != submuestraEliminadaId);
          }else{
            const indexSubmuestraEliminar = this.submuestra_agregar.findIndex(objeto => objeto.orden === filaSeleccionada.orden);
            for(var j = indexSubmuestraEliminar; j < this.submuestra_agregar.length -1;j++){
                this.submuestra_agregar[j+1].orden = this.submuestra_agregar[j+1].orden-1;
            }
            this.submuestra_agregar.splice(indexSubmuestraEliminar,1);
          }   
          
          nuevoSub.splice(index, 1);
          this.sub = nuevoSub;
          for(var i = index; i < this.sub.length; i++){
            this.sub[i].orden = this.sub[i].orden-1;

          }      
          this.nMuestras--;
          this.nMuestras = this.nMuestras.toString();
        },

        
        eliminarParametro(filaSeleccionada, index) {
            this.parametrosPorAsignar.push({ id_parametro: filaSeleccionada.id_parametro[index], parametro: filaSeleccionada.parametro[index]});
            filaSeleccionada.id_parametro.splice(index, 1); 
            filaSeleccionada.parametro.splice(index, 1);
        },
        eliminarParametroSubmuestraAgregar(index) {
            this.parametro_submuestra_agregar.splice(index,1);
        },
        eliminarParametroSubmuestraEliminar(index) {
            this.parametro_submuestra_eliminar.splice(index,1);
        },
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        agregarParametro(filaSeleccionada) {
            
            // Obtener el ID del parámetro seleccionado
            const parametroId = this.parametroSeleccionadoIngreso;
            if(parametroId === undefined){
                return;
            }
            // Buscar el parámetro en la lista de opciones
            const parametroSeleccionado = this.parametrosPorAsignar.find(param => param.id_parametro === parametroId);
            // Almacenar información anterior en variables auxiliares
            if (!(Array.isArray(filaSeleccionada.id_parametro) && Array.isArray(filaSeleccionada.parametro))) {
                filaSeleccionada.id_parametro = [filaSeleccionada.id_parametro];
                filaSeleccionada.parametro = [filaSeleccionada.parametro];
            }                  

            if (filaSeleccionada.id_parametro.includes(parametroSeleccionado.id_parametro) &&
              filaSeleccionada.nombre_parametro.includes(parametroSeleccionado.parametro)
            ) {
              this.alertaDuplicado2 = true;
              this.alertaExito2 = false;
              this.parametroSeleccionadoIngreso = '';
            } else {
              // Agregar el nuevo valor a los arrays existentes
              filaSeleccionada.id_parametro.push(parametroSeleccionado.id_parametro);
              filaSeleccionada.parametro.push(parametroSeleccionado.parametro);
              this.alertaExito2 = true;
              this.alertaDuplicado2 = false;
              this.parametrosPorAsignar = this.parametrosPorAsignar.filter(objeto => !(objeto.parametro === parametroSeleccionado.parametro && objeto.id_parametro === parametroSeleccionado.id_parametro))
              this.parametroSeleccionadoIngreso = '';
            }

            filaSeleccionada.id_parametro = filaSeleccionada.id_parametro.filter(Boolean);
            filaSeleccionada.parametro = filaSeleccionada.parametro.filter(Boolean);
        
        },

        obs(){
            if (this.observacionesOG.length === 0) {
                this.nuevaobs = [{
                  fecha_observacion: '',
                  hora_observacion: '',
                  observaciones: this.observaciones,
                }];                
            }   
            else{
                const fechaObs = this.observacionesOG[0].fecha_observacion;
                const horaObs = this.observacionesOG[0].hora_observacion;
                const observaciones = [{
                    fecha_observacion: fechaObs,
                    hora_observacion: horaObs,  
                    observaciones: this.observaciones,
                }]
                if (observaciones === this.observacionesOG){
                    this.nuevaobs = []
                }else{
                    this.nuevaobs = observaciones;
                }              
            }
        },            

        abrirParam(row) {        
            this.filaSeleccionada = row;
            this.$bvModal.show('modal-Agregar-Parametros');
        },
        abrirObservacionesAnalista(row) {        
            this.analistaSeleccionadoModal = row;
            this.observacion_analista = this.analistaSeleccionadoModal.observacion_analista;
            this.$bvModal.show('modal-agregar-observacion-analista');
        },

        actualizarSelectedEmpresa() {

            if(this.empresaSolicitante !== '' && this.empresaSolicitante !== undefined && this.empresaSolicitante !== 'Particular'  && this.empresaSolicitante !== null){
                this.empresaSeleccionada = true;
                const nombreEmpresa = this.opcionesEmpresa.find(objeto => objeto.rut_empresa === this.empresaSolicitante);
                let nombreEmpresaEncontrada = "";
                if (nombreEmpresa) {
                nombreEmpresaEncontrada = nombreEmpresa.nombre_empresa;
                this.nombre_empresa = nombreEmpresaEncontrada;
                }
                MuestraService.obtenerEmpresas().then((response) => {
                    const empresas = response.data;
                    const empresaSelec = empresas.find(empresa => empresa.nombre_empresa === this.nombre_empresa);
                    const direccion = empresaSelec.ciudades_direcciones;

                    const direccionCiudad = direccion.map(c => c.direccion);
                    this.opcionesDireccion = direccion.map(opc => ({
                        id_ciudad: opc.id_ciudad,
                        nombre_ciudad: opc.nombre_ciudad,
                        direccion: opc.direccion,
                        direccionConCiudad: `${opc.nombre_ciudad} / ${opc.direccion}`
                    }))
                    this.direccion_empresa = direccionCiudad.toString();
                });
            }


        },
        agregar() {
            this.alertaExito = false;
            this.alertaDuplicado = false;
            this.actualizarModal++;
            this.$bvModal.show('modal-cantidad');
        },

        agregarObjetosSeleccionados() {
            if (this.parametroSeleccionado && this.metodologiaSeleccionada) {
                const ParamExistente = this.objetosSeleccionados.find(objeto => objeto.parametro === this.parametroSeleccionado);
                const MetExistente = this.objetosSeleccionados.find(objeto => objeto.metodologia === this.metodologiaSeleccionada);
                
                if (ParamExistente && MetExistente) {
                    this.alertaDuplicado = true;
                    this.parametroSeleccionado = '';
                    this.metodologiaSeleccionada = '';
                    this.alertaExito = false;
                } else {
                    const parametroData = this.metodologiasData.find(item => item.nombre_parametro === this.parametroSeleccionado);
                    const metodologiaCompleta = parametroData.metodologias.find(metodologia => metodologia.nombre_metodologia === this.metodologiaSeleccionada);
                    this.objetosSeleccionados.push({
                        id_parametro: parametroData.id_parametro,
                        parametro: this.parametroSeleccionado,
                        metodologia: metodologiaCompleta.nombre_metodologia,
                        id_metodologia: metodologiaCompleta.id_metodologia
                    });
                    this.parametros_agregar.push({
                        id_parametro: parametroData.id_parametro,
                        id_metodologia: metodologiaCompleta.id_metodologia,
                    });                     

                      // En caso de agregar un parametro que no está registrado en la BD
                    const parametroAntiguo = this.parametros_ya_en_sistema.find(param => param.nombre_metodologia == this.metodologiaSeleccionada.nombre_metodologia &&
                    param.nombre_parametro == this.parametroSeleccionado.nombre_parametro);
                    if (parametroAntiguo == null) {
                        if (this.parametroSeleccionado.id_parametro && this.metodologiaSeleccionada.id_metodologia) {
                            this.parametros_agregar.push({
                                id_parametro: this.parametroSeleccionado.id_parametro,
                                id_metodologia: this.metodologiaSeleccionada.id_metodologia,
                            });                                
                        }
                    } else {
                        this.parametros_eliminar = this.parametros_eliminar.filter(param => param.id_metodologia !== this.metodologiaSeleccionada.id_metodologia &&
                        param.id_parametro !== this.parametroSeleccionado.id_parametro &&
                        typeof param.id_metodologia !== 'undefined' &&
                        typeof param.id_parametro !== 'undefined');
                    }
                    this.parametroSeleccionado = '';
                    this.metodologiaSeleccionada = '';
                    this.alertaDuplicado = false;
                    this.alertaExito = true;
                }
            }
        },

        agregarObjetosTablaSeleccionados() {
            if (this.parametroTablaSeleccionado && this.metodologiaTablaSeleccionada) {
                const ParamExistente = this.objetosSeleccionados.find(objeto => objeto.parametro === this.parametroTablaSeleccionado);
                const MetExistente = this.objetosSeleccionados.find(objeto => objeto.metodologia === this.metodologiaTablaSeleccionada);
                if (ParamExistente && MetExistente) {
                    this.alertaDuplicado = true;
                    this.parametroSeleccionado = '';
                    this.metodologiaSeleccionada = '';
                    this.alertaExito = false;
                } else {
                    const parametroData = this.metodologiasData.find(item => item.nombre_parametro === this.parametroTablaSeleccionado);
                    const metodologiaCompleta = parametroData.metodologias.find(metodologia => metodologia.nombre_metodologia === this.metodologiaTablaSeleccionada);
                    this.objetosSeleccionados.push({
                        id_parametro: parametroData.id_parametro,
                        parametro: this.parametroTablaSeleccionado,
                        metodologia: metodologiaCompleta.nombre_metodologia,
                        id_metodologia: metodologiaCompleta.id_metodologia
                    });
                    if(this.parametros_eliminar.find(objeto => objeto.id_parametro === parametroData.id_parametro && objeto.id_metodologia === metodologiaCompleta.id_metodologia)){
                        this.parametros_eliminar = this.parametros_eliminar.filter(param => !(param.id_metodologia === metodologiaCompleta.id_metodologia &&
                            param.id_parametro === parametroData.id_parametro));
                        this.parametro_submuestra_eliminar = this.parametro_submuestra_eliminar.filter(param => !(param.id_metodologia === metodologiaCompleta.id_metodologia &&
                            param.id_parametro === parametroData.id_parametro));
                        if(!this.submuestrasOG.find(objeto => objeto.PYM.find(parametro => parametro.id_metodologia === metodologiaCompleta.id_metodologia && parametro.id_parametro === parametroData.id_parametro))){
                            let submuestras_agregar_yaExistentesEnBD = this.sub.filter(objeto => objeto.id_submuestra != '')
                            submuestras_agregar_yaExistentesEnBD.forEach(submuestra => {
                                this.parametro_submuestra_agregar.push({
                                    id_submuestra: submuestra.id_submuestra,
                                    id_parametro: parametroData.id_parametro,
                                    id_metodologia: metodologiaCompleta.id_metodologia,
                                }) 
                            });
                        }
                    }else{
                        this.parametros_agregar.push({
                            id_parametro: parametroData.id_parametro,
                            id_metodologia: metodologiaCompleta.id_metodologia,
                        });
                        if(!this.submuestrasOG.find(objeto => objeto.PYM.find(parametro => parametro.id_metodologia === metodologiaCompleta.id_metodologia && parametro.id_parametro === parametroData.id_parametro))){
                            let submuestras_agregar_yaExistentesEnBD = this.sub.filter(objeto => objeto.id_submuestra != '')
                            submuestras_agregar_yaExistentesEnBD.forEach(submuestra => {
                                this.parametro_submuestra_agregar.push({
                                    id_submuestra: submuestra.id_submuestra,
                                    id_parametro: parametroData.id_parametro,
                                    id_metodologia: metodologiaCompleta.id_metodologia,
                                }) 
                            });
                        }
                    }
                    if(this.opcionesParametrosSeleccionados.find(objeto => objeto.parametro === this.parametroTablaSeleccionado)){
                        const index = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === this.parametroTablaSeleccionado)
                        this.opcionesParametrosSeleccionados[index].metodologias.push({
                            metodologia: metodologiaCompleta.nombre_metodologia,
                            id_metodologia: metodologiaCompleta.id_metodologia,
                        })
                    }else{
                        this.opcionesParametrosSeleccionados.push({
                            id_parametro: parametroData.id_parametro,
                            parametro: this.parametroTablaSeleccionado,
                            metodologias: [{metodologia: metodologiaCompleta.nombre_metodologia,id_metodologia: metodologiaCompleta.id_metodologia,}],
                        })
                        this.parametrosPorAsignar.push({ id_parametro: parametroData.id_parametro, parametro: this.parametroTablaSeleccionado})
                    }
                    this.sub.forEach(submuestra => {
                        submuestra.PYM.push({
                            id_parametro: parametroData.id_parametro,
                            parametro: this.parametroTablaSeleccionado,
                            metodologia: metodologiaCompleta.nombre_metodologia,
                            id_metodologia: metodologiaCompleta.id_metodologia
                        })
                    })
                    for (let i = 1; i <= this.submuestra_agregar.length; i++) {
                        if(this.submuestra_agregar[i-1].parametros_agregar.length > 0){
                            if(this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.parametro === this.parametroTablaSeleccionado &&
                            this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.metodologia === this.metodologiaTablaSeleccionada))){
                                continue;
                            }else{
                                this.submuestra_agregar[i-1].parametros_agregar.push({
                                id_parametro: parametroData.id_parametro,
                                parametro: this.parametroTablaSeleccionado,
                                metodologia: metodologiaCompleta.nombre_metodologia,
                                id_metodologia: metodologiaCompleta.id_metodologia
                                });
                            }

                        }else{
                            this.submuestra_agregar[i-1].parametros_agregar.push({
                             id_parametro: parametroData.id_parametro,
                             parametro: this.parametroTablaSeleccionado,
                             metodologia: metodologiaCompleta.nombre_metodologia,
                             id_metodologia: metodologiaCompleta.id_metodologia
                            });
                        }
                    }
                    
                    this.parametroTablaSeleccionado = '';
                    this.metodologiaTablaSeleccionada = '';
                    this.alertaDuplicado = false;
                    this.alertaExito = true;
                }
            }
        },

        obtenerParametro() {
            ElementosService.obtenerParametros().then((response) => {
                if (response.data != null && response.status === 200) {

                    // Almacenar los datos en metodologiasData
                    this.metodologiasData = response.data.map(item => ({
                        id_parametro: item.id_parametro,
                        nombre_parametro: item.nombre_parametro,
                        metodologias: item.metodologias.map(metodologia => ({
                            id_metodologia: metodologia.id_metodologia,
                            nombre_metodologia: metodologia.nombre_metodologia
                        }))
                    }));
                    this.opcionesParametro = response.data.map(item => item.nombre_parametro);
                    this.TodasopcionesParametro = response.data.map(item => item.nombre_parametro);
                    this.parametrosOptions = response.data.map(parametro => ({
                        id_parametro: parametro.id_parametro,
                        nombre_parametro: parametro.nombre_parametro
                    }))
                    // Buscar nombres de parámetro en base a los IDs
                    const nombresParametro = this.idParamOG.map((id) => {
                      const parametro = response.data.find((item) => item.id_parametro === id);
                      return parametro ? parametro.nombre_parametro : null;
                    });
                    this.prevP = nombresParametro

                    // Buscar nombres de metodología en base a los IDs
                    const nombresMetodologia = this.idMetOG.map((id) => {
                      const metodologia = response.data.find((item) => item.metodologias.some((m) => m.id_metodologia === id));
                      return metodologia ? metodologia.metodologias.find((m) => m.id_metodologia === id).nombre_metodologia : null;
                    });
                    this.prevM = nombresMetodologia

                    const nuevaVariable = JSON.parse(JSON.stringify(this.empleados));
                    nuevaVariable.forEach(objeto => {
                      objeto.nombre_parametro = [objeto.nombre_parametro];
                      objeto.id_parametro = [objeto.id_parametro];
                      this.EmpleadosArray.push(objeto);                      
                    });
                }
            });
        },

        eliminarObjetosTablaSeleccionados(index) {

            const yaEnSistema = this.parametros_ya_en_sistema.find(param => param.nombre_metodologia == this.objetosSeleccionados[index].metodologia &&
                param.nombre_parametro == this.objetosSeleccionados[index].parametro);
            if (yaEnSistema != null) {
                this.parametros_eliminar.push({
                    id_parametro: yaEnSistema.id_parametro,
                    id_metodologia: yaEnSistema.id_metodologia
                })
              
            } else {
                this.parametros_agregar = this.parametros_agregar.filter(param => param.id_metodologia != this.objetosSeleccionados[index].id_metodologia &&
                    param.id_parametro != this.objetosSeleccionados[index].id_parametro)
            }
            for(var i = 0; i < this.sub.length ;i++){
                for(var j = 0; j < this.sub[i].PYM.length; j++){
                    if(this.sub[i].PYM[j].id_parametro === this.objetosSeleccionados[index].id_parametro && this.sub[i].PYM[j].id_metodologia === this.objetosSeleccionados[index].id_metodologia){
                        if(this.sub[i].id_submuestra != ''){
                            if(this.parametro_submuestra_agregar.find(objeto => objeto.id_submuestra === this.sub[i].id_submuestra
                            && objeto.id_parametro === this.objetosSeleccionados[index].id_parametro && objeto.id_metodologia === this.objetosSeleccionados[index].id_metodologia)){
                                const index_borrar = this.parametro_submuestra_agregar.findIndex(objeto => objeto.id_submuestra === this.sub[i].id_submuestra
                                 && objeto.id_parametro === this.objetosSeleccionados[index].id_parametro && objeto.id_metodologia === this.objetosSeleccionados[index].id_metodologia);
                                 this.parametro_submuestra_agregar.splice(index_borrar,1);
                            }else{
                                const eliminarParametro = { 
                                id_submuestra:this.sub[i].id_submuestra,
                                id_parametro:this.objetosSeleccionados[index].id_parametro, 
                                id_metodologia:this.objetosSeleccionados[index].id_metodologia};
                                this.parametro_submuestra_eliminar.push(eliminarParametro);
                            }
                            this.sub[i].PYM.splice(j,1);
                        }else{
                            const submuestra_agregar_quitar_parametro = this.submuestra_agregar.find(submuestra => submuestra.orden === this.sub[i].orden);
                            const index_parametro = submuestra_agregar_quitar_parametro.parametros_agregar.findIndex(param => param.id_metodologia === this.objetosSeleccionados[index].id_metodologia && param.id_parametro === this.objetosSeleccionados[index].id_parametro)
                            submuestra_agregar_quitar_parametro.parametros_agregar.splice(index_parametro,1);
                            
                        }

                    }
                }
                
            }
            const indexParametro = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === this.objetosSeleccionados[index].parametro);
            const indexMetodologia = this.opcionesParametrosSeleccionados[indexParametro].metodologias.findIndex(objeto => objeto.metodologia === this.objetosSeleccionados[index].metodologia);
            this.opcionesParametrosSeleccionados[indexParametro].metodologias.splice(indexMetodologia,1);
            if(this.opcionesParametrosSeleccionados[indexParametro].metodologias.length === 0) {
                this.opcionesParametrosSeleccionados.splice(indexParametro,1);
                this.parametrosPorAsignar = this.parametrosPorAsignar.filter(objeto => !(objeto.parametro === this.objetosSeleccionados[index].parametro && objeto.id_parametro ===this.objetosSeleccionados[index].id_parametro))
                for(var z = 0; z < this.empleadosPorAsignar.length; z++){
                    if(this.empleadosPorAsignar[z].parametro.length > 0){
                        this.empleadosPorAsignar[z].parametro = this.empleadosPorAsignar[z].parametro.filter(objeto => !(objeto === this.objetosSeleccionados[index].parametro));
                        this.empleadosPorAsignar[z].id_parametro = this.empleadosPorAsignar[z].id_parametro.filter(objeto => !(objeto === this.objetosSeleccionados[index].id_parametro));
                    }
                }
            }
            this.objetosSeleccionados.splice(index, 1);
            
        },

        actualizarMetodologias() {
            const parametro = this.parametroSeleccionado;

            // Buscar el objeto correspondiente al parámetro seleccionado en metodologiasData
            const parametroData = this.metodologiasData.find(item => item.nombre_parametro === parametro);
            this.metodologias = parametroData.metodologias
            this.opcionesMetodologia = this.metodologias.map(item => item.nombre_metodologia);

        },       

        identificadores(datos) {

            const identificacionEliminada = datos.map(objeto => objeto);

            const subEliminarAntes = this.submuestra_eliminar.length - 1;

            identificacionEliminada.forEach(id_submuestra => {
                // Verificar si la submuestra ya existe en submuestra_eliminar
                const existe = this.submuestra_eliminar.some(submuestra => submuestra.id_submuestra === id_submuestra);
                if (!existe) {
                  this.submuestra_eliminar.push({ id_submuestra: id_submuestra });
                }
            });
        
            const subEliminar = this.submuestra_eliminar.slice(1);
            this.subEliminar = subEliminar
        
        
            const subEliminarDespues = subEliminar.length;
        
            if (subEliminarDespues > subEliminarAntes) {
              const cantidadCambios = subEliminarDespues - subEliminarAntes;
              this.nMuestras -= cantidadCambios;
              this.nMuestras = this.nMuestras.toString();
            }
        
        },

        capturarDatos(datos) {  

            this.submuestra_agregar = [];    
            this.submuestra_editar = []; 
            this.sub = [];
            for(var i = 0; i < datos.length; i++){
                if(datos[i].id_submuestra != ''){
                    for(var z = 0; z < this.submuestrasOG.length; z++){
                        if(datos[i].id_submuestra === this.submuestrasOG[z].id_submuestra){
                            if(datos[i].identificador !== this.submuestrasOG[z].identificador  || datos[i].orden !== this.submuestrasOG[z].orden){
                                this.submuestra_editar.push({
                                    id_submuestra: datos[i].id_submuestra,
                                    identificador: datos[i].identificador,
                                    orden: datos[i].orden,
                                })
                                
                            }
                        }
                    }
                    
                }else{
                    this.submuestra_agregar.push({
                        orden: datos[i].orden,
                        identificador: datos[i].identificador,
                        parametros_agregar: datos[i].parametros_agregar,
                    })
                }
                
                this.sub.push({
                    orden: datos[i].orden,
                    identificador: datos[i].identificador,
                    id_submuestra: datos[i].id_submuestra,        
                    PYM: datos[i].parametros_agregar,
                });
                
            }

            
        },

        SubEliminadas(datos){
            datos.forEach(objeto => {
                this.parametro_submuestra_eliminar.push({
                    id_submuestra: objeto.id_submuestra,
                    id_parametro: objeto.id_parametro,
                    id_metodologia: objeto.id_metodologia,
                })
            })
        },

        agregarParametroSubmuestra(datos){
            datos.forEach(objeto => {
                this.parametro_submuestra_agregar.push({
                    id_submuestra: objeto.id_submuestra,
                    id_parametro: objeto.id_parametro,
                    id_metodologia: objeto.id_metodologia,
                })
            })
        },
        onModalClose() {
            this.alertaDuplicado2 = false;
            this.alertaExito2 = false;
            
        },
        async obtenerMatriz() {
            await ElementosService.obtenerMatriz().then((response) => {
                if (response.data != null && response.status === 200) {
                    // Mapear los datos de las matrices a las opciones del select
                    this.opcionesMatriz = response.data.map(matriz => ({
                        id_matriz: matriz.id_matriz,
                        nombre_matriz: matriz.nombre_matriz
                    }));
                    const matrizSeleccionada = this.opcionesMatriz.find(matriz => matriz.id_matriz === this.TipoMatrizOG);
                    if (matrizSeleccionada) {
                        this.nombreMatrizOG = matrizSeleccionada.nombre_matriz;
                    }
                }
            });
        },

        obtenerNormas() {            
            ElementosService.obtenerNormas().then((response) => {
                if (response.data != null && response.status === 200) {
                    const normas = response.data.map(norma => ({
                        id_norma: norma.id_norma,
                        nombre_norma: norma.nombre_norma
                    }));
                    this.opcionesNorma = normas;

                    const normaSeleccionada = this.opcionesNorma.find(norma => norma.id_norma === this.normaOG);
                    if (normaSeleccionada) {
                        this.nombreNormaOG = normaSeleccionada.nombre_norma;
                    }
                }
            });
        },

        async obtenerTablasNormas() {
            const idNormaSeleccionada = this.norma;
            await ElementosService.obtenerTablasNorma(idNormaSeleccionada).then((response) => {

                if(response != undefined){
                    if (response.data != null && response.status === 200) {

                        // Almacena las tablas agrupadas por nombre
                        const tablasAgrupadas = {};

                        // agrupar las tablas por nombre
                        response.data.forEach((tabla) => {
                            const nombreTabla = tabla.nombre_tabla;
                            const nombreParametro = tabla.nombre_parametro;
                            const idParametro = tabla.id_parametro;
                            const idMetodologia = tabla.id_metodologia;
                            const nombreMetodologia = tabla.nombre_metodologia;

                            // Verificar si la tabla ya está en el objeto tablasAgrupadas
                            if (!tablasAgrupadas[nombreTabla]) {
                                // Si la tabla no existe, crear un nuevo objeto con el nombre de la tabla
                                tablasAgrupadas[nombreTabla] = {
                                    nombre_tabla: nombreTabla,
                                    id_tablas: [],
                                    parametros: [],
                                    id_parametro: [],
                                    id_metodologia: [],
                                    metodologias: []
                                };
                            }
                            // Agregar el id_tabla y el nombre_parametro a la tabla correspondiente en tablasAgrupadas
                            tablasAgrupadas[nombreTabla].id_tablas.push(tabla.id_tabla);
                            tablasAgrupadas[nombreTabla].parametros.push(nombreParametro);
                            tablasAgrupadas[nombreTabla].id_parametro.push(idParametro);
                            tablasAgrupadas[nombreTabla].id_metodologia.push(idMetodologia);
                            tablasAgrupadas[nombreTabla].metodologias.push(nombreMetodologia)
                        });
                        // convertir  en un array
                        const tablasProcesadas = Object.values(tablasAgrupadas);
                        // Asignar las tablas procesadas a opcionesTabla
                        this.opcionesTabla = tablasProcesadas.map((tabla) => tabla.nombre_tabla);

                        // Asignar tablasProcesadas a this.tablasProcesadas
                        this.tablasProcesadas = tablasProcesadas;                                    
                    }
                }
            });
        },

        PushParametrosMetodologias() {            
            function esDuplicado(parametrosYMetodologias, parametro, metodologia) {
                return parametrosYMetodologias.some((item) => item.parametro === parametro && item.metodologia === metodologia);
            }
            let parametrosYMetodologias = [];
            // Recorrer cada tabla procesada
            for (const tabla of this.tablasProcesadas) {
                // Recuperar los arrays de id_parametro y metodologias de la tabla actual
                const idParametro = tabla.id_parametro;
                const parametros = tabla.parametros;
                const metodologias = tabla.metodologias;
                const idMetodologias = tabla.id_metodologia;
                
                // Agregar los parámetros y metodologías de la tabla actual a la variable principal
                for (let i = 0; i < parametros.length; i++) {
                    const parametro = parametros[i];
                    const metodologia = metodologias[i];
                    const id_parametro = idParametro[i];
                    const id_metodologia = idMetodologias[i];
                    // Verificar si el parámetro y la metodología ya existen en la variable "parametrosYMetodologias"
                    if (!esDuplicado(parametrosYMetodologias, parametro, metodologia)) {
                        parametrosYMetodologias.push({
                            id_parametro: id_parametro,
                            parametro: parametro,
                            metodologia: metodologia,
                            id_metodologia: id_metodologia
                        });
                        if(this.parametros_eliminar.find(objeto => objeto.id_parametro === id_parametro && objeto.id_metodologia === id_metodologia)){
                            this.parametros_eliminar = this.parametros_eliminar.filter(param => !(param.id_metodologia === id_metodologia &&
                                param.id_parametro === id_parametro));
                            this.parametro_submuestra_eliminar = this.parametro_submuestra_eliminar.filter(param => !(param.id_metodologia === id_metodologia &&
                                param.id_parametro === id_parametro));
                            if(!this.submuestrasOG.find(objeto => objeto.PYM.find(parametro => parametro.id_metodologia === id_metodologia && parametro.id_parametro === id_parametro))){
                                let submuestras_agregar_yaExistentesEnBD = this.sub.filter(objeto => objeto.id_submuestra != '')
                                submuestras_agregar_yaExistentesEnBD.forEach(submuestra => {
                                    this.parametro_submuestra_agregar.push({
                                        id_submuestra: submuestra.id_submuestra,
                                        id_parametro: id_parametro,
                                        id_metodologia: id_metodologia,
                                    }) 
                                });
                            }
                        }else{
                            this.parametros_agregar.push({
                                id_parametro: id_parametro,
                                id_metodologia: id_metodologia,
                            });
                            if(!this.submuestrasOG.find(objeto => objeto.PYM.find(parametro => parametro.id_metodologia === id_metodologia && parametro.id_parametro === id_parametro))){
                                let submuestras_agregar_yaExistentesEnBD = this.sub.filter(objeto => objeto.id_submuestra != '')
                                submuestras_agregar_yaExistentesEnBD.forEach(submuestra => {
                                    this.parametro_submuestra_agregar.push({
                                        id_submuestra: submuestra.id_submuestra,
                                        id_parametro: id_parametro,
                                        id_metodologia: id_metodologia,
                                    }) 
                                });
                            }
                        }
                        if(this.opcionesParametrosSeleccionados.find(objeto => objeto.parametro === parametro)){
                            const index = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === parametro);
                            if(!this.opcionesParametrosSeleccionados[index].metodologias.find(objeto => objeto.metodologia === metodologia)){
                                this.opcionesParametrosSeleccionados[index].metodologias.push({
                                metodologia: metodologia,
                                id_metodologia: id_metodologia,
                                })
                            }
                        }else{
                            this.opcionesParametrosSeleccionados.push({
                                id_parametro: id_parametro,
                                parametro: parametro,
                                metodologias: [{metodologia: metodologia,id_metodologia: id_metodologia}],
                            })
                            this.parametrosPorAsignar.push({id_parametro: id_parametro, parametro: parametro});

                        }
                        this.sub.forEach(submuestra => {
                            submuestra.PYM.push({
                                id_parametro: id_parametro,
                                parametro: parametro,
                                metodologia: metodologia,
                                id_metodologia: id_metodologia,
                            })
                        })
                        for (let i = 1;  i <= this.submuestra_agregar.length; i++) {
                            if(this.submuestra_agregar[i-1].parametros_agregar.length > 0){
                                if(this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.parametro === parametro &&
                                this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.metodologia === metodologia))){
                                    continue;
                                }else{
                                    this.submuestra_agregar[i-1].parametros_agregar.push({
                                    id_parametro: id_parametro,
                                    parametro: parametro,
                                    metodologia: metodologia,
                                    id_metodologia: id_metodologia
                                    });
                                }

                            }else{
                                this.submuestra_agregar[i-1].parametros_agregar.push({
                                id_parametro: id_parametro,
                                parametro: parametro,
                                metodologia: metodologia,
                                id_metodologia: id_metodologia
                                });
                            }
                        }
                    }
                }
            }
            const objetosNoDuplicados = parametrosYMetodologias.filter((item) => !esDuplicado(this.objetosSeleccionados, item.parametro, item.metodologia));
            this.objetosSeleccionados.push(...objetosNoDuplicados);
        },
            //PushParametrosMetodologias de la tabla seleccionada para agregar objetosSeleccionados
        PushParametrosMetodologiasTabla() {
            function esDuplicado(parametrosYMetodologias, parametro, metodologia) {
                return parametrosYMetodologias.some((item) => item.parametro === parametro && item.metodologia === metodologia);
            }   

            let parametrosYMetodologias = [];

            // Recuperar la tabla seleccionada
            const tablaSeleccionada = this.tablasProcesadas.find(tabla => tabla.nombre_tabla === this.tabla);

            if (tablaSeleccionada) {
                // Recuperar los arrays de id_parametro y metodologias de la tabla actual
                const idParametro = tablaSeleccionada.id_parametro;
                const parametros = tablaSeleccionada.parametros;
                const metodologias = tablaSeleccionada.metodologias;
                const idMetodologias = tablaSeleccionada.id_metodologia;

                // Agregar los parámetros y metodologías de la tabla actual a la variable principal
                for (let i = 0; i < parametros.length; i++) {
                    const parametro = parametros[i];
                    const metodologia = metodologias[i];
                    const id_parametro = idParametro[i];
                    const id_metodologia = idMetodologias[i];

                        // Verificar si el parámetro y la metodología ya existen en la variable "parametrosYMetodologias"
                        if (!esDuplicado(parametrosYMetodologias, parametro, metodologia)) {
                            if(!esDuplicado(this.objetosSeleccionados, parametro, metodologia)){

                                parametrosYMetodologias.push({
                                    id_parametro: id_parametro,
                                    parametro: parametro,
                                    metodologia: metodologia,
                                    id_metodologia: id_metodologia
                                });
                                if(this.parametros_eliminar.find(objeto => objeto.id_parametro === id_parametro && objeto.id_metodologia === id_metodologia)){
                                    this.parametros_eliminar = this.parametros_eliminar.filter(param => !(param.id_metodologia === id_metodologia &&
                                        param.id_parametro === id_parametro));
                                    this.parametro_submuestra_eliminar = this.parametro_submuestra_eliminar.filter(param => !(param.id_metodologia === id_metodologia &&
                                        param.id_parametro === id_parametro));
                                    if(!this.submuestrasOG.find(objeto => objeto.PYM.find(parametro => parametro.id_metodologia === id_metodologia && parametro.id_parametro === id_parametro))){
                                        let submuestras_agregar_yaExistentesEnBD = this.sub.filter(objeto => objeto.id_submuestra != '')
                                        submuestras_agregar_yaExistentesEnBD.forEach(submuestra => {
                                            this.parametro_submuestra_agregar.push({
                                                id_submuestra: submuestra.id_submuestra,
                                                id_parametro: id_parametro,
                                                id_metodologia: id_metodologia,
                                            }) 
                                        });
                                    }
                                }else{
                                    this.parametros_agregar.push({
                                        id_parametro: id_parametro,
                                        id_metodologia: id_metodologia,
                                    });
                                    if(!this.submuestrasOG.find(objeto => objeto.PYM.find(parametro => parametro.id_metodologia === id_metodologia && parametro.id_parametro === id_parametro))){
                                        let submuestras_agregar_yaExistentesEnBD = this.sub.filter(objeto => objeto.id_submuestra != '')
                                        submuestras_agregar_yaExistentesEnBD.forEach(submuestra => {
                                            this.parametro_submuestra_agregar.push({
                                                id_submuestra: submuestra.id_submuestra,
                                                id_parametro: id_parametro,
                                                id_metodologia: id_metodologia,
                                            }) 
                                        });
                                    }
                                }
                                if(this.opcionesParametrosSeleccionados.find(objeto => objeto.parametro === parametro)){
                                    const index = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === parametro);
                                    if(!this.opcionesParametrosSeleccionados[index].metodologias.find(objeto => objeto.metodologia === metodologia)){
                                        this.opcionesParametrosSeleccionados[index].metodologias.push({
                                        metodologia: metodologia,
                                        id_metodologia: id_metodologia,
                                        })
                                    }
                                }else{
                                    this.opcionesParametrosSeleccionados.push({
                                        id_parametro: id_parametro,
                                        parametro: parametro,
                                        metodologias: [{metodologia: metodologia,id_metodologia: id_metodologia}],
                                    })
                                    this.parametrosPorAsignar.push({id_parametro: id_parametro, parametro: parametro});
                                }
                                this.sub.forEach(submuestra => {
                                    submuestra.PYM.push({
                                        id_parametro: id_parametro,
                                        parametro: parametro,
                                        metodologia: metodologia,
                                        id_metodologia: id_metodologia,
                                    })
                                })
                                for (let i = 1;  i <= this.submuestra_agregar.length; i++) {
                                    if(this.submuestra_agregar[i-1].parametros_agregar.length > 0){
                                        if(this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.parametro === parametro &&
                                        this.submuestra_agregar[i-1].parametros_agregar.find(objeto => objeto.metodologia === metodologia))){
                                            continue;
                                        }else{
                                            this.submuestra_agregar[i-1].parametros_agregar.push({
                                            id_parametro: id_parametro,
                                            parametro: parametro,
                                            metodologia: metodologia,
                                            id_metodologia: id_metodologia
                                            });
                                        }

                                    }else{
                                        this.submuestra_agregar[i-1].parametros_agregar.push({
                                        id_parametro: id_parametro,
                                        parametro: parametro,
                                        metodologia: metodologia,
                                        id_metodologia: id_metodologia
                                        });
                                    }
                                }

                            }
                            
                        }
                }
                // Asegurarnos de no agregar duplicados a "this.objetosSeleccionados"
                const objetosNoDuplicados = parametrosYMetodologias.filter((item) => !esDuplicado(this.objetosSeleccionados, item.parametro, item.metodologia));
                this.objetosSeleccionados.push(...objetosNoDuplicados);
             
            }


        },
        asignarAnalistas() {
            this.empleadosPorAsignar.forEach(empleado => {
                for(var i = 0; i < empleado.parametro.length; i++){
                    this.empleados_agregar.push({
                        rut_empleado: empleado.rut_empleado,
                        orden_de_analisis: empleado.orden_de_analisis,
                        id_parametro: empleado.id_parametro[i],
                        fecha_entrega: empleado.fecha_entrega,
                        observacion_analista: empleado.observacion_analista, 
                    })
                }
            });
            
        },
        resetOpcionesParametrosInciales() {
            this.obtenerParametro();
            this.opcionesParametro = this.TodasopcionesParametro;
        },
        
        actualizarParametrosTabla() {
            const tablaSeleccionada = this.tabla;
            // Buscar la tabla seleccionada en tablasProcesadas
            const tablaProcesada = this.tablasProcesadas.find(tabla => tabla.nombre_tabla === tablaSeleccionada);

            if (tablaProcesada) {
                const parametrosUnicos = Array.from(new Set(tablaProcesada.parametros)); // Eliminar duplicados
                this.parametrosTablaSeleccionada = parametrosUnicos.map(parametro => ({
                    nombre_parametro: parametro
                }));
                this.opcionesParametro = parametrosUnicos;
            } else {
                // Si la tabla seleccionada no se encuentra en tablasProcesadas, limpiar los parámetros
                this.parametrosTablaSeleccionada = [];
                this.opcionesParametro = [];
            }
        },

        actualizarMetodologiasTabla(){

            const parametroSeleccionado = this.parametrosOptions.find(parametro => parametro.nombre_parametro === this.parametroTablaSeleccionado);
            if (parametroSeleccionado) {
                
                const metodologiasdelparametroseleccionado = this.metodologiasData.find(p => p.nombre_parametro === parametroSeleccionado.nombre_parametro);              

                if(metodologiasdelparametroseleccionado){


                    const metodologiaEncontrada = metodologiasdelparametroseleccionado.metodologias

                    if (metodologiaEncontrada){

                        this.opcionesMetodologiaTabla = [];
                        this.opcionesMetodologiaTabla = metodologiaEncontrada.map(item => item.nombre_metodologia);
                    }
                }            
            } 
        },

        async validarFormularios() {
            const recepValido = await this.$refs.valrecepcionista.validate();
            const muestValido = await this.$refs.valmuestra.validate()
            const transValido = await this.$refs.valtransportista.validate();
            const paramValido = await this.$refs.valparametros.validate();

            if (recepValido && muestValido && transValido && paramValido) {
                this.recepcionista_incompleto = false;
                this.muestra_incompleto = false;
                this.transportista_incompleto = false;
                this.parametros_incompleto = false;
                this.revisado = true;
                return true;
            } else {

                this.recepcionista_incompleto = !recepValido;
                this.muestra_incompleto = !muestValido;
                this.transportista_incompleto = !transValido;
                this.parametros_incompleto = !paramValido;
                this.revisado = true;
                return false;
            }
        },

        async RellenarForm(response) {            
            //info anterior
            this.recepcionistaRUT = response.rut_empleado;
            this.RUM = response.RUM;
            this.direccionOG = response.direccion_empresa;       
            //this.rutOG = response.rut_solicitante;
            this.recepcionistaOG = response.rut_empleado;
            this.nMuestrasOG = response.cantidad_muestras.toString();
            this.fechaOG = response.fecha_muestreo;
            this.horaOG = response.hora_muestreo;
            this.fechaEntregaOG = response.fecha_entrega;
            this.TipoMatrizOG = response.id_matriz;
            this.muestreadoOG = response.muestreado_por;
            this.observacionesOG = response.observaciones
            this.prioridadOG = response.prioridad;
            this.transportistaRutOG = response.rut_transportista;
            this.transportistaOG = response.nombre_transportista;                       
            this.TemperaturaOG = response.temperatura_transporte;
            this.patenteOG = response.patente_vehiculo;
            this.telefonos_agregarOG = response.telefonos_transportista;
            this.idParamOG = response.submuestras.map((idP) => idP.id_parametro);
            this.idMetOG = response.submuestras.map((idM) => idM.id_metodologia);
            this.normaOG = response.id_norma;
            this.fecha_recepcion = response.fecha_ingreso;
            this.hora_recepcion = response.hora_ingreso.slice(0, 5);
            //this.tablaOG = response.id_tabla;
            this.cantSubmuestrasIniciales = this.cantidad_muestras;
            for(var i = 0; i < response.submuestras.length; i++){
                if(this.submuestrasOG.length<=0){
                    this.submuestrasOG.push({
                        id_submuestra: response.submuestras[i].id_submuestra,
                        identificador: response.submuestras[i].identificador,
                        orden: response.submuestras[i].orden,
                        PYM: [{id_parametro: response.submuestras[i].id_parametro, id_metodologia:response.submuestras[i].id_metodologia}]
                    })
                }else{
                    if(this.submuestrasOG.find(objeto => objeto.id_submuestra === response.submuestras[i].id_submuestra)){
                        const index_submuestra = this.submuestrasOG.findIndex(objeto => objeto.id_submuestra === response.submuestras[i].id_submuestra)
                        this.submuestrasOG[index_submuestra].PYM.push({id_parametro: response.submuestras[i].id_parametro, id_metodologia:response.submuestras[i].id_metodologia})
                    }else{
                        this.submuestrasOG.push({
                        id_submuestra: response.submuestras[i].id_submuestra,
                        identificador: response.submuestras[i].identificador,
                        orden: response.submuestras[i].orden,
                        PYM: [{id_parametro: response.submuestras[i].id_parametro, id_metodologia:response.submuestras[i].id_metodologia}]
                    })
                    }
                }
            }
            this.subMuestra = response.submuestras.map(submuestra=> ({
                identificador: submuestra.identificador,
                id_metodologia: submuestra.id_metodologia,
                id_parametro: submuestra.id_parametro,
                orden: submuestra.orden,
                analistasSeleccionados: [],
            }));        
            this.empleados = response.empleados;
            this.empleadosOG = response.empleados;
            this.parametros_metodologias = response.parametros_metodologias;
            this.parametros_metodologias.forEach(parametroExistente => {
                const parametro = parametroExistente.nombre_parametro;
                const metodologia = parametroExistente.nombre_metodologia;
                const id_parametro = parametroExistente.id_parametro;
                const id_metodologia = parametroExistente.id_metodologia;
                if(this.opcionesParametrosSeleccionados.find(objeto => objeto.parametro === parametro)){
                    const index = this.opcionesParametrosSeleccionados.findIndex(objeto => objeto.parametro === parametro);
                    if(!this.opcionesParametrosSeleccionados[index].metodologias.find(objeto => objeto.metodologia === metodologia)){
                        this.opcionesParametrosSeleccionados[index].metodologias.push({
                        metodologia: metodologia,
                        id_metodologia: id_metodologia,
                        })
                    }
                    }else{
                        this.opcionesParametrosSeleccionados.push({
                            id_parametro: id_parametro,
                            parametro: parametro,
                            metodologias: [{metodologia: metodologia,id_metodologia: id_metodologia}],
                        })
                        this.parametrosPorAsignar.push({id_parametro: id_parametro,parametro: parametro})
                    }
                    
            })
            
            this.sub = response.submuestras.reduce((result, current) => {
                const index = result.findIndex((item) => item.id_submuestra === current.id_submuestra);
                const PYMObj = {
                    metodologia: current.nombre_metodologia,
                    parametro: current.nombre_parametro,
                    id_metodologia: current.id_metodologia,
                    id_parametro: current.id_parametro,
                };
                if (index === -1) {
                    result.push({
                        RUM: current.RUM,
                        detalle_metodologia: [current.detalle_metodologia],
                        id_metodologia: [current.id_metodologia],
                        id_parametro: [current.id_parametro],
                        id_submuestra: current.id_submuestra,
                        identificador: current.identificador,
                        orden: current.orden,
                        PYM: [PYMObj], // Agregamos el array PYM con el primer objeto PYM
                    });
                } 
                else {
                    result[index].detalle_metodologia.push(current.detalle_metodologia);
                    result[index].id_metodologia.push(current.id_metodologia);
                    result[index].id_parametro.push(current.id_parametro);
                    result[index].PYM.push(PYMObj); // Agregamos el objeto PYM al array PYM existente
                }
                return result;
            }, []);


            this.sub = this.sub.map(sub => ({
                id_submuestra: sub.id_submuestra,
                orden: sub.orden,
                identificador: sub.identificador,
                PYM: sub.PYM
            }))

                //TAB RECEPCIONISTA
            this.observacionCliente = response.observacion_cliente;
            if(response.rut_solicitante != null){
                this.rutFilter = response.rut_solicitante;
                await solicitanteService.obtenerDetallesSolicitante(this.rutFilter).then((response) => {
                try{
                    if (response.request.status == 200 && this.rutFilter !== "") {
                    this.solicitanteIngresado = true;
                    this.empresaSeleccionada = true;
                    this.opcionesDireccion  = [];
                    this.direccion = '';
                    this.nombre_empresa = '';
                    //obten nombre y apellido
                    this.nombreSolicitante = `${response.data.nombre} ${response.data.primer_apellido} ${response.data.segundo_apellido}`;
                    this.rutSolicitante = response.data.rut_solicitante; 
                    this.telefono_solicitante = response.data.telefono;
                    this.correo_solicitante = response.data.correo;

                    if(response.data.detalles_empresas.length > 0){
                        this.tieneEmpresa = true;
                    }else{

                        this.tieneEmpresa = false;
                        ///
                    }
                    const empresas = response.data.detalles_empresas;
                    this.empresasDelSolicitante = empresas;

                    
                    const data = { rut_solicitante: this.rutSolicitante };
                    solicitanteService.CotizacionSolicitante(data).then((response) => {
                        if (response.data != null && response.status === 200) {
                            this.opcionesCotizacion = response.data.map(cotizacion => ({
                                id_cotizacion: cotizacion.id_cotizacion,
                                nombre_original_documento: cotizacion.nombre_original_documento,
                                idconNombre: `${cotizacion.id_cotizacion} - ${cotizacion.nombre_original_documento}`
                            }))
                            if(this.opcionesCotizacion.length>0){
                                this.tieneCotizaciones = true;
                            }else{
                                this.tieneCotizaciones = false;
                            }
                        }
                    })

                    if(response.data.tipo_cliente === 'Particular'){
                        this.empresaSolicitante = 'Particular';
                        this.nombre_empresa = 'Particular';
                        this.rut_empresa =null;
                        this.empresasDelSolicitante = [{nombre_empresa: 'Particular',rut_empresa:'Particular'}];

                    }
                }

                }catch{
                    this.showAlert();
                }
            })
            }else{
                await MuestraService.obtenerEmpresas().then((response) => {
                    const empresas = response.data;
                    this.opcionesEmpresa = empresas;
                })
                this.empresasDelSolicitante =  this.opcionesEmpresa 
                
            }  
            this.rut = response.rut_solicitante;
            if(response.rut_empresa != null){
                this.empresaSolicitante = response.rut_empresa;
            } 
            this.rut_empresa = response.rut_empresa;
            this.nombre_empresa = response.nombre_empresa;
            this.direccion = response.id_ciudad;
            this.cotizacion = response.id_cotizacion;
                //TAB MUESTRA
            this.nMuestras = response.cantidad_muestras.toString();
            this.fecha = response.fecha_muestreo;
            this.hora = response.hora_muestreo.slice(0, 5);
            this.fechaEntrega = response.fecha_entrega;
            this.TipoMatriz = response.id_matriz;
            this.muestreado = response.muestreado_por;
            this.observaciones = response.observaciones.map(obs => obs.observaciones).join("\n");
            this.prioridad = response.prioridad;      


                //TAB TRANSPORTISTA
            this.transportistaRut = response.rut_transportista;
            this.transportista = response.nombre_transportista;                       
            this.Temperatura = response.temperatura_transporte;
            this.patente = response.patente_vehiculo;
            this.telefonos_eliminar = response.telefonos_transportista;
            if(response.telefonos_transportista.length > 0){
                this.telefono_transportista = response.telefonos_transportista[0].telefono_transportista;
            } 

                //TAB PARAMETROS
            this.norma = response.id_norma;
            //this.tabla = response.id_tabla;

                //TAB ASIGNACIÓN
            this.identificacion = response.submuestras.map((id) => id.identificador);    
            this.id_submuestra = response.submuestras.map((id) => id.id_submuestra);

        },   
        verificarParametros() {
            for (let objetoSeleccionado of this.objetosSeleccionados) {
                let idMetodologia = objetoSeleccionado.id_metodologia;
                let encontrado = false;

                // Recorremos cada submuestra
                for (let submuestra of this.sub) {
                let parametrosAgregar = submuestra.PYM;

                // Verificamos si el id_metodologia está en los parámetros de la submuestra
                if (parametrosAgregar.some(parametro => parametro.id_metodologia === idMetodologia)) {
                    encontrado = true;
                    break; // Si encontramos el id_metodologia, no necesitamos seguir buscando en otras submuestras
                }
                }

                // Si no encontramos el id_metodologia en ninguna submuestra, retornamos false
                if (!encontrado) {
                return false;
                }
            }

            // Si todos los id_metodologia están presentes en al menos una submuestra, retornamos true
            return true;
        },       
        async enviarFormulario() {
            const datosValidos = await this.validarFormularios();
            
            if (!datosValidos) {
                return;
            } 
            else if (this.sub.some(submuestra => submuestra.identificador.trim() === '')) {
                this.$bvToast.toast("La muestra contiene identificadores en blanco. Por favor, complete todos los identificadores.", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (this.sub.some(submuestra => submuestra.PYM.length === 0)) {
                this.$bvToast.toast("Alguna submuestra no posee parametros, porfavor ingrese parametros para dicha muestra.", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (this.parametrosPorAsignar.length !== 0) {
                this.$bvToast.toast("Hay parametros que no se han asignado a ningun analista, intentelo denuevo", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (this.empleadosPorAsignar.some(empleado => empleado.rut_empleado === null  || empleado.orden_de_analisis === null  || empleado.fecha_entrega === null)) {
                this.$bvToast.toast("Existen datos sin completar en la asignación de analistas", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (this.empleadosPorAsignar.some(empleado => empleado.id_parametro.length === 0)) {
                this.$bvToast.toast("Existen analistas sin parametros asociados", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (!(this.verificarOrdenAnalisis(this.empleadosPorAsignar))) {
                this.$bvToast.toast("Verifique que el orden de analisis de los analistas", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else if (!this.verificarParametros()) {
                this.$bvToast.toast("Algun parámetro no ha sido asignado a ninguna submuestra, intentelo denuevo", {
                    title: 'Error',
                    toaster: 'b-toaster-top-center',
                    solid: true,
                    variant: "danger",
                    appendToast: true
                });
                return;
            }
            else{
                
                this.obs();
                this.asignarAnalistas();               
                if(this.empresaSolicitante =='Particular'){
                    this.empresaSolicitante = null;
                }
                var data = {
                    RUM: this.RUM,
                    rut_empresa: this.empresaSolicitante,
                    rut_empleado: this.recepcionistaRUT,
                    nombre_empresa: this.nombre_empresa,
                    id_ciudad: this.direccion,
                    direccion_empresa: this.direccion_empresa,                    
                    rut_solicitante: this.rutSolicitante,
                    muestreado_por: this.muestreado,
                    cantidad_muestras: this.nMuestras,
                    prioridad: this.prioridad,
                    temperatura_transporte: this.Temperatura,
                    fecha_ingreso: this.fecha_recepcion,
                    hora_ingreso: this.hora_recepcion,
                    fecha_entrega: this.fechaEntrega,
                    fecha_muestreo: this.fecha,
                    hora_muestreo: this.hora,
                    rut_transportista: this.transportistaRut,
                    nombre_transportista: this.transportista,
                    patente_vehiculo: this.patente,
                    telefonos_agregar: {telefono_transportista: this.telefono_transportista},
                    estado: 'En Análisis',
                    observaciones: this.nuevaobs.map(obs=> ({
                        fecha_observacion: obs.fecha_observacion,
                        hora_observacion: obs.hora_observacion,
                        observaciones: obs.observaciones
                    })),
                    parametros_agregar: this.parametros_agregar,
                    id_matriz: this.TipoMatriz,
                    id_norma: this.norma,
                    id_tabla: this.id_tabla,
                    submuestras_agregar: this.submuestra_agregar,
                    submuestras_eliminar: this.submuestra_eliminar,
                    submuestras_editar: this.submuestra_editar,
                    parametro_submuestra_agregar: this.parametro_submuestra_agregar,
                    parametro_submuestra_eliminar: this.parametro_submuestra_eliminar,
                    telefonos_eliminar: this.telefonos_eliminar,
                    parametros_eliminar: this.parametros_eliminar,
                    id_cotizacion: this.cotizacion,
                    tipo_pago: this.tipo_pago,
                    valor_neto: this.valor_neto,
                    empleados_agregar: this.empleados_agregar,
                    observacion_cliente: this.observacionCliente,
                                       
                }
                MuestraService.actualizarMuestra(data).then((response) => {
                    if (response != null && response != undefined) {
                        if (response.status == 200) {
                            this.$bvToast.toast(`Ingreso de la muestra exitoso.`, {
                                title: 'Éxito',
                                toaster: 'b-toaster-top-center',
                                solid: true,
                                variant: "success",
                                appendToast: true
                            })    
                            setTimeout(() => {
                                if(this.userData.role == 2|| this.userData.role == 6){ // jefe de laboratorio y supervisor
                                this.$router.push({name: "AdminMuestraSupervisor"});
                                }
                                if(this.userData.role == 3 || this.userData.role == 4){ // quimico y analista quimico
                                    this.$router.push({name: "AdminMuestraLab"});
                                }
                                
                            }, 1000);                            
                        }else{
                            this.$bvToast.toast(`Error al ingresar la muestra.`+response.toString(), {
                            title: 'Error',
                            toaster: 'b-toaster-top-center',
                            solid: true,
                            variant: "warning",
                            appendToast: true
                            })
                            this.empleados_agregar = [];

                        }

                    } 
                    else {
                        this.$bvToast.toast(`Error al ingresar la muestra.`, {
                            title: 'Error',
                            toaster: 'b-toaster-top-center',
                            solid: true,
                            variant: "warning",
                            appendToast: true
                        })
                        this.empleados_agregar = [];
                    }
                })
            }        
        },
    }
}
</script>