<template>
  <div>
    <b-modal id="modal-cantidad" ref="modal" title="Cantidad de submuestras" size="xl" @hidden="onModalClose">
<template #modal-header="{ close }">
 <!-- Emulate built-in modal header close button action -->
 <b-row class="d-flex justify-content-around">
   <div class="pl-3">Cantidad de muestras</div>
 </b-row>
 <button type="button" class="close" aria-label="Close" @click="close()">
   <span aria-hidden="true" style="color:white">&times;</span>
 </button>
</template>
<div>
 <b-table :items="tablaItems" :fields="tablaFields">   
   <template #cell(identificacion)="row">
     <b-form-input v-model="row.item.identificacion" placeholder="Ingrese la identificación de la muestraA."></b-form-input>
   </template>        
   <template #cell(PYM)="row">
    <template v-if="row.item.PYM.length === 0">
      <b-list-group-item>No hay parametros seleccionados.</b-list-group-item>
    </template>
    <template v-else>
      <b-list-group>
        <b-list-group-item v-b-toggle="'collapse-pym-' + row.index" :visible="row.item.PYM.length > 0">
          <strong>{{ row.item.PYM[0].parametro }}</strong> - {{ row.item.PYM[0].metodologia }}
          <b-icon style="position:absolute; right:0px; top:25%; color: #949494" icon="caret-down-fill" v-if="row.item.PYM.length > 1"></b-icon>
        </b-list-group-item>
        <b-collapse :id="'collapse-pym-' + row.index">
          <template v-for="(item, index) in row.item.PYM.slice(1)"> 
            <b-list-group-item :key="index">
              <strong>{{ item.parametro }}</strong> - {{ item.metodologia }}
            </b-list-group-item>
          </template>
        </b-collapse>
      </b-list-group>
    </template>
   </template>
   <template #cell(accion)="row">
     <b-dropdown right size="sm" variant="link" toggle-class="text-decoration-none" no-caret>
         <template #button-content>
             <b-icon style="height: 80%; width: 80%; align-items: center;" icon="three-dots" variant="dark" aria-hidden="true"></b-icon>
         </template>
         <b-dropdown-item v-if="row" @click="abrirParam(row.item)">
              <b-icon icon="clipboard" aria-hidden="true" class="mr-2"></b-icon>Administrar parámetros
         </b-dropdown-item>
         <b-dropdown-item v-if="row" @click="eliminarSubmuestra(row.item, row.index)">
              <b-icon icon="trash-fill" aria-hidden="true" class="mr-2"></b-icon>Eliminar submuestra
         </b-dropdown-item>                                                                                                                     
     </b-dropdown>
   </template>      
 </b-table>
 <div class="alert alert-warning mt-3" role="alert">
   No olvide guardar los cambios que haya realizado.
 </div>
</div> 

<b-alert variant="success" :show="guardadoExitoso" dismissible @dismissed="guardadoExitoso = false">
  Se han guardado los datos con éxito.
</b-alert>
<b-alert variant="danger" :show="minimoDeSubmuestras" dismissible @dismissed="minimoDeSubmuestras = false">
  No se puede tener 0 submuestras 
</b-alert>
<template>
  <b-button @click="AgregarSubmuestra()" variant="primary" size="xl" class="float-left reactive-button" style="font-weight:bold">Agregar</b-button> 
</template>

<template #modal-footer="{ close }" >
  <b-button @click="guardarFormulario()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">Guardar</b-button>
  
  <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">
    Cerrar
  </b-button>
</template>
</b-modal>

  <b-modal id="modal-Agregar-P" ref="m" :title="`Agregar parámetros a analistas`" size="lg" @hidden="onModalClose2">
    <template #modal-header="{ close }">
        <b-row class="d-flex justify-content-around">
            <div class="pl-3">Asignar parámetros a analistas</div>
        </b-row>
        <button type="button" class="close" aria-label="Close" @click="close()">
            <span aria-hidden="true" style="color:white">&times;</span>
        </button>
    </template>
    <b-row>
        <b-col>
            <b-form-group label="Seleccione un parámetro:">
                <b-form-select v-model="parametroSeleccionadoIngreso" :options="TodasopcionesParametro" text-field="parametro" value-field="id_parametro" @change="agregar(filaSeleccionada)"></b-form-select>
            </b-form-group>
        </b-col>

        <b-col>
            <b-form-group label="Seleccione una metodologia:">
                <b-form-select v-model="metodologiaSeleccionadoIngreso" :options="opcionesMetodologia" :disabled="metodologiaDeshabilitada" text-field="metodologia" value-field="id_metodologia" @change="agregar(filaSeleccionada)"></b-form-select>
            </b-form-group>
        </b-col>  
    </b-row>
    <b-row v-if="filaSeleccionada && filaSeleccionada.PYM.length > 0" class="mt-3">
      <b-col>
        <b-form-group label="Parámetros seleccionados:">
          <div v-for="(objeto, index) in filaSeleccionada.PYM" :key="index" class="d-flex align-items-center objetos-item mb-3">
            <b-input readonly :value="objeto.parametro" class="mr-2"></b-input>
            <b-input readonly :value="objeto.metodologia" class="mr-2"></b-input>                                     
            <b-button variant="danger" @click="eliminarElementoPYM(filaSeleccionada, index)" class="ml-2">
              <b-icon-trash-fill></b-icon-trash-fill>
            </b-button>
          </div>
        </b-form-group>
      </b-col>
    </b-row>           
    <b-alert variant="danger" :show="alertaDuplicado" dismissible @dismissed="alertaDuplicado = false">
        El parámetro ya se encuentra agregado.
    </b-alert>           
    <b-alert variant="success" :show="alertaExito" dismissible @dismissed="alertaExito = false">
        Parámetro agregado con éxito.
    </b-alert>
    <!-- //////////////////////////////////////////MODAL-FOOTER////////////////////////////////////////////////////////////////////////////////// -->
    <template #modal-footer="{ close }">                        
        <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">
          Cerrar
        </b-button>
    </template>
  </b-modal>           
</div>
</template>

<script>
import ElementosService from '@/helpers/api-services/Elementos.service';

export default {
data() {
return {
  parametrosOptions: [],
  metodologiasOptions: [],
  identificacion: '',
  orden: 0,
  identificacionesArray: [],
  parametros_metodologias: [],
  TodasopcionesParametro: [],
  objetossubSeleccionados: [],
  parametroSeleccionado: '',
  metodologiaSeleccionada: '',
  opcionesMetodologia: [],
  alertaDuplicado: false,
  alertaExito: false,
  alertaExiste: false,
  alertaNOexiste: false,
  metodologiaDeshabilitada: true,
  parametroSeleccionadoIngreso: '',
  filaSeleccionada: null,
  metodologiaSeleccionadoIngreso: '',
  metodologiasData: [],
  index: null,
  eliminar: [],
  guardadoExitoso: false,
  submuestra: '',
  elementosAgregados: [],
  minimoDeSubmuestras: false,
};
},

props: {
nMuestras: {
  type: String,
  default: '',
},
objetosSeleccionados: {
  type: Array,
},
identificaciones: {
  type: Array,
},
sub: {
  type: Array
},
opcionesParametrosSeleccionados: {
  type: Array
},
actualizarModal: {
  type: Number,
  default: 0,
},
parametro_submuestra_agregar: {
  type: Array
},
parametro_submuestra_eliminar: {
  type: Array
},
},

mounted(){
  this.$refs.modal.$on('show', () => {
    if (this.identificaciones.length > 0){
      this.identificacionesArray = JSON.parse(JSON.stringify(this.identificaciones));
    }
  });
  this.obtenerParametro();
},

watch: {
objetosSeleccionados:{
  handler(nuevosObjetosSeleccionados) {
     const parametrosSet = new Set(nuevosObjetosSeleccionados.map(p => p.parametro));
     const metodologiasSet = new Set(nuevosObjetosSeleccionados.map(m => m.metodologia));
     this.parametrosOptions = [...parametrosSet];
     this.metodologiasOptions = [...metodologiasSet];
   },
   immediate: true
},
actualizarModal:{
    handler(numero) {
      console.log(numero);
      },
      immediate: true
},
  opcionesParametrosSeleccionados:{
        handler(opcionesParametrosSeleccionados_nuevos) {
          this.TodasopcionesParametro = opcionesParametrosSeleccionados_nuevos;
          if(this.TodasopcionesParametro.length <= 0){
            this.hayParametros = false;
          }else{
            this.hayParametros = true;
          }


          },
          immediate: true
},
parametroSeleccionadoIngreso: function (newParametro) {
       if (newParametro) {
           this.actualizarMetodologias();
           this.metodologiaDeshabilitada = false; // Habilita el input 
       } else {
           this.metodologiaDeshabilitada = true;
       }
   },
},

computed: {
  tablaItems() {
    this.actualizarModal;
    const items = [];
    const submuestras = this.sub; 
    for (let i = 0; i < submuestras.length; i++) {
      const submuestra = submuestras[i];
      const identificacion = submuestra.identificador || ''; 

      items.push({
        orden:submuestra.orden,
        identificacion,
        id_submuestra: submuestra.id_submuestra,        
        PYM: submuestra.PYM 
      });
    }
    
    return items;
    
  },

  tablaFields() {
    const fields = [
      { key: 'orden', label: 'Orden', thClass: 'text-center', tdClass: 'text-center', editable: true },
      { key: 'identificacion', label: 'Identificación', thClass: 'text-center', tdClass: 'item-center' },
    ];
    if (this.sub.length > 0) { // Reemplaza 'tuArrayDeDatos' con el nombre de tu array de datos
      fields.push({ key: 'PYM', label: 'Parámetros y metodologías', thClass: 'text-center', tdClass: 'item-center' });
      fields.push({ key: 'accion', label: 'Acción', thClass: 'text-center', tdClass: 'item-center' });
    }
    return fields;
  },      
},

methods: {
  guardarFormulario() {
  const datosIngresados = this.tablaItems.map((item) => {        

   // Capturamos los id_parametro e id_metodologia del PYM
    const PYM = item.PYM.map(pymItem => ({
      id_parametro : pymItem.id_parametro,
      id_metodologia : pymItem.id_metodologia,
      parametro: pymItem.parametro,
      metodologia: pymItem.metodologia,
    }));         

    return {
      id_submuestra: item.id_submuestra,
      identificador: item.identificacion,
      orden: item.orden,     
      parametros_agregar: PYM              
    };
  });
  this.$emit('eliminados', this.eliminar);
  this.$emit('agregar-sub-param', this.elementosAgregados);
  this.$emit('datosIngresados', datosIngresados);
  
  this.elementosAgregados = []; //luego de agregar los elementos se eliminan
  this.eliminar = [];
  this.$bvToast.toast(`Identificadores guardados con éxito.`, {
    title: 'Identificadores Guardados',
    toaster: 'b-toaster-top-center',
    solid: true,
    variant: "success",
    appendToast: true
  }) 
},
onModalClose() {
      this.elementosAgregados = [];
      this.eliminar = [];
},
onModalClose2() {
  this.parametroSeleccionadoIngreso = '';
  this.metodologiaDeshabilitada = true;
  this.metodologiaSeleccionadoIngreso = '';

},
eliminarElementoPYM(filaSeleccionada, index) {
  // Obtenemos el elemento eliminado antes de hacer el slice
  const elementoEliminado = filaSeleccionada.PYM[index];  
  // Guardar valores específicos para mostrar en variables
  const id_metodologia = elementoEliminado.id_metodologia;
  const id_parametro = elementoEliminado.id_parametro;
  const id_submuestra = filaSeleccionada.id_submuestra;  
  
  // Hacemos un slice del array PYM para eliminar el elemento en el índice dado
  filaSeleccionada.PYM = [...filaSeleccionada.PYM.slice(0, index), ...filaSeleccionada.PYM.slice(index + 1)];
  
  // Agregamos solo los datos específicos a la lista de eliminados
  if(filaSeleccionada.id_submuestra != ''){
    if(this.elementosAgregados.find(objeto => objeto.id_submuestra === id_submuestra && objeto.id_parametro === id_parametro && objeto.id_metodologia === id_metodologia)){
      const indexBorrar = this.elementosAgregados.findIndex(objeto => objeto.id_submuestra === id_submuestra && objeto.id_parametro === id_parametro && objeto.id_metodologia === id_metodologia)
      this.elementosAgregados.splice(indexBorrar,1);
    }else if(this.parametro_submuestra_agregar.find(objeto => objeto.id_submuestra === id_submuestra && objeto.id_parametro === id_parametro && objeto.id_metodologia === id_metodologia)){
      const indexBorrar = this.parametro_submuestra_agregar.findIndex(objeto => objeto.id_submuestra === id_submuestra && objeto.id_parametro === id_parametro && objeto.id_metodologia === id_metodologia)
      this.$emit('eliminar-parametro-submuestra-agregar', indexBorrar);
    }
    else{
      this.eliminar.push({ id_submuestra, id_parametro, id_metodologia });
    }
    
  }
  
},

eliminarSubmuestra(filaSeleccionada, index) {
  if(this.sub.length == 1){
    this.minimoDeSubmuestras = true;
    return;
  }
  
  this.$emit('eliminar-fila', filaSeleccionada, index);
},

AgregarSubmuestra(){
  this.$emit('agregar-submuestra',this.tablaItems[this.tablaItems.length - 1].orden);
},

abrirParam(row) {
  this.filaSeleccionada = row;
  this.$bvModal.show('modal-Agregar-P');
},

onModalShown() {
  this.alertaExito = false;
  this.alertaDuplicado = false;
},
  
obtenerParametro() {
  ElementosService.obtenerParametros().then((response) => {
    if (response.data != null && response.status === 200) {
      // Almacenar los datos en metodologiasData
      this.metodologiasData = response.data.map(item => ({
        id_parametro: item.id_parametro,
        nombre_parametro: item.nombre_parametro,
        metodologias: item.metodologias.map(metodologia => ({
          id_metodologia: metodologia.id_metodologia,
          nombre_metodologia: metodologia.nombre_metodologia
        }))
      }));
      this.opcionesMet = this.metodologiasData.flatMap(met => met.metodologias);
      this.opcionesParametro = response.data.map(item => item.nombre_parametro);
      
   }
 });
},
 
agregarObjetosSeleccionados() {
  if (this.parametroSeleccionado && this.metodologiaSeleccionada) {
    const ParamExistente = this.objetossubSeleccionados.find(objeto => objeto.parametro === this.parametroSeleccionado);
    const MetExistente = this.objetossubSeleccionados.find(objeto => objeto.metodologia === this.metodologiaSeleccionada);
    if (ParamExistente && MetExistente) {
      this.alertaDuplicado = true;
      this.parametroSeleccionado = '';
      this.metodologiaSeleccionada = '';
      this.alertaExito = false;
    } else {
      const parametroData = this.metodologiasData.find(item => item.nombre_parametro === this.parametroSeleccionado);
      const metodologiaCompleta = parametroData.metodologias.find(metodologia => metodologia.nombre_metodologia === this.metodologiaSeleccionada);
      this.objetossubSeleccionados.push({
          id_parametro: parametroData.id_parametro,
          parametro: this.parametroSeleccionado,
          metodologia: metodologiaCompleta.nombre_metodologia,
          id_metodologia: metodologiaCompleta.id_metodologia
      });
      this.parametros_agregar.push({
          id_parametro: parametroData.id_parametro,
          id_metodologia: metodologiaCompleta.id_metodologia,
      });
      this.parametroSeleccionado = '';
      this.metodologiaSeleccionada = '';
      this.alertaDuplicado = false;
      this.alertaExito = true;
    }
  }
},

agregar(filaSeleccionada) {  

  if( this.parametroSeleccionadoIngreso && this.metodologiaSeleccionadoIngreso){
    const ParamExistente = this.filaSeleccionada.PYM.find(objeto => objeto.id_parametro === this.parametroSeleccionadoIngreso);
    const MetExistente = this.filaSeleccionada.PYM.find(objeto => objeto.id_metodologia === this.metodologiaSeleccionadoIngreso);
   
   if (ParamExistente && MetExistente) {
      this.alertaDuplicado = true;
      this.parametroSeleccionadoIngreso = '';
      this.metodologiaSeleccionadoIngreso = '';
      this.alertaExito = false;
    }
    else{
      const parametroData = this.metodologiasData.find(item => item.id_parametro === this.parametroSeleccionadoIngreso);
      const metodologiaCompleta = parametroData.metodologias.find(metodologia => metodologia.id_metodologia === this.metodologiaSeleccionadoIngreso);
      const nuevoElemento = {
        id_parametro: this.parametroSeleccionadoIngreso,
        id_metodologia: metodologiaCompleta.id_metodologia,
        parametro: parametroData.nombre_parametro,
        metodologia: metodologiaCompleta.nombre_metodologia,      
      };
      if(filaSeleccionada.id_submuestra != ''){
        if(this.parametro_submuestra_eliminar.find(objeto => objeto.id_submuestra === filaSeleccionada.id_submuestra && objeto.id_parametro === nuevoElemento.id_parametro && objeto.id_metodologia === nuevoElemento.id_metodologia)){
          const indexBorrar = this.parametro_submuestra_eliminar.findIndex(objeto => objeto.id_submuestra === filaSeleccionada.id_submuestra && objeto.id_parametro === nuevoElemento.id_parametro && objeto.id_metodologia === nuevoElemento.id_metodologia)
          this.$emit('eliminar-parametro-submuestra-eliminar', indexBorrar);

        }else{
          this.elementosAgregados.push({
            id_submuestra: filaSeleccionada.id_submuestra,
            id_parametro: nuevoElemento.id_parametro,
            id_metodologia: nuevoElemento.id_metodologia     
          });
        }
        
      }
      this.agregarElementoPYM(filaSeleccionada, nuevoElemento);
      this.alertaExito = true;
      this.alertaDuplicado = false;
      this.parametroSeleccionadoIngreso = '';
      this.metodologiaSeleccionadoIngreso = '';      
    }
  }     
},

agregarElementoPYM(filaSeleccionada, nuevoElemento) {
  // Copiar
  const nuevoPYM = [...filaSeleccionada.PYM];

  // Agregar
  nuevoPYM.push(nuevoElemento);  

  // Asignar
  filaSeleccionada.PYM = nuevoPYM;
},

actualizarMetodologias() {
  const parametro = this.parametroSeleccionadoIngreso;
  // Buscar el objeto correspondiente al parámetro seleccionado en metodologiasData
  const parametroData = this.TodasopcionesParametro.find(item => item.id_parametro === parametro);
  this.metodologias = parametroData.metodologias
  this.opcionesMetodologia = this.metodologias;
 
},
},  
};
</script> 