<template>
  <b-modal id="modal-detalle-muestra-quimico" :title="`Detalles de la muestra ${RUM}`" size="xl" @hidden="onHidden">

    <template #modal-header="{ close }">
      <!-- Emulate built in modal header close button action -->
      <b-row class="d-flex justify-content-around">
        <div class="pl-3">Detalles de la muestra</div>
      </b-row>

      <button type="button" class="close" aria-label="Close" @click="close()">
        <span aria-hidden="true" style="color:white">&times;</span>
      </button>
    </template>

    <div class="p-3">
      <b-col class="col-12">
<b-row class="d-flex justify-content-between">
        <b-col class="col-3">
        <b-row style="border: 1px solid var(--lsa-light-gray); padding:4px; border-radius:5px" >
              <b-col class="col-6" style="font-weight:bold; "> RUM: </b-col>
              <b-col class="col-6">{{ RUM }}</b-col>
            </b-row>
            
          </b-col>
          <b-col class="col-4">
            <b-row class="d-flex justify-content-between" style="border: 1px solid var(--lsa-light-gray); padding-left:15px; padding-right:20px; padding-top:6px; padding-bottom:4px; border-radius:5px; margin-right:10px">
              <div style="font-weight:bold;"> Fecha de entrega: </div>
              <div>
              
                <b-row >
                 <div class="mr-2"> {{ fecha_entrega }}</div>
                 
                </b-row>
              
              </div>
            </b-row>
          </b-col>
        </b-row>
        
        
          
<br/>
        <b-row class="pb-2">
          

          <b-col class="col-6">
            <b-row style="border-bottom: 1px solid var(--lsa-light-gray); padding:3px">
              <b-col class="col-5" style="font-weight:bold;"> Número de muestras: </b-col>
              <b-col class="col-7">{{ numero_muestras }}</b-col>
            </b-row>
            <b-row style="border-bottom: 1px solid var(--lsa-light-gray); padding:3px">
              <b-col class="col-5" style="font-weight:bold;"> Matriz: </b-col>
              <b-col class="col-7" v-if="matriz">{{ matriz }}</b-col>
              <b-col class="col-7" v-else> - </b-col>

            </b-row>
            <b-row style="border-bottom: 1px solid var(--lsa-light-gray); padding:3px">
              <b-col class="col-5" style="font-weight:bold;"> Muestreado por: </b-col>
              <b-col class="col-7">{{ muestreador }}</b-col>
            </b-row>
           

          </b-col>

          <b-col class="col-6">

            <b-row style="border-bottom: 1px solid var(--lsa-light-gray); padding:3px">
              <b-col class="col-5" style="font-weight:bold;"> Norma: </b-col>
              <b-col class="col-7"  v-if="norma && norma.nombre_norma">{{ norma.nombre_norma }}</b-col>
              <b-col class="col-7" v-else> - </b-col>
            </b-row>
            <b-row style="border-bottom: 1px solid var(--lsa-light-gray); padding:3px">
              <b-col class="col-5" style="font-weight:bold;"> Fecha de ingreso: </b-col>
              <b-col class="col-7">{{ fecha_ingreso }}</b-col>
            </b-row>
            <b-row style="border-bottom: 1px solid var(--lsa-light-gray); padding:3px">
              <b-col class="col-5" style="font-weight:bold;"> Hora de ingreso: </b-col>
              <b-col class="col-7">{{  hora_ingreso }}</b-col>
            </b-row>
       

           
            
      </b-col>
        </b-row>
<br/>
        <b-list-group horizontal >
          <b-list-group-item class="d-flex align-items-center justify-content-center" variant='primary'
            style="width:30%;font-weight: bold; padding:3px">Parámetro(s)</b-list-group-item>

          <b-list-group-item class="d-flex align-items-center justify-content-center" variant='primary'
            style="width:70%;font-weight: bold;padding:3px">Metodología(s)</b-list-group-item>

        </b-list-group>

        <b-list-group>
          <b-list-group-item v-if="cargandoParametros" class="d-flex align-items-center justify-content-center lsa-orange-text"
            style="height:100px">
            <div>
              <b-spinner class="align-middle"></b-spinner>
              <strong> Cargando...</strong>
            </div>
          </b-list-group-item>
          <template>
  <div>
            <!-- Lista de parámetros -->
            <b-list-group v-for="parametro in parametros" :key="parametro.id_parametro" horizontal>
              <b-list-group-item 
                  class="d-flex align-items-center justify-content-center"  
                  :variant="parametro.parametro_asignado ? 'warning' : ''" 
                  style="width:30%; padding:3px">
                {{ parametro.nombre_parametro }}
              </b-list-group-item>


              <b-list-group-item style="padding:0px;width:70%">
                <b-list-group flush>
                  <b-list-group-item 
                      class="d-flex justify-content-between align-items-center" 
                      style="padding:3px" 
                      :variant="parametro.parametro_asignado ? 'warning' : ''" 
                      v-for="metodologia in parametro.metodologias"
                      :key="metodologia.id_metodologia + '-' + parametro.id_parametro">
                    
                    <span class="pl-2">{{ metodologia.nombre_metodologia }}</span>

                    <b-popover 
                        placement="lefttop"
                        :target="'button-' + metodologia.id_metodologia + '-' + parametro.id_parametro"
                        title="Descripción metodología" 
                        triggers="focus">
                      
                      <template v-if="metodologia.detalle_metodologia">
                        {{ metodologia.detalle_metodologia }}
                      </template>
                      <template v-else>
                        <div>La metodología no cuenta con una descripción actualmente.</div>
                      </template>
                    </b-popover>
                    
                    <b-button 
                        class="boton-ojo-metodo"
                        :id="'button-' + metodologia.id_metodologia + '-' + parametro.id_parametro">
                      <b-icon scale="0.9" icon="eye-fill" style="color:gray"></b-icon>
                    </b-button>
                  </b-list-group-item>
                </b-list-group>
              </b-list-group-item>
            </b-list-group>
            <b-alert variant="warning" show style="margin-top: 10px;">
              <strong>Nota:</strong> Los elementos resaltados en amarillo indican los parámetros asignados para el análisis.
            </b-alert>
          </div>
        </template>

        </b-list-group>
     
<br/>
<b-list-group-item class="d-flex align-items-center justify-content-center" style='font-weight:bold ' variant='primary'>DETALLE DE LA SUBMUESTRA A ANALIZAR</b-list-group-item>

        <b-list-group horizontal>
          <b-list-group-item class="d-flex align-items-center justify-content-center" variant='secondary'
            style="width:30%;font-weight: bold; padding:3px">Identificaciones de las submuestras</b-list-group-item>

          <b-list-group-item class="d-flex align-items-center justify-content-center" variant='secondary'
            style="width:70%;font-weight: bold;padding:3px">Parametros y Metodología(s)</b-list-group-item>

        </b-list-group>
        <b-list-group >
          
            <b-list-group v-for="submuestra in submuestras_analizar" :key="submuestra.id_submuestra" horizontal>
            <b-list-group-item class="d-flex align-items-center justify-content-center" style="width:30%;font-weight: bold; padding:3px">
                  {{ submuestra.identificador }}
            </b-list-group-item>
            <b-list-group-item class="d-flex align-items-center " style="width:70%; padding:0px">
              <b-list-group style="width:100%;padding:0px">
                <b-list-group-item class="d-flex align-items-center " v-for="parametro_submuestra in submuestra.parametros_filtrados" :key="parametro_submuestra.index"
                    >{{parametro_submuestra.nombre_parametro }} - {{parametro_submuestra.nombre_metodologia }}</b-list-group-item>
              </b-list-group>
            </b-list-group-item>
            
            
          </b-list-group>
           
        </b-list-group>
        <b-list-group-item class="d-flex align-items-center justify-content-center" style='font-weight:bold;margin-top:10px ' variant='primary'>SEGUIMIENTO DEL TRABAJO ASIGNADO</b-list-group-item>

        <b-list-group horizontal >
          
        
          <b-list-group-item class="d-flex align-items-center justify-content-center" variant='secondary'
            style="width:50%;font-weight: bold; padding:3px">Nombre del analista</b-list-group-item>

          <b-list-group-item class="d-flex align-items-center justify-content-center" variant='secondary'
            style="width:20%;font-weight: bold;padding:3px">Orden de análisis</b-list-group-item>
            <b-list-group-item class="d-flex align-items-center justify-content-center" variant='secondary'
            style="width:30%;font-weight: bold;padding:3px">Estado</b-list-group-item>

      


           

        </b-list-group>
        <b-list-group horizontal v-for="empleado in empleados" :key="empleado.rut_empleado">
          <b-list-group-item style="width:50%;padding:3px" class="d-flex align-items-center justify-content-center">
            {{ empleado.nombre + " " + empleado.apellido }}
            <b-popover v-if='empleado.nombre == userData.nombre && empleado.apellido == userData.apellido'
              placement="righttop"
              :target="'button-' + empleado.nombre + '-' + empleado.apellido"
              title="Observación del análisis" 
              triggers="focus">
            
            <template v-if="empleado.observacion_analista">
              {{ empleado.observacion_analista }}
            </template>
            <template v-else>
              <div>No hay ninguna observacion </div>
            </template>
          </b-popover>
          
          <b-button  v-if='empleado.nombre == userData.nombre && empleado.apellido == userData.apellido'
              class="boton-ojo-metodo"
              :id="'button-' + empleado.nombre + '-' + empleado.apellido">
            <b-icon scale="0.9" icon="sticky" style="color:gray "></b-icon>
          </b-button>
        </b-list-group-item >
        <b-list-group-item style="width:20%;padding:3px" class="d-flex align-items-center justify-content-center">
            {{ empleado.orden_de_analisis}}
        </b-list-group-item >
        <b-list-group-item style="width:30%;padding:3px" class="d-flex align-items-center justify-content-center" :variant="getVariant(empleado.estado)">
            {{ empleado.estado}}
        </b-list-group-item >
        </b-list-group>
        </b-col>
        <b-alert variant="warning" show style="margin-top: 10px;">
              <strong>Nota:</strong> Para visualizar la observacion de su asignación presione el icono <b-icon scale="0.9" icon="sticky" style="color:gray "></b-icon>
            </b-alert>
<br/>

    </div>

   



    <template #modal-footer="{ close }">
      <b-button @click="close()" variant="primary" size="xl" class="float-right reactive-button" style="font-weight:bold">
        Cerrar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import MuestraQuimicoService from '@/helpers/api-services/Muestra-quimicos.service';
import {getUserInfo} from "@/helpers/api-services/Auth.service";
import {isLoggedIn} from "@/helpers/api-services/Auth.service";
export default {
  props: {
    detallesData: Object
  },
  data() {
    return {

      RUM: '',
      numero_muestras: '',
      norma: '',
      muestreador: '',
      fecha_entrega: '',
      hora_ingreso: '',
      fecha_ingreso: '',
      matriz: '',
      loading: false,
      cargandoParametros: false,
      cargandoAnalistas: false,
      showObservaciones: false,
      parametros: [],
      empleados: [],
      submuestras_analizar: [],
      userInfo:null,

    }
  },
  async created() {
    if (isLoggedIn()) {
            
            this.userData = getUserInfo();
            console.log(this.userData);
        }
  },
  methods: {
    
    getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },
    detallesAnalistas(empleadosData){

      this.empleados = empleadosData;
      this.cargandoAnalistas = false;
    },
    cambiarFechaEntrega(){
      this.$refs.formfecha.validate().then(success => {
                if (!success) {
                    return;
                } else {
                  console.log("cambiando fecha")
                }});
  
    },
    getVariant(estado) {
      // Devuelve el nombre de clase según el estado del empleado
      switch (estado) {
        case 'Sin iniciar':
          return 'danger';
        case 'En proceso':
          return 'warning'; 
        case 'Entregado':
          return 'success';
        default:
          return ''; // Por si acaso el estado no coincide con ninguno de los casos anteriores
      }
    },
    obtenerDetalles(rum) {
      this.cargandoParametros = true;
      this.cargandoAnalistas = true;
      MuestraQuimicoService.obtenerDetallesMuestra(rum).then((response) => {
        console.log(response)
        if (response != null) {
          if (response.status == 200 && response.data != null) {
            const detalles = response.data;
            console.log('detalles de muestra', response.data);
            this.numero_muestras = detalles.cantidad_muestras;
            this.norma = detalles.norma ?? null;
            this.matriz = detalles.matriz && detalles.matriz.nombre_matriz === null ? null : detalles.matriz ? detalles.matriz.nombre_matriz : null;                       
            this.fecha_ingreso = detalles.fecha_ingreso_formateada;
            this.hora_ingreso = detalles.hora_ingreso_formateada;
            this.muestreador = detalles.muestreado_por;

           

            const empleadosData = response.data.empleados;
            this.detallesAnalistas(empleadosData);

            const parametrosData = response.data.parametros_metodologias;
            for(var i = 0; i < parametrosData.length; i++){
              const parametroExistente = this.parametros.find(param => param.id_parametro == parametrosData[i].id_parametro);
              if(parametroExistente == null){
                this.parametros.push({
                  nombre_parametro: parametrosData[i].nombre_parametro,
                  id_parametro : parametrosData[i].id_parametro,
                  metodologias: [{
                    id_metodologia: parametrosData[i].id_metodologia,
                    nombre_metodologia: parametrosData[i].nombre_metodologia,
                    detalle_metodologia: parametrosData[i].detalle_metodologia,
                  }],
                  parametro_asignado: this.detallesData.parametros_metodologias.some(objeto => objeto.id_parametro == parametrosData[i].id_parametro),
                })
              } else{
                parametroExistente.metodologias.push({
                  id_metodologia: parametrosData[i].id_metodologia,
                    nombre_metodologia: parametrosData[i].nombre_metodologia,
                    detalle_metodologia: parametrosData[i].detalle_metodologia,
                })
              }
            }
            this.submuestras_analizar = response.data.submuestras_analizar;
            const submuestra_analizar_filtrada = this.submuestras_analizar.map(submuestra => {
                // Filtrar los parametros que están en la lista de parametros asignados
                const parametros_filtrados = submuestra.parametros_submuestra.filter(parametro => 
                    this.detallesData.parametros_metodologias.some(asignado => asignado.id_metodologia === parametro.id_metodologia 
                    && asignado.id_parametro === parametro.id_parametro)
                );
                console.log(parametros_filtrados);
                // Retornar una nueva submuestra con los parametros filtrados
                return { ...submuestra, parametros_filtrados: parametros_filtrados };
            });
            this.cargandoParametros = false;
            this.submuestras_analizar = submuestra_analizar_filtrada;
            var j = 0;
            while (j < this.submuestras_analizar.length) {
                if (this.submuestras_analizar[j].parametros_filtrados.length === 0) {
                    this.submuestras_analizar.splice(j, 1);
                } else {
                    j++;
                }
            }
            console.log(this.submuestras_analizar);

          }


        } else {
          this.$bvToast.toast(`Error al obtener detalles de muestra.`, {
            title: 'Error',
            toaster: 'b-toaster-top-center',
            solid: true,
            variant: "warning",
            appendToast: true
          })
          this.cargandoDirecciones = false;
        }
      })
    },
    onHidden() {
      this.$emit('modal-cerrado');
    }
  },
  watch: {
    detallesData: {
      handler() {
        console.log("detallesData actualizada", this.detallesData)
        this.userInfo = getUserInfo();
        this.RUM = this.detallesData.RUM;
        this.fecha_entrega = this.detallesData.fecha_entrega_formateada;
      
       this.parametros = [];
       this.empleados = [];
        this.cargandoAnalistas = true;
        this.cargandoParametros = true;
        this.obtenerDetalles(this.RUM);
      }
    }
  }
}
</script>
